<template>
	<div class="Countdown pointer" @click="onClick">
		{{ second <= 0 ? codeMsg:'剩余'+second+'s'}}
	</div>
</template>

<script>
	export default {
		name: 'Countdown',//修改密码
		props: {
			seconds: {
				type: [Number,String],
				default: 60
			},
			codeMsg: {
				type: String,
				default: '获取验证码'
			},
		},
		data() {
			return {
				dialogVisible: false,
				timer:'',
				second: 0,
				
				
				
			}
		},
		computed: {
			canGetCode() {
				if (this.second > 0) {
					return false
				} else {
					return true
				}
			}
		},
		created() {
			
		},
		beforeDestroy() {
			if(this.timer){
				clearInterval(this.timer)
			}
		},
		methods: {
			start() {
				this.second = this.seconds
				//验证码倒计时
				if(!this.timer){
					this.timer= setInterval(()=>{
						this.second--
					},1000)
				}
			},
			onClick() {
				this.$emit('click')
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.Countdown {}
</style>