<template>
	<div class="pop L">
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="shenqingshouhou">
					<div class="pop-header">
						<div class="ph-title">申请售后</div>
						<div class="ph-close"></div>
					</div>
					<el-form class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">售后类型：</div>
							<div class="cbr-content">
								<el-radio-group v-model="radio_group_value" @input="handleCheckRadioChange($event,erJiChilditem)">
								   <el-radio :label="0">申请赔偿</el-radio>
								   <el-radio :label="1">申请售后</el-radio>
								</el-radio-group>
							</div>
						</div>
						<div class="cb-row" v-if="radio_group_value == 0">
							<div class="cbr-label">赔偿金额：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item yanzhengma">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">申请理由：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-1" v-model="select_value"
										placeholder="请选择" value-key="phone">
										<el-option v-for="item in options"
											:key="`technicalUserVoList-${item.value}`" :label="item.name" :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label" style="align-self: flex-start;">申请说明：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="textarea type-1" type="textarea" :show-word-limit="true"
										:maxlength="400" :rows="2"
										placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label" style="align-self: flex-start;">凭证上传：</div>
							<div class="cbr-content">
								<el-form-item prop="productPicture" class="form-item">
									<FileUpload class="upload-demo" v-model="picture" :limit="1"
										dir="productPicture">
										<div class="upload-item">
											<div class="ui-icon"
												:style="{backgroundImage: `url('${require('../assets/051.png')}')`}"></div>
											<div class="ui-text">上传产品图片</div>
										</div>
									</FileUpload>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="wenxintishi">
					<div class="pop-header">
						<div class="ph-title">温馨提示</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-icon"></div>
						<div class="pb-content">您下单的地址无法准确获取定位信息，可能会导致无法及时派单确定要继续提交吗?</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="true">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="tianjiatupian">
					<div class="pop-header">
						<div class="ph-title">添加图片</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="card">
							<div class="c-header">
								<div class="zh-tab">
									<div class="zht-item" :class="{active: index==tabIndex}"
										v-for="(item,index) in tabList" :key="`zh-tab-${index}`"
										@click="tabClick(item,index)">
										<div class="zhti-text">{{item}}</div>
										<div class="zhti-line"></div>
									</div>
								</div>
								<div class="ch-edit">
									<div class="che-icon"></div>
									<div class="che-text">编辑图库</div>
								</div>
							</div>
							<div class="c-body">
								<div class="cb-tip">请选择产品图片（最多9张）</div>
								<div class="li-album">
									<div class="lia-item" v-for="i in 12">
										<img src="../assets/001.png" alt="" class="liai-pic" />
										<div class="mul-checkbox">
											<div class="checkbox">
												<div class="no" v-if="true"></div>
												<div class="yes" v-else></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pagination-wrap">
							<div class="pagination">
								<el-pagination background layout="prev, pager, next" :total="1000">
								</el-pagination>
							</div>
						</div>
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="quxiaodingdan">
					<div class="pop-header">
						<div class="ph-title">取消订单</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-icon"></div>
						<div class="pb-content">是否确认取消订单？</div>
						<div class="pb-fields">
							<div class="pbf-item">订单号:202401120321</div>
							<div class="pbf-item">下单时间: 2023/12/13 12:34:57</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="lianxikefu">
					<div class="pop-header">
						<div class="ph-title">联系客服</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-text-1">客服李工</div>
						<div class="pb-text-2">联系电话</div>
						<div class="pb-text-3">18859623350</div>
						<div class="pb-text-4">微信二维码</div>
						<img src="../assets/sucai/001.png" alt="" class="pb-erqode" />
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">关闭</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="yuanchengfeixinxi">
					<div class="pop-header">
						<div class="ph-title">远程费信息</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-text-1">远程费凭证</div>
						<div class="li-album">
							<div class="lia-item" v-for="i in 3">
								<img src="../assets/001.png" alt="" class="liai-pic" />
							</div>
						</div>
						<div class="pb-text-2">
							远程费金额
							<span class="money">￥8</span>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="weixiucailiao">
					<div class="pop-header">
						<div class="ph-title">维修材料</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-text-1">故障点视频</div>
						<div class="li-album">
							<div class="lia-item" v-for="i in 3">
								<img src="../assets/001.png" alt="" class="liai-pic" />
							</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">配件信息</div>
							<div class="pbf-value">普通坐便器(马桶)2个</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">备注信息</div>
							<div class="pbf-value">无</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">收件信息</div>
							<div class="pbf-shoujianxinxi">
								<div class="pbfs-row-1">
									<div class="sr-name">李海洋</div>
									<div class="sr-phone">199 009 80876</div>
									<div class="sr-shengfen">师傅</div>
									<div class="sr-copy"></div>
								</div>
								<div class="pbfs-row-2">
									<div class="sr-address">安徽省合肥市幸福西街幸福西区2号楼1单元101号</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">关闭</div>
						<div class="pf-confirm">填写配件发货信息</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="wangongcailiao">
					<div class="pop-header">
						<div class="ph-title">完工材料</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-text-1">完工照片和试机视频</div>
						<div class="li-album">
							<div class="lia-item" v-for="i in 3">
								<img src="../assets/001.png" alt="" class="liai-pic" />
							</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">备注信息</div>
							<div class="pbf-value">无</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">关闭</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="peijianwuliu">
					<div class="pop-header">
						<div class="ph-title">配件物流信息</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-field">
							<div class="pbf-label">快递公司：</div>
							<div class="pbf-value">圆通快递</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">物流单号：</div>
							<div class="pbf-value">YT235600232210</div>
							<div class="pbf-copy"></div>
						</div>
						<div class="pb-progress">
							<div class="pbp-item">
								<div class="pbpi-date">
									<div class="id-date">01:35</div>
									<div class="id-time">12-02</div>
								</div>
								<div class="pbpi-icons">
									<div class="ii-dot"></div>
									<div class="ii-line"></div>
								</div>
								<div class="pbpi-content">
									<div class="ic-text">[沈阳市] 订单已到达[辽宁沈阳转运中心</div>
								</div>
							</div>
							<div class="pbp-item">
								<div class="pbpi-date">
									<div class="id-date">01:35</div>
									<div class="id-time">12-02</div>
								</div>
								<div class="pbpi-icons">
									<div class="ii-dot"></div>
									<div class="ii-line"></div>
								</div>
								<div class="pbpi-content">
									<div class="ic-text">[沈阳市] 您的包表已由物流公司揽收</div>
								</div>
							</div>
							<div class="pbp-item">
								<div class="pbpi-date">
									<div class="id-date">01:35</div>
									<div class="id-time">12-02</div>
								</div>
								<div class="pbpi-icons">
									<div class="ii-dot"></div>
									<div class="ii-line"></div>
								</div>
								<div class="pbpi-content">
									<div class="ic-text">包裹正在等待揽收</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">关闭</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="peijianfahuo">
					<div class="pop-header">
						<div class="ph-title">填写配件发货信息</div>
						<div class="ph-close"></div>
					</div>
					<el-form class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">快递品牌：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">快递单号：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="pop-footer">
						<div class="pf-cancel">关闭</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="fanjianshenhe">
					<div class="pop-header">
						<div class="ph-title">返件审核</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-field">
							<div class="pbf-label">快递单照片</div>
							<img src="../assets/001.png" alt="" class="pbf-image" />
						</div>
						<div class="pb-field">
							<div class="pbf-label">快递品牌</div>
							<div class="pbf-value">普通坐便器(马桶)2个</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">快递单号</div>
							<div class="pbf-value">无</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">快递费</div>
							<div class="pbf-value type-1">￥28</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">支付截屏</div>
							<img src="../assets/001.png" alt="" class="pbf-image" />
						</div>
						<div class="pb-field">
							<div class="pbf-label">返件拍照</div>
							<img src="../assets/001.png" alt="" class="pbf-image" />
						</div>
						<div class="pb-field">
							<div class="pbf-label">寄送地址</div>
							<div class="pbf-shoujianxinxi">
								<div class="pbfs-row-1">
									<div class="sr-name">李海洋</div>
									<div class="sr-phone">199 009 80876</div>
								</div>
								<div class="pbfs-row-2">
									<div class="sr-address">安徽省合肥市幸福西街幸福西区2号楼1单元101号</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">驳回</div>
						<div class="pf-confirm">通过</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="cuhaopingshenhe">
					<div class="pop-header">
						<div class="ph-title">促好评审核</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-field">
							<div class="pbf-label">促好评要求</div>
							<div class="pbf-value">晒图3张+五星好评+15字以上评论</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">促好评金额</div>
							<div class="pbf-value type-1">￥28</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">凭证拍照</div>
							<img src="../assets/001.png" alt="" class="pbf-image" />
						</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">驳回</div>
						<div class="pf-confirm">通过</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="xiugaidenglumima">
					<div class="pop-header">
						<div class="ph-title">修改登录密码</div>
						<div class="ph-close"></div>
					</div>
					<el-form class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">手机号：</div>
							<div class="cbr-content">
								<div class="cbrc-value">135****3320</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">验证码：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item yanzhengma">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
									<div class="yanzhengma-btn">获取验证码</div>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">新密码：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">确认密码：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="zhuxiaozhanghu">
					<div class="pop-header">
						<div class="ph-title">注销账户</div>
						<div class="ph-close"></div>
					</div>
					<div class="pop-body">
						<div class="pb-icon"></div>
						<div class="pb-content">是否确认注销账户？</div>
						<div class="pb-waring">一旦注销成功，所有数据将被删除且无法恢复、无法申请售后。</div>
					</div>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定注销</div>
					</div>
				</div>
			</el-dialog>
		</template>
		<template v-if="false">
			<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
				<div class="tianjiazizhanghao">
					<div class="pop-header">
						<div class="ph-title">添加子账号</div>
						<div class="ph-close"></div>
					</div>
					<el-form class="pop-body">
						<div class="cb-row">
							<div class="cbr-label">用户昵称：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item yanzhengma">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">登录手机号：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">登录密码：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input class="input type-1" placeholder="请输入" clearable></el-input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="pop-footer">
						<div class="pf-cancel">取消</div>
						<div class="pf-confirm">确定</div>
					</div>
				</div>
			</el-dialog>
		</template>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: true,
				tabList: ['产品图库', '系统图库', '本地上传'],
				tabIndex: 0,
				radio_group_value: 1,
				select_value: '',
				options: [
					{
						name: '123',
						value: 1
					},
					{
						name: '456',
						value: 1
					},
				],
				picture: '',
				

			}
		},
		created() {

		},
		mounted() {
			// setTimeout(() => {
			// 	this.dialogVisible = true
			// },3000)
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.pop {
		/deep/.el-dialog__wrapper {
			display: flex;
			justify-content: center;
			align-items: center;

			.el-dialog {
				margin: 0 !important;
				background-color: transparent;
				width: auto;

				.el-dialog__header {
					display: none;
				}

				.el-dialog__body {
					padding: 0;
				}

				.el-dialog__footer {
					display: none;
				}
			}
		}
		
		.shenqingshouhou {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						.el-radio-group {
							display: flex;
							align-items: center;
						}
						
						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
						
						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							.el-input__suffix {
								display: flex;
								align-items: center;
							}
						
							&.type-1 {
								width: 320px;
							}
						
						}
						
						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 420px;
							}
						}
						
						.upload-demo {
							background: transparent;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;
						
							&:hover {
						
								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}
						
							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}
						
						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px;
							border: 1px dashed #CED4DB;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 10px;
						
							.ui-icon {
								width: 24px;
								height: 24px;
								background-image: url('../assets/052.png');
								background-size: 100% 100%;
								margin-bottom: 8px;
							}
						
							.ui-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 12px;
							}
						
						}
						
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}

		.wenxintishi {
			width: 600px;
			min-height: 272px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				align-items: center;

				.pb-icon {
					width: 20px;
					height: 20px;
					background-image: url('../assets/066.png');
					background-size: 100% 100%;
					margin-right: 10px;
				}

				.pb-content {
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}
			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}

		.tianjiatupian {
			width: 780px;
			min-height: 514px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
			
			.mul-checkbox {
				display: flex;
				align-items: center;
			
				.checkbox {
					width: 18px;
					height: 18px;
			
					.no {
						width: 100%;
						height: 100%;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #CDCDCD;
						box-sizing: border-box;
					}
			
					.yes {
						width: 100%;
						height: 100%;
						background-image: url('../assets/039.png');
						background-size: 100% 100%;
					}
				}
			
				.label {
					margin-left: 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 18px;
					.num {
						margin: 0 5px;
					}
				}
			}

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				padding: 20px 0;

				.card {
					width: 740px;
					min-height: 346px;
					background: #F8F8F8;
					border-radius: 0px 0px 0px 0px;
					opacity: 1;
					border: 1px solid #EDEDED;
					box-sizing: border-box;
					padding: 0 20px;

					.c-header {
						border-bottom: 1px solid #ECECEC;
						display: flex;
						align-items: center;
						.zh-tab {
							display: flex;

							.zht-item {
								height: 50px;
								display: flex;
								position: relative;
								margin-right: 30px;
								box-sizing: border-box;
								padding-top: 20px;

								.zhti-text {
									font-size: 16px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
									line-height: 16px;
								}

								.zhti-line {
									display: none;
									width: 44px;
									height: 0;
									border-bottom: 4px solid #3571DA;
									border-left: 4px solid transparent;
									border-right: 4px solid transparent;
									position: absolute;
									bottom: 0;
									left: 50%;
									transform: translate(-50%, 0);
								}

								&.active {
									.zhti-text {
										font-weight: bold;
										color: #3571DA;
									}

									.zhti-line {
										display: initial;
									}
								}
							}
						}

						.ch-edit {
							margin-left: auto;
							display: flex;
							align-items: center;
							.che-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/067.png');
								background-size: 100% 100%;
								margin-right: 10px;
							}
							.che-text {
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 16px;
							}
						}
					}
					.c-body {
						.cb-tip {
							margin: 20px 0;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 16px;
						}
						.li-album {
							box-sizing: border-box;
							display: flex;
							flex-wrap: wrap;
							.lia-item {
								width: 100px;
								height: 100px;
								margin-right: 19px;
								margin-bottom: 20px;
								position: relative;
								border-radius: 4px;
								overflow: hidden;
								&:nth-child(6n) {
									margin-right: 0;
								}
								.liai-pic {
									width: 100px;
									height: 100px;
								}
								.mul-checkbox {
									position: absolute;
									top: 10px;
									right: 10px;
								}
							}
						}
					}
				}
			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				// justify-content: center;
				align-items: center;

				.pagination-wrap {
					margin-right: auto;
					// padding: 20px 0;
					/deep/.pagination {
						display: flex;
						// justify-content: center;
						.el-pagination {
							// padding: 0;
						}
					}
				}

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin-right: 20px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
				}
			}
		}
		
		.quxiaodingdan {
			width: 600px;
			min-height: 272px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
		
				.pb-icon {
					width: 42px;
					height: 42px;
					background-image: url('../assets/066.png');
					background-size: 100% 100%;
				}
		
				.pb-content {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.pb-fields {
					display: flex;
					align-items: center;
					margin-top: 20px;
					.pbf-item {
						margin: 0 20px;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #999999;
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.lianxikefu {
			min-width: 600px;
			min-height: 504px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
		
				.pb-text-1 {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
				.pb-text-2 {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					line-height: 12px;
				}
				.pb-text-3 {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 16px;
				}
				.pb-text-4 {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					line-height: 12px;
				}
				.pb-erqode {
					margin-top: 20px;
					width: 140px;
					height: 140px;
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.yuanchengfeixinxi {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 36px;
				display: flex;
				flex-direction: column;
		
				.pb-text-1 {
					margin: 20px 0;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.li-album {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					.lia-item {
						width: 120px;
						height: 120px;
						margin-right: 20px;
						position: relative;
						border-radius: 4px;
						overflow: hidden;
						&:nth-child(4n) {
							margin-right: 0;
						}
						.liai-pic {
							width: 120px;
							height: 120px;
						}
					}
				}
				.pb-text-2 {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					.money {
						margin-left: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FC474C;
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		.weixiucailiao {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 56px;
				display: flex;
				flex-direction: column;
		
				.pb-text-1 {
					margin: 20px 0;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.li-album {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					.lia-item {
						width: 120px;
						height: 120px;
						margin-right: 20px;
						position: relative;
						border-radius: 4px;
						overflow: hidden;
						&:nth-child(4n) {
							margin-right: 0;
						}
						.liai-pic {
							width: 120px;
							height: 120px;
						}
					}
				}
				.pb-field {
					display: flex;
					margin-top: 20px;
					.pbf-label {
						margin-right: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
					.pbf-value {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
					}
					.pbf-shoujianxinxi {
						flex: 1;
						.pbfs-row-1 {
							display: flex;
							align-items: center;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
							.sr-name {
								margin-right: 10px;
							}
							.sr-phone {
								margin-right: 10px;
							}
							.sr-shengfen {
								width: 44px;
								height: 22px;
								background: #FFFFFF;
								border-radius: 8px 8px 8px 8px;
								opacity: 1;
								border: 1px solid #3571DA;
								box-sizing: border-box;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #3571DA;
								line-height: 20px;
								text-align: center;
							}
							.sr-copy {
								width: 20px;
								height: 20px;
								background-image: url('../assets/068.png');
								background-size: 100% 100%;
								margin-left: auto;
							}
						}
						.pbfs-row-2 {
							margin-top: 20px;
							.sr-address {
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #666666;
							}
						}
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					// width: 88px;
					padding: 0 16px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		.wangongcailiao {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 56px;
				display: flex;
				flex-direction: column;
		
				.pb-text-1 {
					margin: 20px 0;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.li-album {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					.lia-item {
						width: 120px;
						height: 120px;
						margin-right: 20px;
						position: relative;
						border-radius: 4px;
						overflow: hidden;
						&:nth-child(4n) {
							margin-right: 0;
						}
						.liai-pic {
							width: 120px;
							height: 120px;
						}
					}
				}
				.pb-field {
					display: flex;
					margin-top: 20px;
					.pbf-label {
						margin-right: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
					.pbf-value {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.peijianwuliu {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.pb-field {
					display: flex;
					align-items: center;
					margin-top: 20px;
					font-size: 14px;
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					color: #999999;
					line-height: 14px;
					.pbf-label {
						font-weight: 400;
					}
					.pbf-value {
						color: #000000;
					}
					.pbf-copy {
						width: 20px;
						height: 20px;
						background-image: url('../assets/068.png');
						background-size: 100% 100%;
						margin-left: auto;
					}
				}
				
				.pb-progress {
					display: flex;
					flex-direction: column;
					.pbp-item {
						display: flex;
						padding-top: 20px;
						position: relative;
						
						&:last-child {
							.pbpi-icons {
								.ii-line {
									display: none;
								}
							}
						}
						
						.pbpi-date {
							width: 45px;
							font-size: 14px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							color: #999999;
							.id-date {}
							.id-time {}
						}
						.pbpi-icons {
							width: 72px;
							display: flex;
							justify-content: center;
							align-items: center;
							// position: relative;
							.ii-dot {
								width: 12px;
								height: 12px;
								background: #D9D9D9;
								border-radius: 50px;
								opacity: 1;
							}
							.ii-line {
								position: absolute;
								width: 0px;
								height: 100%;
								opacity: 1;
								border: 1px solid #D9D9D9;
								transform: translate(0, 50%);
							}
						}
						.pbpi-content {
							.ic-text {
								font-size: 14px;
								font-family: PingFang SC, PingFang SC;
								font-weight: 500;
								color: #999999;
								padding-top: 8px;
							}
						}
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.peijianfahuo {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 80px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.fanjianshenhe {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 56px;
				display: flex;
				flex-direction: column;
				.pb-field {
					display: flex;
					margin-top: 20px;
					.pbf-label {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						width: 100px;
					}
					.pbf-image {
						width: 120px;
						height: 120px;
						border-radius: 4px 4px 4px 4px;
						opacity: 1;
					}
					.pbf-value {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						&.type-1 {
							color: #FC474C;
						}
					}
					.pbf-shoujianxinxi {
						flex: 1;
						.pbfs-row-1 {
							display: flex;
							align-items: center;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
							.sr-name {
								margin-right: 10px;
							}
							.sr-phone {
								margin-right: 10px;
							}
							.sr-shengfen {
								width: 44px;
								height: 22px;
								background: #FFFFFF;
								border-radius: 8px 8px 8px 8px;
								opacity: 1;
								border: 1px solid #3571DA;
								box-sizing: border-box;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #3571DA;
								line-height: 20px;
								text-align: center;
							}
							.sr-copy {
								width: 20px;
								height: 20px;
								background-image: url('../assets/068.png');
								background-size: 100% 100%;
								margin-left: auto;
							}
						}
						.pbfs-row-2 {
							margin-top: 20px;
							.sr-address {
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #666666;
							}
						}
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					// padding: 0 16px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.cuhaopingshenhe {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 56px;
				display: flex;
				flex-direction: column;
				.pb-field {
					display: flex;
					margin-top: 20px;
					.pbf-label {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						width: 100px;
					}
					.pbf-image {
						width: 120px;
						height: 120px;
						border-radius: 4px 4px 4px 4px;
						opacity: 1;
					}
					.pbf-value {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						&.type-1 {
							color: #FC474C;
						}
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					// padding: 0 16px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.xiugaidenglumima {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 80px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						
						.yanzhengma {
							position: relative;
							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.zhuxiaozhanghu {
			width: 600px;
			min-height: 272px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
		
				.pb-icon {
					width: 42px;
					height: 42px;
					background-image: url('../assets/066.png');
					background-size: 100% 100%;
				}
		
				.pb-content {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.pb-waring {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FC474C;
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #FC474C;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
		.tianjiazizhanghao {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						
						.yanzhengma {
							position: relative;
							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
		
	}
</style>