<template>
	<div class="PopRichText">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="wenxintishi">
				<div class="pop-header">
					<div class="ph-title">{{popData.title}}</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<slot v-bind:popData="popData">
					<div class="pop-body" >
						<div class="pb-content" v-html="popData.content"></div>
					</div>
				</slot>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">关闭</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	var that = {}
	var popData = {
		title: '',
		content: ''
	}
	export default {
		name: 'PopRichText',//添加子账号
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				dialogVisible: false,
				popData: {
					...popData
				},
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			open(data={}) {
				this.popData = {...popData,...data}
				if (data.noticeTitle) {
					this.popData.title = data.noticeTitle
				}
				if (data.noticeContent) {
					this.popData.content = data.noticeContent
				}
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			confirm() {
				console.log(111);
				this.popData.confirm()
				this.close()
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.PopRichText {
		.wenxintishi {
			width: 600px;
			// min-height: 272px;
			// max-height: 80vh;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
			display: flex;
			flex-direction: column;
		
			.pop-header {
				flex-shrink: 0;
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				// flex: 1;
				display: flex;
				align-items: center;
		
				.pb-icon {
					width: 20px;
					height: 20px;
					background-image: url('../assets/066.png');
					background-size: 100% 100%;
					margin-right: 10px;
				}
		
				.pb-content {
					min-height: 40vh;
					max-height: 70vh;
					overflow-y: auto;
					box-sizing: border-box;
					padding: 30px 0;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}
			}
		
			.pop-footer {
				flex-shrink: 0;
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					// margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>