// 星星屏蔽
export function starFilter(data = {}) {
	// console.log('arguments: ',arguments);
	if (typeof arguments[0] == 'object') {
		//对象模式
		var {
			str = '',
				start = 1,
				length = 0,
				unit = '*'
		} = data
	} else {
		//字符串模式
		var str = arguments[0] || ''
		var start = arguments[1] || 1
		var length = arguments[2] || 0
		var unit = arguments[3] || '*'
	}

	var arr = str.split("")
	var num = arr.splice(start - 1, length).length
	var placeholder = ''
	for (var i = 0; i < num; i++) {
		placeholder += unit
	}
	arr.splice(start - 1, 0, placeholder)
	var result = arr.join('')
	return result
}


