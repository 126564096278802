<template>
	<div class="PopYuanchengfei">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="yuanchengfeixinxi">
				<div class="pop-header">
					<div class="ph-title">远程费信息</div>
					<div class="ph-close pointer" @click="close()"></div>
				</div>
				<div class="pop-body">
					<div class="pb-text-1">远程费凭证</div>
					<div class="li-album">
						<div class="lia-item">
							<Media :value="detail.navigationPic"></Media>
						</div>
					</div>
					<div class="pb-text-2">
						远程费金额
						<span class="money">￥{{detail.remoteFees}}</span>
					</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close()">取消</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		FeesDetail
	} from '../common/api.js'
	var that = {}
	var ruleForm = {
		
	}
	export default {
		name: 'PopYuanchengfei',//远程费信息
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {...ruleForm},
				rules: {},
				detail: {},
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			open(e) {
				this.ruleForm = {...ruleForm}
				if (e) {
					// 
					FeesDetail(e.relevanceId).then(res => {
						this.detail = res.data
						this.dialogVisible = true
					})
				} else {
					this.dialogVisible = true
				}
			},
			close() {
				this.dialogVisible = false
				this.$emit('cancel')
			},
			confirm() {
				this.dialogVisible = false
				this.$emit('confirm')
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.PopYuanchengfei {
		.yuanchengfeixinxi {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 36px;
				display: flex;
				flex-direction: column;
		
				.pb-text-1 {
					margin: 20px 0;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.li-album {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					.lia-item {
						width: 120px;
						height: 120px;
						margin-right: 20px;
						position: relative;
						border-radius: 4px;
						overflow: hidden;
						&:nth-child(4n) {
							margin-right: 0;
						}
						.liai-pic {
							width: 120px;
							height: 120px;
						}
					}
				}
				.pb-text-2 {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					.money {
						margin-left: 20px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FC474C;
					}
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>