<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="cuhaopingshenhe">
				<div class="pop-header">
					<div class="ph-title">促好评审核</div>
					<div class="ph-close" @click="dialogVisible = false"></div>
				</div>
				<div class="pop-body">
					<div class="pb-field">
						<div class="pbf-label">促好评要求</div>
						<div class="pbf-value">晒图3张+五星好评+15字以上评论</div>
					</div>
					<div class="pb-field">
						<div class="pbf-label">促好评金额</div>
						<div class="pbf-value type-1">￥{{detail.amount}}</div>
					</div>
					<div class="pb-field">
						<div class="pbf-label">凭证拍照</div>
						<div style="flex: 1;">
							<Media :value="detail.certificate"></Media>
						</div>
					</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="dialogVisible1 = true">驳回</div>
					<div class="pf-confirm btn-fill" @click="submit">通过</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible1" :lock-scroll="false">
			<div class="fanjianshenhe">
				<div class="pop-header">
					<div class="ph-title">促好评审核</div>
					<div class="ph-close" @click="dialogVisible1=false"></div>
				</div>
				<div>
					<el-input v-model="reason" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"
						placeholder="请输入驳回理由">
					</el-input>
				</div>
				<div class="pop-footer">
					<div class="pf-confirm btn-fill" @click="rejectSubmit()">提交</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		praiseInfoById,
		praiseInfo,
		praiseAudit
	} from '../common/api.js'

	export default {
		name: 'PopOrderCuhaoping',
		data() {
			return {
				dialogVisible: false,
				dialogVisible1: false,
				applicationId: '',
				detail: {},
				orderId: '',
				id: '',
				reason: ''
			}
		},

		methods: {
			open(orderId, id) {
				this.orderId = orderId
				this.id = id
				this.loadData()
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			loadData() {
				if (this.id) {
					praiseInfoById(this.id).then(res => {
						this.detail = res.data
						console.log(res)
					})
				} else {
					praiseInfo(this.orderId).then(res => {
						this.detail = res.data
						console.log(res)
					})
				}
			},
			submit() {
				let {
					orderId,
					reason
				} = this
				praiseAudit({
					orderId,
					reason,
					audit: 1
				}).then(res => {
					this.toast({
						message: '提交成功',
						type: 'success'
					})
					this.close()
					this.$emit('success')
					this.loadData()
				})
			},
			rejectSubmit() {
				let {
					orderId,
					reason
				} = this
				praiseAudit({
					orderId,
					reason,
					audit: 2
				}).then(res => {
					this.toast({
						message: '已驳回',
						type: 'success'
					})
					this.close()
					this.dialogVisible1 = false
					this.$emit('success')
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cuhaopingshenhe {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 56px;
			display: flex;
			flex-direction: column;

			.pb-field {
				display: flex;
				margin-top: 20px;

				.pbf-label {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					width: 100px;
				}

				.pbf-image {
					width: 120px;
					height: 120px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
				}

				.pbf-value {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;

					&.type-1 {
						color: #FC474C;
					}
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				// padding: 0 16px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
	
	.fanjianshenhe {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;
	
		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;
	
			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}
	
			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}
	
		.pop-body {
			box-sizing: border-box;
			padding: 0 0 56px;
			display: flex;
			flex-direction: column;
	
			.pb-field {
				display: flex;
				margin-top: 20px;
	
				.pbf-label {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					width: 100px;
				}
	
				.pbf-image {
					width: 120px;
					height: 120px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
				}
	
				.pbf-value {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
	
					&.type-1 {
						color: #FC474C;
					}
				}
	
				.pbf-shoujianxinxi {
					flex: 1;
	
					.pbfs-row-1 {
						display: flex;
						align-items: center;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
	
						.sr-name {
							margin-right: 10px;
						}
	
						.sr-phone {
							margin-right: 10px;
						}
	
						.sr-shengfen {
							width: 44px;
							height: 22px;
							background: #FFFFFF;
							border-radius: 8px 8px 8px 8px;
							opacity: 1;
							border: 1px solid #3571DA;
							box-sizing: border-box;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 20px;
							text-align: center;
						}
	
						.sr-copy {
							width: 20px;
							height: 20px;
							background-image: url('../assets/068.png');
							background-size: 100% 100%;
							margin-left: auto;
						}
					}
	
					.pbfs-row-2 {
						margin-top: 20px;
	
						.sr-address {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}
					}
				}
			}
		}
	
		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;
	
			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}
	
			.pf-confirm {
				width: 88px;
				// padding: 0 16px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>