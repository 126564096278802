<template>
	<div class="product">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">产品管理</div>
					<div class="chh-line"></div>
				</div>
				<div class="ch-right">
					<div class="chr-input-box">
						<el-input v-model="ruleForm.keywords" class="input input-1" placeholder="产品名称/备注说明" clearable></el-input>
					</div>
					<div class="chr-search-btn btn-fill">
						<div class="chrs-icon"></div>
						<div class="chrs-text" @click="search">搜索</div>
					</div>
				</div>
			</div>
			<div class="c-body">
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">类别：</div>
						<el-cascader class="select type-1" v-model="ruleForm.categoryValue" :options="erjiCategoryList"
							clearable :props="{ expandTrigger: 'hover', label: 'name', value: 'id', children: 'child', checkStrictly: true }"
							>
						</el-cascader>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">价格范围：</div>
						<el-input v-model="ruleForm.maxPrice" type="number" class="input input-1" placeholder="请输入" clearable></el-input>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">至</div>
						<el-input v-model="ruleForm.minPrice" type="number" class="input input-1" placeholder="请输入" clearable></el-input>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="cb-line"></div>
				<div class="cb-list">
					<div class="cbl-header">
						<div class="mul-checkbox btn-fill" @click="checkedAllClick">
							<div class="checkbox">
								<div class="no" v-if="!checkedAll"></div>
								<div class="yes" v-else></div>
							</div>
							<div class="label ">全选</div>
						</div>
						<div class="cblh-del btn-fill" @click="delProductForSelect">删除</div>
						<div class="cbr-add-btn pointer" @click="toProductAdd">
							<div class="cbra-icon"></div>
							<div class="cbra-text">添加</div>
						</div>
					</div>
					<div class="list-header">
						<div class="lh-item">产品信息</div>
						<div class="lh-item">价格</div>
						<div class="lh-item">操作视频</div>
						<div class="lh-item">完工示例图</div>
						<div class="lh-item">状态</div>
						<div class="lh-item">操作</div>
					</div>
					
					<div class="empty-wrap" v-loading="isLoading" v-if="!productsList.length">
						<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"
							></el-empty>
					</div>
					<div v-loading="isLoading" v-show="productsList.length">
						<div class="list-item" v-for="(item,index) in productsList" :key="`list-item-${index}`">
							<div class="li-column">
								<div class="column-1 " >
									<div class="mul-checkbox-wrap " @click="productitemSelect(item,index)">
										<div class="mul-checkbox" >
											<div class="checkbox">
												<div class="no" v-if="!item.checked"></div>
												<div class="yes" v-else></div>
											</div>
										</div>
									</div>
									<div class="c1-pic">
										<Media mediaClass="w92h92g20b4" :value="item.productPicture"></Media>
									</div>
									<div class="c1-content">
										<div class="c1c-name">{{item.categoryNameTwo}}-{{item.categoryName}}</div>
										<div class="c1c-leibie">
											<div class="c1c-leibie-label">类别: </div>
											<div class="c1c-leibie-value">{{item.categoryNameOne}}</div>
										</div>
										<div class="c1c-xinghao">
											<div class="c1c-xinghao-label">型号: </div>
											<div class="c1c-xinghao-value">{{item.modelName}}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="li-column">
								<div class="column-2">
									<div class="c2-field-wrap">
										<div class="c2-field" v-if="item.installation">
											<div class="c2f-label">安装</div>
											<div class="c2f-value">￥{{item.installation}}</div>
										</div>
										<div class="c2-field" v-if="item.repair">
											<div class="c2f-label">维修</div>
											<div class="c2f-value">￥{{item.repair}}</div>
										</div>
										<div class="c2-field" v-if="item.overhaul">
											<div class="c2f-label">大修</div>
											<div class="c2f-value">￥{{item.overhaul}}</div>
										</div>
										<div class="c2-field" v-if="item.medium">
											<div class="c2f-label">中修</div>
											<div class="c2f-value">￥{{item.medium}}</div>
										</div>
										<div class="c2-field" v-if="item.minor">
											<div class="c2f-label">小修</div>
											<div class="c2f-value">￥{{item.minor}}</div>
										</div>
										<div class="c2-field" v-if="item.cleanse">
											<div class="c2f-label">清洗</div>
											<div class="c2f-value">￥{{item.cleanse}}</div>
										</div>
										<div class="c2-field" v-if="item.debug">
											<div class="c2f-label">调制/检测/定位/教学</div>
											<div class="c2f-value">￥{{item.debug}}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="li-column">
								<div class="column-3">
									<Media mediaClass="w120h90g20b4" :value="item.productVideo" keyName="fileUrl" :limit="1" preview="mediaList"></Media>
								</div>
							</div>
							<div class="li-column">
								<div class="column-4">
									<Media mediaClass="w120h90g20b4" :value="item.finishedDrawing" keyName="fileUrl" :limit="1"></Media>
								</div>
							</div>
							<div class="li-column">
								<div class="column-5">
									<span>{{item.checkStatus | checkStatusFilter}}</span>
								</div>
							</div>
							<div class="li-column">
								<div class="column-6">
									<!-- <div class="c5-edit-btn"></div> -->
									<div class="c6-del-btn pointer" @click="delProduct(item)"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination background :current-page.sync="pageNum" :page-size.sync="pageSize"
							layout="sizes, prev, pager, next" :total="total" @size-change="loadData" :page-sizes="pageSizesOptions"
							@current-change="loadData">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ProductsList,
		CategoryList,
		ProductsDelete
	} from '../common/api.js'
	var ruleForm = {
		categoryId: '', // 类目id
		categoryId1: '', // 1级类目id
		categoryId2: '', // 2级类目id
		keywords: '', //关键词 产品名称
		maxPrice: '', //最高价格
		minPrice: '', //最低价格
		isCheck: '', //1已确认
		categoryValue: [],
	}
	export default {
		data() {
			return {
				ruleForm: {...ruleForm},
				rules: {},
				
				productsList: [],
				
				total: 0,
				pageNum: 1,
				pageSize: 10,
				isLoading: true,
				
				categoryList: [],
				erjiCategoryList: [],
				
				

			}
		},
		computed: {
			checkedAll() {
				if (this.productsList && this.productsList.length) {
					if (this.productsList.some(v => v.checked == false)) {
						return false
					} else {
						return true
					}
				} else {
					return false
				}
			}
		},
		created() {
			this.getCategoryList()
			this.loadData()
		},
		filters: {
			// 审核状态 0=审核中1=已通过2=已驳回
			checkStatusFilter(v) {
				if (v == 0) {
					return '审核中'
				} else if(v == 1) {
					return '已通过'
				} else if(v == 2) {
					return '已驳回'
				} else {
					return ''
				}
			}
		},
		mounted() {

		},
		methods: {
			reload() {
				this.pageNum = 1
				this.loadData()
			},
			getCategoryList() {
				let data = {
					name: '',
					parentId: '',
					type: 1
				}
				CategoryList(data).then(res => {
					this.categoryList = res.data
					if (res.data.length > 1) {
						this.erjiCategoryList = res.data
					} else if (res.data.length == 1) {
						this.erjiCategoryList = res.data[0].child
					}
					
				})
			
			},
			toProductAdd() {
				this.$router.push({ name: 'productAdd' })
			},
			loadData() {
				let data = {
					categoryId1: this.ruleForm.categoryValue[0] || '', // 1级类目id
					categoryId2: this.ruleForm.categoryValue[1] || '', // 2级类目id
					categoryId: this.ruleForm.categoryValue[2] || '', // 类目id
					keywords: this.ruleForm.keywords, //关键词 产品名称
					maxPrice: this.ruleForm.maxPrice, //最高价格
					minPrice: this.ruleForm.minPrice, //最低价格
					isCheck: '', //1已确认
					pageSize: this.pageSize,
					pageNum: this.pageNum
				}
				
				if (this.categoryList.length > 1) {
					data.categoryId1 = this.ruleForm.categoryValue[0] || ''
					data.categoryId2 = this.ruleForm.categoryValue[1] || ''
					data.categoryId = this.ruleForm.categoryValue[2] || ''
				} else if (this.categoryList.length == 1) {
					data.categoryId1 = this.categoryList[0].id || ''
					data.categoryId2 = this.ruleForm.categoryValue[0] || ''
					data.categoryId = this.ruleForm.categoryValue[1] || ''
				}
				this.isLoading = true
				ProductsList(data).then(res => {
					this.productsList = res.rows.map(v => {
						v.checked = false
						return v
					})
					this.total = res.total
					this.$bus.$emit('backTop')
				}).finally(() => {
					this.isLoading = false
				})
			},
			// 搜索
			search() {
				this.reload()
			},
			// 筛选
			filter() {
				this.reload()
			},
			// 重置
			reset() {
				this.ruleForm = {
					...ruleForm
				}
				this.reload()
			},
			// 删除产品
			delProduct(item) {
				this.$rootApp.$refs.PopTip.open({
					content: '是否确认删除该产品？',
					confirm: () => {
						ProductsDelete(item.id).then(res => {
							this.toast({
								message: '删除成功',
								type: 'success'
							})
							this.loadData()
						})
					}
				})
			},
			productitemSelect(productitem,orderListindex) {
				productitem.checked = !productitem.checked
			},
			checkedAllClick() {
				if (!this.checkedAll) {
					this.productsList.forEach(v => v.checked = true)
				} else {
					this.productsList.forEach(v => v.checked = false)
				}
			},
			delProductForSelect() {
				let productsList = this.productsList.filter(v => v.checked)
				if (productsList && productsList.length) {
					this.$rootApp.$refs.PopTip.open({
						content: '是否确认删除该产品？',
						confirm: () => {
							let ids = productsList.map(v => v.id).join(',')
							ProductsDelete(ids).then(res => {
								this.toast({
									message: '删除成功',
									type: 'success'
								})
								this.loadData()
							})
						}
					})
				} else {
					this.toast({
						message: '请勾选需要删除的订单',
						type: 'warning'
					})
				}
			},
			

		}
	}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.product {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 80px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;
			
				.ch-headingbox {
					position: relative;
			
					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}
			
					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
				.ch-right {
					display: flex;
					align-items: center;
					margin-left: auto;
					.chr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;
						.chri-label {
							width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						/deep/.input {
							width: 216px;
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						}
					}
					.chr-search-btn {
						display: flex;
						align-items: center;
						width: 84px;
						height: 32px;
						background-color: #3571DA;
						border-radius: 4px;
						justify-content: center;
						.chrs-icon {
							background-image: url('../assets/047.png');
							background-size: 100% 100%;
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
						.chrs-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 14px;
						}
					}
				}
			}
			.c-body {
				padding-top: 20px;
				.cb-row {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					margin-bottom: 20px;
					
					.cbr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;
						.cbri-label {
							// width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}
						/deep/.input {
							width: 136px;
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
								line-height: 32px;
							}
						}
						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
							.el-input__suffix {
								display: flex;
								align-items: center;
							}
							&.type-1 {
								width: 180px;
							}
						}
						/deep/.date {
							height: 32px;
							padding-left: 8px;
							padding-right: 4px;
							input {
								// height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								text-align: left;
							}
							.el-input__icon {
								height: initial;
							}
							.el-icon-date {
								order: 1;
							}
							&.date-1 {
								width: 280px;
							}
						}
					}
					.cbr-shaixuan-btn {
						width: 60px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}
					.cbr-reset-btn {
						width: 60px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					
				}
				
				.cb-line {
					margin: 0 20px;
					border-bottom: 1px solid #EDEDED;
				}
				
				.mul-checkbox-wrap {
					width: 38px;
					height: 38px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					&:hover {
						.checkbox {
							.no {
								border: 1px solid #3571da;
							}
						}
					}
				}
				
				.mul-checkbox {
					display: flex;
					align-items: center;
					&:hover {
						.checkbox {
							.no {
								border: 1px solid #3571da;
							}
						}
					}
				
					.checkbox {
						width: 18px;
						height: 18px;
				
						.no {
							width: 100%;
							height: 100%;
							background: #FFFFFF;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #CDCDCD;
							box-sizing: border-box;
						}
				
						.yes {
							width: 100%;
							height: 100%;
							background-image: url('../assets/039.png');
							background-size: 100% 100%;
							border-radius: 4px 4px 4px 4px;
							box-sizing: border-box;
						}
					}
				
					.label {
						margin-left: 10px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 18px;
				
						.num {
							margin: 0 5px;
						}
					}
				}
				
				.cb-list {
					.cbl-header {
						padding: 20px;
						display: flex;
						align-items: center;
						.mul-checkbox {
							.label {
								font-size: 12px;
							}
						}
						.cblh-download {
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 32px;
							padding: 0 16px;
							margin-left: 10px;
						}
						.cblh-del {
							width: 60px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #3571DA;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 32px;
							text-align: center;
							margin-left: 10px;
						}
						.cbr-add-btn {
							margin-left: auto;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 84px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #3571DA;
							box-sizing: border-box;
							.cbra-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/045.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}
							.cbra-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #3571DA;
								line-height: 14px;
							}
						}
					}
					
					.list-header {
						display: flex;
						width: 970px;
						margin: 0 auto;
						.lh-item {
							width: calc(100% / 6);
							background-color: #F7F7F7;
							box-sizing: border-box;
							border: 1px solid #DFDFDF;
							border-left: none;
							height: 48px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							&:nth-child(1) {
								width: 33%;
								border-left: 1px solid #DFDFDF;
								border-radius: 8px 0 0 8px;
							}
							&:nth-child(2) {
								width: 15%;
							}
							&:nth-child(5) {
								width: 10%;
							}
							&:nth-child(6) {
								width: 10%;
								border-radius: 0 8px 8px 0;
							}
						}
					}
					
					.list-item {
						display: flex;
						width: 970px;
						margin: 20px auto 0;
						.li-column {
							width: calc(100% / 6);
							box-sizing: border-box;
							border: 1px solid #DFDFDF;
							border-left: none;
							display: flex;
							&:nth-child(1) {
								width: 33%;
								border-left: 1px solid #DFDFDF;
								border-radius: 8px 0 0 8px;
							}
							&:nth-child(2) {
								width: 15%;
							}
							&:nth-child(5) {
								width: 10%;
							}
							&:nth-child(6) {
								width: 10%;
								border-radius: 0 8px 8px 0;
							}
							.column-1 {
								flex: 1;
								display: flex;
								align-items: center;
								box-sizing: border-box;
								padding: 14px 0;
								.c1-pic {
									flex-shrink: 0;
									// width: 92px;
									// height: 92px;
									margin: 0 10px 0 0;
								}
								.c1-content {
									display: flex;
									flex-direction: column;
									align-items: flex-start;
									// min-width: 170px;
									.c1c-name {
										font-size: 14px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: bold;
										color: #333333;
										margin-bottom: 4px;
									}
									.c1c-leibie {
										font-size: 12px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #999999;
										margin-bottom: 4px;
										display: flex;
										.c1c-leibie-label {}
										.c1c-leibie-value {
											flex: 1;
											margin-left: 4px;
										}
									}
									.c1c-xinghao {
										font-size: 12px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #999999;
										display: flex;
										.c1c-xinghao-label {}
										.c1c-xinghao-value {
											flex: 1;
											margin-left: 4px;
										}
									}
								}
							}
							.column-2 {
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								padding: 14px 20px;
								.c2-field-wrap {
									display: flex;
									flex-direction: column;
									.c2-field {
										display: flex;
										// flex-wrap: wrap;
										align-items: center;
										margin: 5px 0;
										
										.c2f-label {
											margin-right: 10px;
											font-size: 12px;
											font-family: Microsoft YaHei, Microsoft YaHei;
											font-weight: 400;
											color: #999999;
											line-height: 16px;
											// white-space: nowrap;
											display: flex;
										}
										.c2f-value {
											font-size: 14px;
											font-family: Microsoft YaHei, Microsoft YaHei;
											font-weight: bold;
											color: #FC474C;
											line-height: 16px;
											white-space: nowrap;
										}
									}
								}
							}
							.column-3 {
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								box-sizing: border-box;
								padding: 14px 20px;
								.c3-pic {
									width: 120px;
									height: 90px;
								}
							}
							.column-4 {
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								box-sizing: border-box;
								padding: 14px 20px;
								.c4-pic {
									width: 120px;
									height: 90px;
								}
							}
							.column-5 {
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								box-sizing: border-box;
								padding: 14px 20px;
							}
							.column-6 {
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								box-sizing: border-box;
								padding: 14px 20px;
								.c6-edit-btn {
									width: 20px;
									height: 20px;
									background-image: url('../assets/054.png');
									background-size: 100% 100%;
									margin: 0 10px;
								}
								.c6-del-btn {
									width: 20px;
									height: 20px;
									background-image: url('../assets/062.png');
									background-size: 100% 100%;
									margin: 0 10px;
								}
							}
						}
					}
				}
				
				
				
				
				.pagination-wrap {
					padding: 20px 0;
					/deep/.pagination {
						display: flex;
						justify-content: center;
					}
				}
				
			}
		}
	}
</style>