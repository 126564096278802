import Vue from 'vue'
import axios from 'axios'
import config from './config.js'
import {
	Notification,
	MessageBox,
	Message,
	Loading
} from 'element-ui'
import store from '../store/index.js'
import QS from 'qs'
import JSZip from 'jszip'
import {
	saveAs
} from 'file-saver'

const fileService = axios.create({
	// baseURL: `${config.downLoad}`, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 50000 // request timeout，
})

// 下载某个文件
export function fileDownload(url,name='') {
	console.log('url: ', url);
	var fullPath = ''
	if (url.startsWith('http://') || url.startsWith('https://')) {
		fullPath = url
	} else {
		if (config.imgRoot[config.imgRoot.length - 1] == '/') {
			if (url[0] == '/') {
				fullPath = config.imgRoot + url.slice(1)
			} else {
				fullPath = config.imgRoot + url
			}
		} else {
			if (url[0] == '/') {
				fullPath = config.imgRoot + url
			} else {
				fullPath = config.imgRoot + '/' + url
			}
		}
	}
	var filename = name || fullPath.substring(fullPath.lastIndexOf("/") + 1)

	let downloadLoadingInstance = Loading.service({
		text: "正在下载数据，请稍候",
		spinner: "el-icon-loading",
		background: "rgba(0, 0, 0, 0.7)",
	})
	console.log('url: ', url);
	return fileService.get(fullPath, {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		responseType: 'blob'
	}).then(async (data) => {
		saveAs(data.data, filename)
		downloadLoadingInstance.close();
	}).catch((r) => {
		console.error(r)
		Message.error('下载文件出现错误，请联系管理员！')
		downloadLoadingInstance.close();
	})
}

// 下载多个文件并且压缩
export function filesDownloadZip(files = '', options = {}) {
	console.log('files: ', files);
	let {
		keyname = 'fileUrl'
	} = options
	if (!files) return
	let list = Array.isArray(files) ? files : files.split(',')
	let fileList = list.map(item => {
		var fullPath = ''
		if (typeof item === "string") {
			if (item.startsWith('http://') || item.startsWith('https://')) {
				fullPath = item
			} else {
				if (config.imgRoot[config.imgRoot.length - 1] == '/') {
					if (item[0] == '/') {
						fullPath = config.imgRoot + item.slice(1)
					} else {
						fullPath = config.imgRoot + item
					}
				} else {
					if (item[0] == '/') {
						fullPath = config.imgRoot + item
					} else {
						fullPath = config.imgRoot + '/' + item
					}
				}
			}
		} else {
			if (item[keyname].startsWith('http://') || item[keyname].startsWith('https://')) {
				fullPath = item[keyname]
			} else {
				if (config.imgRoot[config.imgRoot.length - 1] == '/') {
					if (item[keyname][0] == '/') {
						fullPath = config.imgRoot + item[keyname].slice(1)
					} else {
						fullPath = config.imgRoot + item[keyname]
					}
				} else {
					if (item[keyname][0] == '/') {
						fullPath = config.imgRoot + item[keyname]
					} else {
						fullPath = config.imgRoot + '/' + item[keyname]
					}
				}
			}
		}
		console.log('fullPath: ', fullPath);
		let obj = {
			fileName: fullPath.substring(fullPath.lastIndexOf("/") + 1),
			fileUrl: fullPath
		}
		return obj
	})

	const zip = new JSZip()
	const promises = []
	console.log('fileList: ', fileList);

	let downloadLoadingInstance = Loading.service({
		text: "正在下载数据，请稍候",
		spinner: "el-icon-loading",
		background: "rgba(0, 0, 0, 0.7)",
	})

	fileList.forEach((file) => {
		console.log('file: ', file);
		const pro = fileService.get(file.fileUrl, {
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				responseType: 'blob'
			})
			.then((data) => {
				// 下载文件, 并存成ArrayBuffer对象
				zip.file(file.fileName, data.data, {
					binary: true
				}) // 逐个添加文件
			})
			.catch(() => {
				Message.error('下载文件出现错误，请联系管理员！')
				downloadLoadingInstance.close();
			})
		promises.push(pro)
	})
	Promise.all(promises).then(() => {
		zip.generateAsync({
			type: 'blob'
		}).then((content) => {
			// 生成二进制流
			saveAs(content, `下载的压缩包.zip`) // 利用file-saver保存文件
			downloadLoadingInstance.close();
		}).catch(() => {
			Message.error('下载文件出现错误，请联系管理员！')
			downloadLoadingInstance.close();
		})
	})
}