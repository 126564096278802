import Vue from 'vue'

Vue.directive('c-ellipsis', {
	// 当绑定元素插入到DOM中
	inserted: function(el) {
		// 获取元素的高度
		const height = el.offsetHeight;
		// 获取元素的计算样式
		const style = window.getComputedStyle(el);
		// 计算行高
		const lineHeight = parseFloat(style.lineHeight);
		
		const lineClamp = parseInt(height / lineHeight)
		
		// 设置元素的样式
		el.style.textOverflow = '-o-ellipsis-lastline'
		el.style.display = '-webkit-box'
		el.style.webkitBoxOrient = 'vertical'
		el.style.overflow = 'hidden'
		el.style.textOverflow = 'ellipsis'
		el.style.wordBreak = 'break-all'
		el.style.lineClamp = lineClamp
		el.style.webkitLineClamp = lineClamp
		
		console.log('el.offsetHeight: ', height);
		console.log('el.offsetHeight: ', lineHeight);
		console.log('el.offsetHeight: ', lineClamp);
	}
})