import Vue from 'vue'
import {
	starFilter
} from './utils.js'

Vue.mixin({
	data() {
		return {
			time: {
				fullYear: "",
				month: "",
				date: "",
				week: "",
				hours: "",
				minutes: "",
				seconds: "",
				weekName: "",
			},
			longText: '最近，谷歌发布了新的一篇论文介绍了一项新技术，使大语言模型能够处理无限长度的上下文。新论文声称可赋予大型语言模型（LLM）处理无限长度文本的能力。该论文介绍了无限注意力（Infini-attention），这是一种以扩展“上下文窗口”的方式配置语言模型的技术，同时保持内存和计算需求不变。',
			verifyAccountTemp: '',
			
		}
	},
	filters: {
		// 格式化为0
		zeroFilter(v) {
			if (v === '' || v === null || v === 'null' || v === undefined || v === 'undefined') {
				return 0
			} else {
				return v
			}
		},
		// 显示隐藏
		visibilityFilter(v) {
			if (v) {
				return {
					visibility: 'visible'
				}
			} else {
				return {
					visibility: 'hidden'
				}
			}
		},
		// 背景颜色
		backgroundColorFilter(v,key) {
			let backgroundColor = ''
			if (
				key == 'orderStatus' && v == 100
			) {
				backgroundColor = '#409eff'
			}
			if (
				key == 'orderStatus' && v == 200
			) {
				backgroundColor = '#EE82EE'
			}
			if (
				key == 'orderStatus' && v == 300
			) {
				backgroundColor = '#944806'
			}
			if (
				key == 'orderStatus' && v == 400
			) {
				backgroundColor = '#f0a800'
			}
			if (
				key == 'orderStatus' && v == 500
			) {
				backgroundColor = '#87CEEB'
			}
			if (
				key == 'orderStatus' && v == 600
			) {
				backgroundColor = '#FF7F50'
			}
			if (
				key == 'orderStatus' && v == 650
			) {
				backgroundColor = '#7d24ed'
			}
			if (
				key == 'orderStatus' && v == 700
			) {
				backgroundColor = '#46c26f'
			}
			if (
				key == 'orderStatus' && v == 800
			) {
				backgroundColor = '#485970'
			}
			if (
				key == 'orderStatus' && v == 900
			) {
				backgroundColor = '#FF0000'
			}
			return `backgroundColor: ${backgroundColor};`
		},
		// 星星屏蔽
		starFilter(data = {}) {
			return starFilter(...arguments)
		},
	},
	mounted() {
		this.getTime()
	},
	methods: {
		getTime() {
			setInterval(() => {
				const newtime = new Date();
				const weeks = ["星期天","星期一","星期二","星期三","星期四","星期五","星期六"]
				this.time.hours = this.getIncrease(newtime.getHours(), 2);
				this.time.minutes = this.getIncrease(newtime.getMinutes(), 2);
				this.time.seconds = this.getIncrease(newtime.getSeconds(), 2);
				this.time.fullYear = this.getIncrease(newtime.getFullYear(), 4);
				this.time.month = this.getIncrease(newtime.getMonth() + 1, 2);
				this.time.date = this.getIncrease(newtime.getDate(), 2);
				this.time.week = newtime.getDay();
				this.time.weekName = weeks[this.time.week];
			}, 1000)
		},
		// 补0
		getIncrease(num, digit) {
			var increase = "";
			for (var i = 0; i < digit; i++) {
				increase += "0";
			}
			return (increase + num).slice(-digit);
		},
		// 验证值是否正整数
		verifyPositiveInteger(arg,options) {
			let {
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '请输入正整数',
				type = 'warning',
			} = options
			let verifyResult = false
			if (arg == 'this') {
				if (key3) {
					let value = this[key1][key2][key3]
					this[key1][key2][key3] = this[key1][key2][key3].replace(/\D/g,'')
					verifyResult = this[key1][key2][key3] == value
				} else if(key2) {
					let value = this[key1][key2]
					this[key1][key2] = this[key1][key2].replace(/\D/g,'')
					verifyResult = this[key1][key2] == value
				} else {
					let value = this[key1]
					this[key1] = this[key1].replace(/\D/g,'')
					verifyResult = this[key1] == value
				}
			} else {
				if (key3) {
					let value = arg[key1][key2][key3]
					arg[key1][key2][key3] = arg[key1][key2][key3].replace(/\D/g,'')
					verifyResult = arg[key1][key2][key3] == value
				} else if(key2) {
					let value = arg[key1][key2]
					arg[key1][key2] = arg[key1][key2].replace(/\D/g,'')
					verifyResult = arg[key1][key2] == value
				} else {
					let value = arg[key1]
					arg[key1] = arg[key1].replace(/\D/g,'')
					verifyResult = arg[key1] == value
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
		},
		// 验证-输入只能由数字、字母、汉字组成
		verifyAccount(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '含有非法字符，内容只能包含数字、字母、汉字',
				type = 'warning',
				val = '',
				oldVal = '',
			} = options
			let verifyResult = false
			const regex = /^[\da-zA-Z\u4e00-\u9fa5]+$/
			console.log('val: ',val);
			if (val) {
				verifyResult = regex.test(val)
			} else {
				verifyResult = true
			}
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 验证-手机号
		verifyPhone(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message = '手机号格式不正确',
				type = 'warning',
				val = '',
				oldVal = '',
			} = options
			let verifyResult = false
			const regex = /^1[3-9]\d{9}$/
			console.log('val: ',val);
			if (val) {
				verifyResult = regex.test(val)
			} else {
				verifyResult = true
			}
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 验证-字符长度
		verifyLength(options) {
			let {
				arg = '',
				key1 = '',
				key2 = '',
				key3 = '',
				showToast = true,
				message =  `长度不能小于，长度不能大于`,
				type = 'warning',
				val = '',
				oldVal = '',
				min = '',
				max = ''
			} = options
			
			if (!message) {
				if (min && max) {
					message = `长度不能小于${min}，长度不能大于${max}`
				} else if(min) {
					message = `长度不能小于${min}`
				} else if(max) {
					message = `长度不能大于${max}`
				}
			}
			
			let verifyResult = false
			// const regex = /^1[3-9]\d{9}$/
			console.log('val: ',val);
			if (val) {
				if (min && val.length < min) {
					verifyResult = false
				} else if (max && val.length > max) {
					verifyResult = false
				} else {
					verifyResult = true
				}
			} else {
				verifyResult = true
			}
			if (options.hasOwnProperty('oldVal')) {
				if (arg == 'this') {
					if (key3) {
						this[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						this[key1][key2] = verifyResult ? val : oldVal
					} else {
						this[key1] = verifyResult ? val : oldVal
					}
				} else {
					if (key3) {
						arg[key1][key2][key3] = verifyResult ? val : oldVal
					} else if(key2) {
						arg[key1][key2] = verifyResult ? val : oldVal
					} else {
						arg[key1] = verifyResult ? val : oldVal
					}
				}
			}
			
			if (showToast && !verifyResult) {
				this.toast({
					message: message,
					type: type
				})
			}
			
			return verifyResult
			
		},
		// 复制
		copyData(text) {
			try {
				const t = document.createElement('textarea')
				t.nodeValue = text
				t.value = text
				document.body.appendChild(t)
				t.select()
				document.execCommand('copy');
				document.body.removeChild(t)
		
				this.toast({
					message: '复制成功',
					type: 'success'
				})
		
				return true
			} catch (e) {
				console.log(e)
				return false
			}
		},
		//控制富文本图片大小
		formatRichText(html) {
			let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
				match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
				match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
				match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
				return match;
			});
			newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
				match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
					'max-width:100%;');
				return match;
			});
			newContent = newContent.replace(/<br[^>]*\/>/gi, '');
			newContent = newContent.replace(/\<img/gi,
				'<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
			return newContent;
		},
	}
})