<template>
  <el-dialog :visible.sync="dialogVisible" title="预览" append-to-body :lock-scroll="false" show-close>
    <div style="background-color: #FFFFFF;height: 60px;display: flex;align-items: center;justify-content: center;border-radius: 10px 10px 0 0 ">
      <div style="color: #333333;font-size: 18px">预览</div>
      <div class="ph-close" style="margin-right: 10px" @click="dialogVisible = false"></div>
    </div>
    <video style="background-color: #000000;display: block; width:700px;height: 500px; margin: 0 auto"
           controls="controls" v-if="dialogVisible">
      <source :src="imgUrl(url)"/>
    </video>
  </el-dialog>
</template>

<script>
export default {
  name: 'videoShow',
  data() {
    return {
      url: '',
      dialogVisible: false
    };
  },
  methods: {
    open(url) {
      this.url = url
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    }

  }
};
</script>
<style>
.ph-close {
  width: 17px;
  height: 17px;
  background-image: url('../assets/058.png');
  background-size: 100% 100%;
  position: absolute;
  right: 0;
}
</style>
