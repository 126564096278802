<template>
	<div class="orderDetail">
		<div class="card">
			<div class="c-header">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text btn-text" @click="openHome">首页</div>
					<div class="chl-line">/</div>
					<div class="chl-text btn-text" @click="toAfterSale">售后管理</div>
					<div class="chl-line">/</div>
					<div class="chl-text">售后详情</div>
				</div>
				<div class="ch-right btn-fill" @click="toAfterSale">返回></div>
			</div>
			<div class="c-body" v-loading="!detail || !detail.id">
				<div class="process" v-if="detail.type == 1">
					<div class="progress-item" :class="{success: true}">
						<div class="pi-num">1</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">申请售后</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{success: detail.checkStatus > 0,active: true}">
						<div class="pi-num">2</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">售后处理中</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{success: detail.checkStatus > 0}">
						<div class="pi-num">3</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">售后完结</div>
					</div>
				</div>
				<div class="process" v-if="detail.type == 2">
					<div class="progress-item" :class="{success: true}">
						<div class="pi-num">1</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">申请售后</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{success: detail.status > 1,active: true}">
						<div class="pi-num">2</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">售后处理中</div>
						<div class="pin-line"></div>
					</div>
					<div class="progress-item" :class="{success: detail.status > 1}">
						<div class="pi-num">3</div>
						<div class="pi-icon-box">
							<div class="pi-icon"></div>
						</div>
						<div class="pin-text">售后完结</div>
					</div>
				</div>
				<div class="log">
					<div class="log-content">
						<template v-if="detail.type == 1">
							<div class="tip" v-if="detail.checkStatus == 0">平台审核中</div>
							<div class="tip" v-if="detail.checkStatus == 1">平台已赔偿</div>
							<div class="tip" v-if="detail.checkStatus == 2">赔偿已驳回 ({{detail.checkReason}})</div>
							<div class="tip" v-if="detail.checkStatus == 3">已撤销申请</div>
						</template>
						<template v-if="detail.type == 2">
							<template v-if="detail.checkStatus == 0">
								<div class="tip" v-if="detail.status == 0">平台审核中</div>
							</template>
							<template v-if="detail.checkStatus == 1">
								<template v-if="detail.status == 1">
									<div class="tip" v-if="detail.processingStatus == 0">师傅取消重新派单中</div>
									<div class="tip" v-if="detail.processingStatus == 1">师傅处理中</div>
								</template>
								<template v-if="detail.status == 2">
									<div class="tip">售后已完成</div>
								</template>
								<template v-if="detail.status == 3">
									<div class="tip">售后已关闭</div>
								</template>
							</template>
							<template v-if="detail.checkStatus == 2">
								<div class="tip">售后已驳回 ({{detail.checkReason}})</div>
							</template>
							<template v-if="detail.checkStatus == 3">
								<div class="tip">已撤销申请</div>
							</template>
						</template>
						<div class="log-list">
							<div class="ll-item">
								<div class="lli-label">原工单号：</div>
								<div class="lli-content" @click="toOrderDetail(detail.orderId)"><span class="blue" v-if="detail.oldOrder">{{detail.oldOrder.orderNo || ''}}</span></div>
							</div>
							<div class="ll-item">
								<div class="lli-label">售后单号：</div>
								<div class="lli-content" >
									<span class="blue" v-if="detail.newOrder" @click="toOrderDetail(detail.afterId)">{{detail.newOrder.orderNo || ''}}</span>
									<span class="default" v-else>--</span>
								</div>
							</div>
						</div>
					</div>
					<div class="options">
						<div class="btn btn-fill" v-if="detail.checkStatus == 0" @click="openAfterSalesCancel(detail)">撤销售后申请</div>
					</div>
				</div>
				<div class="cb-title">
					<div class="cbt-text">售后申请信息</div>
				</div>
				<div class="kehu-info">
					<div class="ki-field">
						<div class="kif-label">售后类型：</div>
						<div class="kif-value" v-if="detail.type == 1">申请赔偿</div>
						<div class="kif-value" v-if="detail.type == 2">申请售后</div>
					</div>
					<div class="ki-field" v-if="detail.money">
						<div class="kif-label">赔偿金额：</div>
						<div class="kif-value">￥{{detail.money}}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">申请理由：</div>
						<div class="kif-value">{{detail.reasons}}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">申请说明：</div>
						<div class="kif-value">{{detail.description}}</div>
					</div>
					<div class="ki-field">
						<div class="kif-label">申请凭证：</div>
						<div class="kif-value">
							<Media :value="detail.voucher" mediaClass="w100h100g20b4"></Media>
						</div>
					</div>
				</div>
				<div class="cb-title">
					<div class="cbt-text">售后记录</div>
				</div>
				<div class="kehu-info">
					<div class="ki-field">
						<div class="kif-label">申请时间：</div>
						<div class="kif-value">{{ detail.createTime }}</div>
					</div>
					<div class="ki-field" v-if="detail.checkTime">
						<div class="kif-label">通过时间：</div>
						<div class="kif-value">{{ detail.checkTime }}</div>
					</div>
					<template v-if="detail.type == 2">
						<div class="ki-field" v-if="detail.newOrder && detail.newOrder.receivingTime">
							<div class="kif-label">师傅接单时间：</div>
							<div class="kif-value">{{ detail.newOrder.receivingTime }}</div>
						</div>
						<div class="ki-field" v-if="detail.newOrder && detail.newOrder.completionTime">
							<div class="kif-label">师傅完工时间：</div>
							<div class="kif-value">{{ detail.newOrder.completionTime }}</div>
						</div>
						<div class="ki-field" v-if="detail.newOrder && detail.newOrder.completionTime">
							<div class="kif-label">售后完成时间：</div>
							<div class="kif-value">{{ detail.newOrder.completionTime }}</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		AfterSalesDetail,
		AfterSalesCancel
	} from '../common/api.js'
	export default {
		props: ['id'],
		data() {
			return {
				peijianwuliuVisible: false,
				detail: {},

			}
		},
		created() {
			this.getAfterSalesDetail()
		},
		mounted() {

		},
		methods: {
			getAfterSalesDetail() {
				AfterSalesDetail(this.id).then(res => {
					this.detail = res.data
				})
			},
			toAfterSale() {
				this.$router.push({
					name: 'afterSale'
				})
			},
			// 取消订单
			openAfterSalesCancel() {
				this.$rootApp.$refs.PopTip.open({
				  content: '是否确认取消售后？',
				  confirm: () => {
				    AfterSalesCancel(this.id).then(res => {
				    	this.toast({
				    		message: '取消成功',
				    		type: 'success'
				    	})
				    	this.getAfterSalesDetail()
				    })
				  }
				})
			},
			toOrderDetail(id) {
				this.$router.push({
					name: 'afterSaleOrderDetail',
					query: {
						id: id
					}
				})
			},
			xxxx() {

			},

		}
	}
</script>

<style lang="scss" scoped>
	.feiyongmingxi {
		.fy-item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.fyi-label {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 32px;
			}

			.fyi-value {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 32px;
			}
		}
	}


	.peijianwuliu {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 20px;
			display: flex;
			flex-direction: column;

			.pb-field {
				display: flex;
				align-items: center;
				margin-top: 20px;
				font-size: 14px;
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				color: #999999;
				line-height: 14px;

				.pbf-label {
					font-weight: 400;
				}

				.pbf-value {
					color: #000000;
				}

				.pbf-copy {
					width: 20px;
					height: 20px;
					background-image: url('../assets/068.png');
					background-size: 100% 100%;
					margin-left: auto;
				}
			}

			.pb-progress {
				display: flex;
				flex-direction: column;

				.pbp-item {
					display: flex;
					padding-top: 20px;
					position: relative;

					&:last-child {
						.pbpi-icons {
							.ii-line {
								display: none;
							}
						}
					}

					.pbpi-date {
						width: 45px;
						font-size: 14px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 400;
						color: #999999;

						.id-date {}

						.id-time {}
					}

					.pbpi-icons {
						width: 72px;
						display: flex;
						justify-content: center;
						align-items: center;

						// position: relative;
						.ii-dot {
							width: 12px;
							height: 12px;
							background: #D9D9D9;
							border-radius: 50px;
							opacity: 1;
						}

						.ii-line {
							position: absolute;
							width: 0px;
							height: 100%;
							opacity: 1;
							border: 1px solid #D9D9D9;
							transform: translate(0, 50%);
						}
					}

					.pbpi-content {
						.ic-text {
							font-size: 14px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							color: #999999;
							padding-top: 8px;
						}
					}
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}


	.yuanchengfeixinxi {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 36px;
			display: flex;
			flex-direction: column;

			.pb-text-1 {
				margin: 20px 0;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}

			.li-album {
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;

				.lia-item {
					width: 120px;
					height: 120px;
					margin-right: 20px;
					position: relative;
					border-radius: 4px;
					overflow: hidden;

					&:nth-child(4n) {
						margin-right: 0;
					}

					.liai-pic {
						width: 120px;
						height: 120px;
					}
				}
			}

			.pb-text-2 {
				margin-top: 20px;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;

				.money {
					margin-left: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FC474C;
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}

	.orderDetail {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 62px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-left {
					display: flex;
					align-items: center;

					.chl-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/048.png');
						background-size: 100% 100%;
					}

					.chl-text {
						margin: 0 5px;
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}

					.chl-line {
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}
				}

				.ch-right {
					margin-left: auto;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
				}
			}

			.c-body {
				width: 970px;
				margin: 0 auto;
				padding-bottom: 20px;

				.process {
					display: flex;
					padding: 30px 0;

					.progress-item {
						display: flex;
						align-items: center;

						&.active {
							.pi-num {
								background-color: #3571DA;
								border-color: #3571DA;
								color: #fff;
							}

							.pin-text {
								color: #18191A;
							}
						}

						&.success {
							.pi-num {
								display: none;
							}

							.pi-icon-box {
								display: flex;
							}

							.pin-text {
								color: #18191A;
							}

							.pin-line {
								background: #3571DA;
							}
						}

						.pi-num {
							width: 40px;
							height: 40px;
							box-sizing: border-box;
							border: 2px solid #B4B9BF;
							font-size: 20px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #8D9094;
							line-height: 20px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 50%;
						}

						.pi-icon-box {
							display: none;
							width: 40px;
							height: 40px;
							box-sizing: border-box;
							border: 2px solid #3571DA;
							justify-content: center;
							align-items: center;
							border-radius: 50%;

							.pi-icon {
								width: 20px;
								height: 20px;
								background-image: url('../assets/007.png');
								background-size: 100% 100%;
							}
						}

						.pin-text {
							margin-left: 6px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #8D9094;
							line-height: 24px;
							white-space: nowrap;
						}

						.pin-line {
							flex: 1;
							margin: 0 10px 0 6px;
							width: 300px;
							height: 4px;
							background: #CED4DB;
						}
					}
				}

				.log {
					box-sizing: border-box;
					padding: 20px;
					display: flex;
					background-color: #F6F8FC;

					.log-content {
						flex: 1;

						.tip {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FC474C;
							margin-bottom: 10px;
						}

						.log-list {
							.ll-item {
								margin-bottom: 10px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 24px;
								display: flex;

								.lli-time {
									margin-right: 20px;
								}

								.lli-content {
									margin-left: 10px;
									.blue {
										color: #3571DA;
										cursor: pointer;
									}
								}
							}
						}
					}

					.options {
						.btn {
							min-width: 88px;
							margin-bottom: 15px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							text-align: center;
							box-sizing: border-box;
							padding: 0 10px;

							&:first-child {
								background: #3571DA;
								border-radius: 4px 4px 4px 4px;
								line-height: 32px;
								color: #FFFFFF;
							}
						}
					}
				}

				.cb-title {
					display: flex;
					align-items: center;
					padding: 30px 0 20px;
					border-bottom: 1px solid #ECECEC;

					&.shangping {
						border: none;
					}

					.cbt-text {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.cbt-edit {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						margin-left: auto;
						line-height: 16px;
					}
				}

				.kehu-info {
					.ki-field {
						display: flex;
						margin-top: 20px;

						.kif-label {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #999999;
						}

						.kif-value {
							flex: 1;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							.kifv-pic {
								width: 100px;
								height: 100px;
								display: block;
							}
						}
					}
				}

				.list-header {
					display: flex;
					width: 970px;
					margin: 0 auto;

					.lh-item {
						width: 20%;
						background-color: #F7F7F7;
						box-sizing: border-box;
						border: 1px solid #DFDFDF;
						border-left: none;
						height: 48px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						border-right-color: transparent;

						&:nth-child(1) {
							width: 40%;
							border-left: 1px solid #DFDFDF;
							border-radius: 8px 0 0 0;
						}

						&:nth-child(4) {
							border-radius: 0 8px 0 0;
							border-right-color: #DFDFDF;
						}
					}
				}

				.list-item {
					display: flex;
					width: 970px;
					margin: 0 auto 0;

					&:last-of-type {
						.li-column {
							&:nth-child(1) {
								border-radius: 0 0 0 8px;
							}

							&:nth-child(4) {
								border-radius: 0 0 8px 0;
							}
						}
					}

					.li-column {
						width: 20%;
						box-sizing: border-box;
						border: 1px solid #DFDFDF;
						border-top: none;
						border-left: none;
						display: flex;

						&:nth-child(1) {
							width: 40%;
							border-left: 1px solid #DFDFDF;
							// border-radius: 8px 0 0 8px;
						}

						// &:nth-child(5) {
						// 	border-radius: 0 8px 8px 0;
						// }
						.column-1 {
							flex: 1;
							display: flex;
							box-sizing: border-box;
							padding: 20px;

							.c1-pic {
								flex-shrink: 0;
								width: 100px;
								height: 100px;
								margin-right: 20px;
							}

							.c1-content {
								display: flex;
								flex-direction: column;
								align-items: flex-start;

								// min-width: 170px;
								.c1c-name {
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								.c1c-field {
									margin-top: 10px;
									display: flex;

									.c1cf-label {
										font-size: 14px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #999999;
									}

									.c1cf-value {
										font-size: 14px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #333333;
									}
								}
							}
						}

						.column-2 {
							flex: 1;
							display: flex;
							justify-content: center;
							padding: 20px;

							.c2-num {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}
						}

						.column-3 {
							flex: 1;
							display: flex;
							justify-content: center;
							box-sizing: border-box;
							padding: 20px;

							.c3-price {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}
						}

						.column-4 {
							flex: 1;
							display: flex;
							justify-content: center;
							box-sizing: border-box;
							padding: 20px;

							.c4-total {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}
						}
					}
				}

				.list-footer {
					padding-top: 30px;
					display: flex;
					justify-content: flex-end;

					.lf-total {
						display: flex;
						align-items: baseline;

						.lft-label {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							margin-right: 20px;
						}

						.lft-money {
							font-size: 18px;
							font-family: Microsoft YaHei-Bold;
							font-weight: bold;
							color: #FC474C;
						}

						.lft-arrow {
							width: 16px;
							height: 16px;
							margin-left: 10px;
							background-image: url('../assets/056.png');
							background-size: 100% 100%;
						}
					}
				}
			}

		}
	}
</style>