<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="fanjianshenhe">
				<div class="pop-header">
					<div class="ph-title">返件审核</div>
					<div class="ph-close" @click="dialogVisible=false"></div>
				</div>
				<div class="pop-body">
					<div class="pb-field">
						<div class="pbf-label">快递单照片</div>
						<el-image :src="imgUrl(detail.expressBillImg)" alt="" class="pbf-image"
							:preview-src-list="[imgUrl(detail.expressBillImg)]" />
					</div>
					<div class="pb-field">
						<div class="pbf-label">快递品牌</div>
						<div class="pbf-value">{{ detail.expressCompanyName || '' }}</div>
					</div>
					<div class="pb-field" >
						<div class="pbf-label">快递单号</div>
						<div class="pbf-value">
							{{ detail.expressNumber }}
							<span class="blue" @click="$refs.wuliu.open(detail.expressNumber,detail.expressCompanyName)">查看物流></span>
						</div>
					</div>
					<div class="pb-field" v-if="detail.paymentType ==0 ">
						<div class="pbf-label">快递费</div>
						<div class="pbf-value type-1">￥{{ detail.expressFee }}</div>
					</div>
					<div class="pb-field" v-if="detail.paymentType ==0 ">
						<div class="pbf-label">支付截屏</div>
						<el-image :src="imgUrl(detail.expressVoucher)" alt="" class="pbf-image"
							:preview-src-list="[imgUrl(detail.expressVoucher)]" />
					</div>
					<div class="pb-field">
						<div class="pbf-label">返件拍照</div>
						<el-image :src="imgUrl(detail.returnedPhotos)" alt="" class="pbf-image"
							:preview-src-list="[imgUrl(detail.returnedPhotos)]" />
					</div>
					<div class="pb-field">
						<div class="pbf-label">寄送地址</div>
						<div class="pbf-shoujianxinxi">
							<div class="pbfs-row-1">
								<div class="sr-name">{{ orderDetail.manufacturerRealName || '' }}</div>
								<div class="sr-phone">{{ orderDetail.manufacturerPhone || '' }}</div>
							</div>
							<div class="pbfs-row-2">
								<div class="sr-address">{{
                    `${orderDetail.manufacturerProvinceName || ''}${orderDetail.manufacturerCityName || ''}${orderDetail.manufacturerDistrictName || ''}${orderDetail.manufacturerDetailAddress || ''}`
                  }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pop-footer" v-if="detail.checkStatus == 0">
					<div class="pf-cancel btn-fill" @click="dialogVisible1 = true">驳回</div>
					<div class="pf-confirm btn-fill" @click="submit()">通过</div>
				</div>
				<div class="pop-footer" v-else>
					<div class="pf-cancel btn-fill" @click="dialogVisible = false">关闭</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible1" :lock-scroll="false">
			<div class="fanjianshenhe">
				<div class="pop-header">
					<div class="ph-title">返件审核</div>
					<div class="ph-close" @click="dialogVisible1=false"></div>
				</div>
				<div>
					<el-input v-model="reason" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"
						placeholder="请输入驳回理由">
					</el-input>
				</div>
				<div class="pop-footer">
					<div class="pf-confirm btn-fill" @click="rejectSubmit()">提交</div>
				</div>
			</div>
		</el-dialog>
		<PopOrderWuliu ref="wuliu"></PopOrderWuliu>
	</div>
</template>

<script>
	import {
		information,
		informationByOrder,
		informationAudit
	} from '../common/api.js'
	import PopOrderWuliu from "@/components/PopOrderWuliu";

	export default {
		name: 'PopOrderFanjian',
		components: {
			PopOrderWuliu
		},
		data() {
			return {
				dialogVisible: false,
				dialogVisible1: false,
				applicationId: '',
				detail: {},
				orderId: '',
				fjId: '',
				orderDetail: {},
				reason: ''
			}
		},

		methods: {
			open(orderId, fjId, orderDetail) {
				this.orderId = orderId
				this.orderDetail = orderDetail
				this.fjId = fjId
				this.loadData()
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			loadData() {
				if (this.fjId) {
					information(this.fjId).then(res => {
						this.detail = res.data
						console.log(res)
					})
				} else {
					informationByOrder(this.orderId).then(res => {
						this.detail = res.data
						console.log(res)
					})
				}
			},
			submit() {
				let {
					orderId,
					reason
				} = this
				informationAudit(orderId, reason, 1).then(res => {
					this.toast({
						message: '提交成功',
						type: 'success'
					})
					this.close()
					this.$emit('success')
					this.loadData()
				})
			},
			rejectSubmit() {
				let {
					orderId,
					reason
				} = this
				informationAudit(orderId, reason, 2).then(res => {
					this.toast({
						message: '已驳回',
						type: 'success'
					})
					this.close()
					this.dialogVisible1 = false
					this.$emit('success')
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.fanjianshenhe {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 56px;
			display: flex;
			flex-direction: column;

			.pb-field {
				display: flex;
				margin-top: 20px;

				.pbf-label {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					width: 100px;
				}

				.pbf-image {
					width: 120px;
					height: 120px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
				}

				.pbf-value {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					
					.blue {
						color: #3571DA;
						margin-left: 10px;
						cursor: pointer;
					}

					&.type-1 {
						color: #FC474C;
					}
				}

				.pbf-shoujianxinxi {
					flex: 1;

					.pbfs-row-1 {
						display: flex;
						align-items: center;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;

						.sr-name {
							margin-right: 10px;
						}

						.sr-phone {
							margin-right: 10px;
						}

						.sr-shengfen {
							width: 44px;
							height: 22px;
							background: #FFFFFF;
							border-radius: 8px 8px 8px 8px;
							opacity: 1;
							border: 1px solid #3571DA;
							box-sizing: border-box;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 20px;
							text-align: center;
						}

						.sr-copy {
							width: 20px;
							height: 20px;
							background-image: url('../assets/068.png');
							background-size: 100% 100%;
							margin-left: auto;
						}
					}

					.pbfs-row-2 {
						margin-top: 20px;

						.sr-address {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}
					}
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				// padding: 0 16px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>