<template>
	<div class="zhiShiKu">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">知识库管理</div>
					<div class="chh-line"></div>
				</div>
			</div>
			<div class="c-body">
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">关键字：</div>
						<el-input v-model="ruleForm.title" class="input input-1" placeholder="请输入" clearable></el-input>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="cb-row">
					<div class="cbr-add-btn btn-fill" @click="openPopAddBase()">
						<div class="cbra-icon"></div>
						<div class="cbra-text">添加</div>
					</div>
				</div>
				<div class="table-wrap">
					<div class="table">
						<el-table v-loading="isLoading && baseList.length" :data="baseList" style="width: 100%"
							header-row-class-name="header-row-class" header-cell-class-name="header-cell-class"
							cell-class-name="cell-class" row-class-name="row-class">
							<div class="empty-wrap" v-loading="isLoading" v-if="!baseList.length" slot="empty">
								<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"></el-empty>
							</div>
							<el-table-column prop="title" label="标题" align="center">
							</el-table-column>
							<el-table-column prop="createTime" label="创建时间" align="center">
							</el-table-column>
							<el-table-column prop="field_3" label="文件类型" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.fileType | fileTypeFilter}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="" label="操作" align="center">
								<template slot-scope="scope">
									<!-- <span class="eye-btn"></span> -->
									<span class="download-btn pointer" @click="downloadBase(scope.row)"></span>
									<span class="edit-btn pointer" @click="openPopAddBase(scope.row)"></span>
									<span class="del-btn pointer" @click="removeBase(scope.row)"></span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination background :current-page.sync="pageNum" :page-size.sync="pageSize"
							layout="sizes, prev, pager, next" :total="total" @size-change="loadData"
							:page-sizes="pageSizesOptions" @current-change="loadData">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- 添加知识库 -->
		<PopAddBase ref="PopAddBase" :show:sync="showPopAddBase" @addSuccess="loadData" @editSuccess="loadData">
		</PopAddBase>
		<!-- 删除提示 -->
		<PopTip ref="PopTip" :show.sync="showPopTip"></PopTip>
	</div>
</template>

<script>
	import {
		BaseList,
		RemoveBase
	} from '../common/api.js'
	import PopAddBase from '../components/PopAddBase.vue'
	import {
		fileDownload
	} from '../common/downLoadFile.js'
	import {
		getFileExt
	} from '../common/h5.js'
	var ruleForm = {
		fileType: '', // 文件类型
		fileUrl: '', // 文件路径
		title: '', // 标题
		id: '', // id
	}
	export default {
		components: {
			PopAddBase
		},
		data() {
			return {
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: '',
				baseList: [],

				ruleForm: {
					...ruleForm
				},
				rules: {},

				total: 0,
				pageNum: 1,
				pageSize: 10,
				isLoading: true,

				showPopAddBase: false,
				showPopTip: false,

			}
		},
		filters: {
			fileTypeFilter(v) {
				// 文件类型 1=图片 2=视频 3=pdf 4=word 5=excel 6=ppt 7=txt
				if (v == 1) {
					return '图片'
				} else if (v == 2) {
					return '视频'
				} else if (v == 3) {
					return 'pdf'
				} else if (v == 4) {
					return 'word'
				} else if (v == 5) {
					return 'excel'
				} else if (v == 6) {
					return 'ppt'
				} else if (v == 7) {
					return 'txt'
				} else {
					return ''
				}
			}

		},
		created() {
			this.loadData()

		},
		mounted() {

		},
		methods: {
			reload() {
				this.pageNum = 1
				this.loadData()
			},
			// 查询知识库列表
			loadData() {
				let data = {
					fileType: this.ruleForm.fileType, // 文件类型
					fileUrl: this.ruleForm.fileUrl, // 文件路径
					title: this.ruleForm.title, // 标题
					id: this.ruleForm.id, // id
					pageSize: this.pageSize,
					pageNum: this.pageNum
				}
				// console.log('data: ',data);
				this.isLoading = true
				BaseList(data).then(res => {
					this.baseList = res.rows
					this.total = res.total
					this.$bus.$emit('backTop')
				}).finally(() => {
					this.isLoading = false
				})
			},
			// 筛选
			filter() {
				this.reload()
			},
			// 重置
			reset() {
				this.ruleForm = {
					...ruleForm
				}
				this.reload()
			},
			// 下载知识库
			downloadBase(data) {
				// console.log('this.downloadFile: ',this.downloadFile);
				let name = `${data.title}.${getFileExt(data.fileUrl)}`
				fileDownload(data.fileUrl, name)

				// this.downloadFile(data.fileUrl)
			},
			// 添加知识库
			openPopAddBase(data) {
				this.$refs.PopAddBase.open(data)
			},
			// 删除知识库
			removeBase(row) {
				this.$refs.PopTip.open({
					content: '确定要删除该知识库吗?',
					confirm: () => {
						RemoveBase(row.id).then(res => {
							this.toast({
								message: '删除成功',
								type: 'success'
							})
							this.loadData()
						})
					}
				})

			}

		}
	}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.zhiShiKu {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 56px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-headingbox {
					position: relative;

					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
			}

			.c-body {
				padding-top: 20px;

				.cb-row {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					margin-bottom: 20px;

					.cbr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;

						.cbri-label {
							width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						/deep/.input {
							width: 216px;

							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.select-1 {
								width: 216px;
							}
						}
					}

					.cbr-shaixuan-btn {
						width: 60px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}

					.cbr-reset-btn {
						width: 60px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.cbr-add-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;

						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/045.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}

						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 14px;
						}
					}
				}

				.table-wrap {
					margin-top: 20px;

					/deep/.table {
						width: 970px;
						border: 1px solid #DFDFDF;
						border-radius: 8px 8px 0 0;
						margin: 0 auto;

						colgroup {
							col {
								&:nth-child(1) {
									// width: 150px;
								}
							}
						}

						.header-row-class {}

						.row-class {
							&:last-child {
								.cell-class {
									border: none;
								}
							}
						}

						.header-cell-class {
							background-color: #F7F7F7;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}

						.cell-class {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;

							.cell {
								padding: 0;
							}

							&:nth-child(3) {
								.cell {
									color: #3571DA;
								}
							}

							&:nth-child(4) {
								.cell {
									display: flex;
									justify-content: center;
									align-items: center;
								}
							}
						}

						.eye-btn {}

						.download-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/046.png');
							background-size: 20px 20px;
							margin: 0 10px;
						}

						.edit-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/070.png');
							background-size: 20px 20px;
							margin: 0 10px;
						}

						.del-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/071.png');
							background-size: 20px 20px;
							margin: 0 10px;
						}

					}
				}

				.pagination-wrap {
					padding: 20px 0;

					/deep/.pagination {
						display: flex;
						justify-content: center;
					}
				}

			}
		}
	}
</style>