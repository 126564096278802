<template>
	<div class="NoData">
		<div class="nd-body">
			<div class="ndb-pic"></div>
			<div class="ndb-text">暂无数据</div>
		</div>
	</div>
</template>

<script>
	var that = {}
	export default {
		name: 'NoData',//添加子账号
		data() {
			return {
				
			}
		},
		created() {
			that = this
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.NoData {
		.nd-body {
			display: flex;
			flex-direction: column;
			align-items: center;
		
			.ndb-pic {
				width: 238px;
				height: 158px;
				background-image: url('../assets/069.png');
				background-size: 100% 100%;
				
			}
		
			.ndb-text {
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 14px;
			}
		
			.se-add-btn {
				margin-top: 20px;
				// width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #3571DA;
				padding: 0 10px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 32px;
			}
		}
	}
</style>