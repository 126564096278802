<template>
	<div class="zhangHao">
		<div class="z-header">
			<div class="zh-tab">
				<div class="zht-item pointer" :class="{active: index==tabIndex}" v-for="(item,index) in tabList"
					:key="`zh-tab-${index}`" @click="tabClick(item,index)">
					<div class="zhti-text">{{item}}</div>
					<div class="zhti-line"></div>
				</div>
			</div>
		</div>
		<div class="z-body main" v-if="tabIndex==0">
			<div class="table-wrap">
				<div class="add-btn btn-fill" @click="openPopAddSubAccounts()">添加子账号</div>
				<div class="table">
					<el-table v-loading="isLoading && subAccountsList.length" :data="subAccountsList"
						style="width: 100%" header-row-class-name="header-row-class"
						header-cell-class-name="header-cell-class" cell-class-name="cell-class"
						row-class-name="row-class">
						<div class="empty-wrap" v-loading="isLoading" v-if="!subAccountsList.length" slot="empty">
							<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"></el-empty>
						</div>
						<el-table-column prop="name" label="用户昵称" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="联系方式" align="center">
						</el-table-column>
						<el-table-column prop="account" label="登录账号" align="center">
						</el-table-column>
						<el-table-column prop="" label="登录密码" align="center">
							********
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<span class="edit-btn btn-fill" @click="openPopAddSubAccounts(scope.row)">修改</span>
								<span class="del-btn btn-fill" @click="delSubAccounts(scope.row)">删除</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- <div class="status-empty" v-else>
				<NoData></NoData>
				<div class="se-add-btn btn-fill" @click="openPopAddSubAccounts()">添加子账号</div>
			</div> -->
		</div>

		<div class="z-body quanxian" v-if="tabIndex==1">
			<div class="zb-xuanze-zhanghao">
				<div class="zbx-label">
					<span class="star">*</span>
					<span class="label">选择账号：</span>
				</div>
				<div class="zbx-select">
					<el-select class="select type-1" v-model="userId" placeholder="请选择" value-key="phone">
						<el-option v-for="item in subAccountsList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="zb-form">
				<div class="zbf-header">
					<div class="checked-Box type-1">
						<el-checkbox name="checked" v-model="checkAll" :disabled="!userId" :false-label="0"
							:true-label="1" @change="handleCheckAllChange">
							<div class="cb-label">
								全选
							</div>
						</el-checkbox>
					</div>
				</div>
				<div class="yiJi" v-for="(subAccountMenuitem,subAccountMenuindex) in subAccountMenu"
					:key="`subAccountMenu-${subAccountMenuindex}`">
					<div class="zbf-heading" v-if="subAccountMenuitem.menuType == 0">{{subAccountMenuitem.name}}</div>
					<div class="erJi" v-for="(erJiChilditem,erJiChildindex) in subAccountMenuitem.child"
						:key="`erJiChild-${erJiChildindex}`">
						<div class="zbf-row">
							<div class="checked-Box type-2">
								<el-checkbox name="checked" v-model="erJiChilditem.isHave" :false-label="0"
									:true-label="1" @change="handleCheckChange($event,erJiChilditem)">
									<div class="cb-label">
										{{erJiChilditem.name}}
									</div>
								</el-checkbox>
								<el-radio-group v-if="erJiChilditem.dataFlag == 1" :disabled="erJiChilditem.isHave == 0"
									v-model="erJiChilditem.dataPermissions"
									@input="handleCheckRadioChange($event,erJiChilditem)">
									<el-radio :label="0">全部数据</el-radio>
									<el-radio :label="1">当前子账户数据</el-radio>
								</el-radio-group>
								<!-- <template v-if="erJiChilditem.dataFlag == 1">
									<el-radio v-model="erJiChilditem.dataPermissions" :label="0">全部数据</el-radio>
									<el-radio v-model="erJiChilditem.dataPermissions" :label="1">当前子账户数据</el-radio>
								</template> -->
							</div>
						</div>
						<div class="zbf-row sanJi" v-if="erJiChilditem.child.length">
							<div class="checked-Box type-3"
								v-for="(sanJiChilditem,sanJiChildindex) in erJiChilditem.child"
								:key="`sanJiChild-${sanJiChildindex}`">
								<el-checkbox name="checked" v-model="sanJiChilditem.isHave"
									:disabled="erJiChilditem.isHave == 0" :false-label="0" :true-label="1"
									@change="handleCheckChange($event,sanJiChilditem)">
									<div class="cb-label">
										{{sanJiChilditem.name}}
									</div>
								</el-checkbox>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 添加子账号 -->
		<PopAddSubAccounts ref="PopAddSubAccounts" :show.sync="showPopAddSubAccounts"
			@addSuccess="getUserGetSubAccounts" @editSuccess="getUserGetSubAccounts"></PopAddSubAccounts>
		<!-- 温馨提示 -->
		<PopTip ref="PopTip" :show.sync="showPopTip"></PopTip>

	</div>
</template>

<script>
	import {
		UserGetSubAccounts,
		UserGetSubAccountMenu,
		UserDeleteSubAccounts,
		UserAddSubAccountsMenu,
		UserDelectSubAccountMenu,
		UserSetSubAccountMenuAll,
		UpDataSubAccountsMenu
	} from '../common/api.js'
	import PopAddSubAccounts from '../components/PopAddSubAccounts.vue'
	export default {
		components: {
			PopAddSubAccounts
		},
		data() {
			return {
				tabList: ['子账号管理', '子账号权限设置'],
				tabIndex: 0,

				showPopAddSubAccounts: false,
				subAccountsList: [],
				show: false,
				userId: '',
				subAccountMenu: [],
				showPopTip: false,

				checkAll: false,
				isLoading: true,


			}
		},
		watch: {
			userId(val) {
				if (val) {
					this.getUserGetSubAccountMenu()
				} else {

				}
			}
		},
		async created() {
			await this.getUserGetSubAccounts()
			this.show = true
		},
		mounted() {

		},
		methods: {
			// 获取子账号
			async getUserGetSubAccounts() {
				this.isLoading = true
				await UserGetSubAccounts().then(
					res => {
						this.subAccountsList = res.data
						if (this.subAccountsList && this.subAccountsList.length) {
							this.userId = this.subAccountsList[0].id
						}

					},
					err => {

					}
				).finally(() => {
					this.isLoading = false
				})
			},
			// 获取子账号菜单
			getUserGetSubAccountMenu() {
				UserGetSubAccountMenu(this.userId).then(res => {
					this.subAccountMenu = res.data
				})
			},
			tabClick(item, index) {
				this.tabIndex = index
			},
			rowClassNameCallback({
				row,
				rowIndex
			}) {
				console.log('row: ', row);
				console.log('rowIndex: ', rowIndex);
				return '111'
			},
			openPopAddSubAccounts(row) {
				this.$refs.PopAddSubAccounts.open(row)
			},
			delSubAccounts(row) {
				this.$refs.PopTip.open({
					content: '确定要删除该子账号吗?',
					confirm: () => {
						UserDeleteSubAccounts(row.id).then(res => {
							this.toast({
								message: '删除成功',
								type: 'success'
							})
							this.getUserGetSubAccounts()
						})
					}
				})
			},
			handleCheckChange(val, item) {
				console.log('val: ', val);
				if (val == 1) {
					let data = {
						menuId: item.id, // 菜单ID
						dataPermissions: item.dataPermissions, // 数据权限（0全部数据 1仅子账号权限）
					}
					UserAddSubAccountsMenu(this.userId, data).then(res => {
						this.getUserGetSubAccountMenu()
					})
				} else {
					UserDelectSubAccountMenu(item.id, this.userId).then(res => {
						this.getUserGetSubAccountMenu()
					})
				}
			},
			handleCheckRadioChange(val, item) {
				let data = {
					menuId: item.id, // 菜单ID
					dataPermissions: item.dataPermissions, // 数据权限（0全部数据 1仅子账号权限）
				}
				UpDataSubAccountsMenu(this.userId, data).then(res => {
					this.getUserGetSubAccountMenu()
				})
			},
			// 全选
			handleCheckAllChange(val) {
				UserSetSubAccountMenuAll(this.userId, val).then(res => {
					this.getUserGetSubAccountMenu()
				})
			}

		}
	}
</script>

<style lang="scss" scoped>
	.el-radio-group {
		display: flex;
		align-items: center;
		margin-left: 30px;
	}

	.zhangHao {
		.z-header {
			height: 50px;
			border-bottom: 1px solid #EDEDED;

			.zh-tab {
				padding-left: 20px;
				display: flex;

				.zht-item {
					height: 50px;
					display: flex;
					position: relative;
					margin-right: 30px;
					box-sizing: border-box;
					padding-top: 20px;

					.zhti-text {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 16px;
					}

					.zhti-line {
						display: none;
						width: 44px;
						height: 0;
						border-bottom: 4px solid #3571DA;
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%, 0);
					}

					&.active {
						.zhti-text {
							font-weight: bold;
							color: #3571DA;
						}

						.zhti-line {
							display: initial;
						}
					}
				}
			}
		}

		.z-body.main {
			.status-empty {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 60px;
				padding-bottom: 60px;

				.se-add-btn {
					margin-top: 20px;
					// width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #3571DA;
					padding: 0 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 32px;
				}
			}

			.table-wrap {
				.add-btn {
					display: inline-block;
					margin-top: 20px;
					margin-left: 20px;
					// width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #3571DA;
					padding: 0 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 32px;
				}

				/deep/.table {
					width: 970px;
					border: 1px solid #DFDFDF;
					border-radius: 8px 8px 0 0;
					margin: 20px auto;

					.header-row-class {}

					.row-class {
						&:last-child {
							.cell-class {
								border: none;
							}
						}
					}

					.header-cell-class {
						background-color: #F7F7F7;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;
					}

					.cell-class {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					.edit-btn,
					.del-btn {
						color: #3571DA;
						margin: 0 10px;
					}
				}
			}
		}

		.z-body.quanxian {

			.checked-Box {
				display: flex;
				align-items: center;
				min-height: 48px;

				&.type-1 {
					.cb-label {
						height: 20px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #333333;
						line-height: 20px;

					}
				}

				&.type-2 {
					.cb-label {
						height: 24px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 24px;
						text-transform: none;

					}
				}

				&.type-3 {
					display: flex;
					flex-wrap: wrap;
					// padding-left: 100px;
					box-sizing: border-box;

					// .mul-checkbox {
					width: 33.33%;

					// }
					.cb-label {
						height: 24px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 24px;
						text-transform: none;

					}
				}

				/deep/.el-checkbox {
					.el-checkbox__inner {
						width: 18px;
						height: 18px;
					}

					.el-checkbox__inner::after {
						width: 4px;
						height: 10px;
						top: 1px;
					}
				}


			}

			.zb-xuanze-zhanghao {
				height: 72px;
				box-sizing: border-box;
				padding: 0 20px;
				display: flex;
				align-items: center;

				.zbx-label {
					margin-right: 10px;

					.star {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #FF0000;
						line-height: 14px;
						margin-right: 4px;
					}

					.label {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 14px;
					}
				}

				.zbx-select {
					/deep/.select {
						input {
							height: 32px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							padding-left: 8px;
						}

						.el-input__suffix {
							display: flex;
							align-items: center;
						}

						&.type-1 {
							width: 216px;
						}

					}

					.zbxs-value {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
					}

					.zbxs-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/038.png');
						background-size: 100% 100%;
						margin-right: 8px;
					}
				}
			}

			.mul-checkbox {
				display: flex;
				align-items: center;

				.checkbox {
					width: 18px;
					height: 18px;

					.no {
						width: 100%;
						height: 100%;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #CDCDCD;
						box-sizing: border-box;
					}

					.yes {
						width: 100%;
						height: 100%;
						background-image: url('../assets/039.png');
						background-size: 100% 100%;
					}
				}

				.label {
					margin-left: 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 18px;
				}
			}

			.sin-checkbox {
				display: flex;
				align-items: center;

				.checkbox {
					width: 16px;
					height: 16px;

					.no {
						width: 100%;
						height: 100%;
						background: #FFFFFF;
						border-radius: 50%;
						box-sizing: border-box;
						border: 1px solid #CED4DB;
					}

					.yes {
						width: 100%;
						height: 100%;
						background-image: url('../assets/040.png');
						background-size: 100% 100%;
					}
				}

				.label {
					margin-left: 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
			}

			.zb-form {
				width: 970px;
				// height: 614px;
				box-sizing: border-box;
				border: 1px solid #DFDFDF;
				border-bottom: none;
				border-radius: 8px 8px 0 0;
				margin: 0 auto;
				margin-bottom: 20px;

				.zbf-header {
					height: 38px;
					background-color: #DFDFDF;
					padding-left: 20px;
					display: flex;
					align-items: center;

					.mul-checkbox {
						.label {
							font-size: 12px;
						}
					}

				}

				.zbf-heading {
					height: 48px;
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 48px;
					border-bottom: 1px solid #DFDFDF;
				}

				.zbf-row {
					min-height: 48px;
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					border-bottom: 1px solid #DFDFDF;

					&.sanJi {
						display: flex;
						flex-wrap: wrap;
						padding-left: 100px;
					}

					&.row-9 {
						border: none;
					}
				}
			}

		}
	}

	// .yiJi {
	// 	.erJi {
	// 		&:last-of-type {
	// 			border: none !important;
	// 		}
	// 		.sanJi {

	// 		}
	// 	}
	// }
</style>