<template>
	<div>
		<!-- <el-dialog :visible.sync="dialogVisible" :lock-scroll="false" :destroy-on-close="true"> -->
			<div class="peijianwuliu">
				<div class="pop-header">
					<div class="ph-title">物流信息</div>
					<!-- <div class="ph-close" @click="dialogVisible =false"></div> -->
				</div>
				<div class="pop-body" >
					<div v-for="(item,index) in list" :key="index">
						<div class="pb-field">
							<div class="pbf-label">快递公司：</div>
							<div class="pbf-value">{{ item.logisticsCompany }}</div>
						</div>
						<div class="pb-field">
							<div class="pbf-label">物流单号：</div>
							<div class="pbf-value">{{ item.logisticsNum }}</div>
							<div class="pbf-copy pointer" @click="copy(item.logisticsNum)"></div>
						</div>
						<div class="pb-progress" v-if="item.cmcLogisticsDetailList">
							<div class="pbp-item" v-for="subitem in item.cmcLogisticsDetailList">
								<div class="pbpi-date">
									<div class="id-date">{{ timeFormateWl(item.createTime).time2 }}</div>
									<div class="id-time">{{ timeFormateWl(item.createTime).time1 }}</div>
								</div>
								<div class="pbpi-icons">
									<div class="ii-dot"></div>
									<div class="ii-line"></div>
								</div>
								<div class="pbpi-content">
									<div class="ic-text">{{ subitem.context }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="dialogVisible =false">关闭</div>
				</div> -->
			</div>
		<!-- </el-dialog> -->
	</div>
</template>

<script>

	export default {
		name: 'PopOrderWuliu',
		props: ['list'],
		data() {
			return {
				
			}
		},

		methods: {
			timeFormateWl(time) {
				const newtime = new Date(time);
				let hour = this.getIncrease(newtime.getHours(), 2);
				let minitus = this.getIncrease(newtime.getMinutes(), 2);
				return {
					time1: `${this.getIncrease(newtime.getMonth() + 1, 2)}-${this.getIncrease(newtime.getDate(), 2)}`,
					time2: `${hour}:${minitus}`
				};
			},
			copy(data) {
				// var num = document.querySelector(`.pbf-copy`)
				// let content = num.innerHTML
				// console.log('content: ',content);
				this.copyData(data)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.peijianwuliu {
		width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 20px 20px 0;
			display: flex;
			flex-direction: column;
			height: 60vh;
			overflow: auto;
			margin-right: -20px;

			.pb-field {
				display: flex;
				align-items: center;
				margin-top: 20px;
				font-size: 14px;
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				color: #999999;
				line-height: 14px;

				.pbf-label {
					font-weight: 400;
				}

				.pbf-value {
					color: #000000;
				}

				.pbf-copy {
					width: 20px;
					height: 20px;
					background-image: url('../assets/068.png');
					background-size: 100% 100%;
					margin-left: auto;
				}
			}

			.pb-progress {
				display: flex;
				flex-direction: column;

				.pbp-item {
					display: flex;
					padding-top: 20px;
					position: relative;

					&:last-child {
						.pbpi-icons {
							.ii-line {
								display: none;
							}
						}
					}

					.pbpi-date {
						width: 45px;
						font-size: 14px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 400;
						color: #999999;

						.id-date {}

						.id-time {}
					}

					.pbpi-icons {
						width: 72px;
						display: flex;
						justify-content: center;
						align-items: center;

						// position: relative;
						.ii-dot {
							width: 12px;
							height: 12px;
							background: #D9D9D9;
							border-radius: 50px;
							opacity: 1;
						}

						.ii-line {
							position: absolute;
							width: 0px;
							height: 100%;
							opacity: 1;
							border: 1px solid #D9D9D9;
							transform: translate(0, 50%);
						}
					}

					.pbpi-content {
						flex: 1;

						.ic-text {
							font-size: 14px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							color: #999999;
							padding-top: 8px;
						}
					}
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>