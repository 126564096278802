<template>
	<div class="accountRecharge">
		<div class="card">
			<div class="z-header">
				<div class="zh-tab">
					<div class="zht-item active">
						<div class="zhti-text">账户充值</div>
						<div class="zhti-line"></div>
					</div>
				</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="c-body" v-if="!isSubmitSuccess">
				<div class="cb-row">
					<div class="cbr-label">账户余额：</div>
					<div class="cbr-content">
						<div class="cbrc-text big blue">￥{{walletGetInfo.balance}}</div>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label">充值账号：</div>
					<div class="cbr-content">
						<div class="cbrc-text">{{userInfo.phone | starFilter(4,4,'*')}}</div>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label" style="align-self: flex-start;">充值金额：</div>
					<div class="cbr-content">
						<el-form-item prop="rechargeAmount" class="form-item">
							<div class="cbrc-num-wrap">
								<el-radio v-model="ruleForm.rechargeAmount" label="500">
									<div class="cbrc-num" :class="{active: ruleForm.rechargeAmount == 500}">500元</div>
								</el-radio>
								<el-radio v-model="ruleForm.rechargeAmount" label="1000">
									<div class="cbrc-num" :class="{active: ruleForm.rechargeAmount == 1000}">1000元</div>
								</el-radio>
								<el-radio v-model="ruleForm.rechargeAmount" label="3000">
									<div class="cbrc-num" :class="{active: ruleForm.rechargeAmount == 3000}">3000元</div>
								</el-radio>
								<el-radio v-model="ruleForm.rechargeAmount" label="5000">
									<div class="cbrc-num" :class="{active: ruleForm.rechargeAmount == 5000}">5000元</div>
								</el-radio>
							</div>
							<div class="cbr-content checkedCustomRechargeAmount" >
								<template v-if="checkedCustomRechargeAmount">
									<el-form-item prop="" class="form-item input-customRechargeAmount-wrap">
										<el-input v-model="customRechargeAmount" class="input type-8"
											placeholder="请输入金额" clearable
											@input="verifyPositiveInteger('this',{key1: 'customRechargeAmount'})"></el-input>
									</el-form-item>
								</template>
								<div class="mul-checkbox pointer" @click="checkedCustomRechargeAmountClick">
									<div class="checkbox">
										<div class="no" v-if="!checkedCustomRechargeAmount"></div>
										<div class="yes" v-else></div>
									</div>
									<div class="label">自定义充值金额</div>
								</div>
							</div>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row zhifu-fangshi">
					<div class="cbr-label">支付方式：</div>
					<div class="cbr-content" v-if="shoukuanQrcode.configValue">
						<el-image :src="imgUrl(shoukuanQrcode.configValue)"
							:preview-src-list="[imgUrl(shoukuanQrcode.configValue)]" alt="" fit="cover"
							class="cbrc-qrcode" />
					</div>
				</div>
				<div class="cb-row sahngchuan-pingzheng">
					<div class="cbr-label">上传凭证：</div>
					<div class="cbr-content">
						<el-form-item prop="credentials" class="form-item">
							<FileUpload class="upload-demo" className="w100h100" v-model="ruleForm.credentials"
								:limit="1" dir="licence">
								<div class="zhizhao-upload">
									<div class="zu-icon"></div>
									<div class="zu-label">上传图片</div>
								</div>
							</FileUpload>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row beizhu">
					<div class="cbr-label">备注：</div>
					<div class="cbr-content">
						<div class="cbr-content">
							<el-form-item prop="" class="form-item input-5-wrap">
								<el-input v-model="ruleForm.remarks" class="textarea textarea-1" type="textarea"
									:show-word-limit="true" :maxlength="400" :rows="2" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label"></div>
					<div class="cbr-content">
						<div class="add-btn btn-3 btn-fill" @click="submit">
							<div class="ab-text ">提交</div>
						</div>
					</div>
				</div>
			</el-form>
			<div class="c-body" v-else>
				<div class="submit-success">
					<div class="ss-icon"></div>
					<div class="ss-title">提交成功</div>
					<div class="ss-btn btn-fill" @click="again">再次充值</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WalletGetInfo,
		RecordssAction,
		Config
	} from '../common/api.js'
	var ruleForm = {
		credentials: '', // 凭证
		rechargeAmount: '', // 充值金额
		remarks: '', // 备注
	}
	export default {
		data() {
			var rechargeAmountChecked = (rule, value, callback) => {
				if (!this.checkedCustomRechargeAmount) {
					if (this.ruleForm.rechargeAmount) {
						callback();
					} else {
						return callback(new Error('请选择或自定义充值金额'));
					}
				} else {
					if (this.customRechargeAmount) {
						callback();
					} else {
						return callback(new Error('请选择或自定义充值金额'));
					}
				}

			}
			return {
				ruleForm: {
					...ruleForm
				},
				rules: {
					credentials: [{
						required: true,
						message: '请上传凭证',
						trigger: 'blur'
					}],
					rechargeAmount: [{
						required: true,
						// message: '请选择充值金额',
						trigger: 'blur',
						validator: rechargeAmountChecked
					}],
				},
				walletGetInfo: {}, //
				shoukuanQrcode: {},
				isSubmitSuccess: false,
				customRechargeAmount: '',
				checkedCustomRechargeAmount: false,

			}
		},
		watch: {
			'ruleForm.rechargeAmount': function(val) {
				if (val) {
					this.checkedCustomRechargeAmount = false
				}
			},
			checkedCustomRechargeAmount: function(val) {
				if (val) {
					this.customRechargeAmount = ''
					this.ruleForm.rechargeAmount = ''
				}

			},
		},
		created() {
			this.getWalletGetInfo()
			Config('shoukuanQrcode').then(res => {
				this.shoukuanQrcode = res.data
			})

		},
		mounted() {

		},
		methods: {
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							credentials: this.ruleForm.credentials, // 凭证
							rechargeAmount: this.ruleForm.rechargeAmount, // 充值金额
							remarks: this.ruleForm.remarks, // 备注
						}

						if (!this.checkedCustomRechargeAmount) {
							data.rechargeAmount = this.ruleForm.rechargeAmount
						} else {
							data.rechargeAmount = this.customRechargeAmount
						}
						
						RecordssAction(data).then(res => {
							this.isSubmitSuccess = true
							this.toast({
								message: '提交成功',
								type: 'success'
							})
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 查询我的账户详细信息
			getWalletGetInfo() {
				WalletGetInfo().then(res => {
					this.walletGetInfo = res.data
				})
			},
			again() {
				this.ruleForm = {
					...ruleForm
				}
				this.isSubmitSuccess = false
			},
			checkedCustomRechargeAmountClick() {
				this.checkedCustomRechargeAmount = !this.checkedCustomRechargeAmount
			},


		}
	}
</script>

<style lang="scss" scoped>
	.mul-checkbox-wrap {
		position: absolute;
		top: 0;
		right: 0;
		width: 38px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			.checkbox {
				.no {
					border: 1px solid #3571da;
				}
			}
		}
	}

	.mul-checkbox {
		display: flex;
		align-items: center;
		// margin-left: 10px;

		&:hover {
			.checkbox {
				.no {
					border: 1px solid #3571da;
				}
			}
		}

		.checkbox {
			width: 18px;
			height: 18px;

			.no {
				width: 100%;
				height: 100%;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #CDCDCD;
				box-sizing: border-box;
			}

			.yes {
				width: 100%;
				height: 100%;
				background-image: url('../assets/039.png');
				background-size: 100% 100%;
			}
		}

		.label {
			margin-left: 10px;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 18px;

			.num {
				margin: 0 5px;
			}
		}
	}

	.accountRecharge {
		.card {
			.z-header {
				height: 50px;
				border-bottom: 1px solid #EDEDED;

				.zh-tab {
					padding-left: 20px;
					display: flex;

					.zht-item {
						height: 50px;
						display: flex;
						position: relative;
						margin-right: 30px;
						box-sizing: border-box;
						padding-top: 20px;

						.zhti-text {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 16px;
						}

						.zhti-line {
							display: none;
							width: 44px;
							height: 0;
							border-bottom: 4px solid #3571DA;
							border-left: 4px solid transparent;
							border-right: 4px solid transparent;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translate(-50%, 0);
						}

						&.active {
							.zhti-text {
								font-weight: bold;
								color: #3571DA;
							}

							.zhti-line {
								display: initial;
							}
						}
					}
				}
			}

			.c-body {
				box-sizing: border-box;
				padding: 20px 20px 100px;

				.submit-success {
					// height: 350px;
					display: flex;
					flex-direction: column;
					align-items: center;

					.ss-icon {
						width: 64px;
						height: 64px;
						background-image: url('../assets/009.png');
						background-size: 100% 100%;
						margin-top: 60px;

					}

					.ss-title {
						margin-top: 20px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						line-height: 14px;
					}

					.ss-btn {
						margin-top: 20px;
						width: 88px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #3571DA;
						line-height: 22px;
						text-align: center;
						line-height: calc(32px - 2px);
					}
				}

				.cb-row {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					&.zhifu-fangshi,
					&.sahngchuan-pingzheng,
					&.beizhu {
						align-items: flex-start;
					}

					.cbr-label {
						width: 80px;
						box-sizing: border-box;
						padding-right: 20px;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					.cbr-content {
						display: flex;
						align-items: center;
						
						&.checkedCustomRechargeAmount {
							margin-top: 20px;
						}

						/deep/.el-radio {
							.el-radio__input {
								display: none;
							}

							.el-radio__label {
								padding-left: 0;
							}
						}

						.cbrc-text {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;

							&.big {
								font-size: 22px;
								font-weight: bold;
							}

							&.blue {
								color: #3571DA;
							}
						}

						.cbrc-num-wrap {
							display: flex;

							.cbrc-num {
								width: 88px;
								height: 32px;
								border-radius: 4px 4px 4px 4px;
								box-sizing: border-box;
								border: 1px solid #3571DA;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #3571DA;
								line-height: 14px;

								// margin-right: 20px;
								&.active {
									background-color: #3571DA;
									color: #FFFFFF;
								}
							}
						}

						.cbrc-qrcode {
							width: 200px;
							// min-height: 200px;
							// height: 226px;
						}

						.form-item {
							&.input-customRechargeAmount-wrap {
								margin-right: 10px;
							}
							&.input-5-wrap {
								margin-right: 20px;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.input-1,
							&.input-3,
							&.input-4,
							&.input-2 {
								width: 320px;
							}

							&.input-5,
							&.input-6 {
								width: 300px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.select-1 {
								width: 300px;
							}
						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.textarea-1 {
								width: 580px;
							}
						}


						.upload-demo {
							// background: #FFFFFF;
							width: 100px;
							height: 100px;
							overflow: hidden;
							// border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							// margin-right: 10px;

							.zhizhao-upload {
								width: 100px;
								height: 100px;
								background: #F6F8FC;
								border-radius: 4px 4px 4px 4px;
								box-sizing: border-box;
								border: 1px dashed #3571DA;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;

								.zu-icon {
									width: 28px;
									height: 28px;
									background-image: url('../assets/043.png');
									background-size: 100% 100%;
								}

								.zu-label {
									margin-top: 8px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #8D9094;
									line-height: 16px;
								}
							}
						}

						.add-btn {
							width: 84px;
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							display: flex;
							justify-content: center;
							align-items: center;

							.ab-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/044.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}

							.ab-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}

							&.btn-1 {
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	}
</style>