<template>
	<div class="PopAddBase">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="tianjiazizhanghao">
				<div class="pop-header">
					<div class="ph-title">
						<span>{{title}}</span>
					</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="pop-body">
					<div class="cb-row">
						<!-- <div class="cbr-label">标题：</div> -->
						<div class="cbr-content">
							<el-form-item prop="content" class="form-item yanzhengma">
								<el-input v-model="ruleForm.content" class="textarea type-1" type="textarea" placeholder="请输入" clearable></el-input>
								<!-- <el-input class="textarea type-2" type="textarea" :show-word-limit="true"
									v-model="content" :maxlength="400" :rows="2"
									placeholder="请输入内容。这是一个文本框占位示例。可固定高度或者使用自动高度。" clearable></el-input> -->
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">取消</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	var that = {}
	var ruleForm = {
		content: '', // 
		confirm: () => {}
	}
	export default {
		name: 'PopTextarea',//文本域
		props: {
			show: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: '备注'
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {...ruleForm},
				rules: {
					content: [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
				},
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
					}
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			open(data) {
				if (!data) {
					this.ruleForm = {...ruleForm}
				} else {
					this.ruleForm = {...ruleForm,...data}
					console.log('this.ruleForm: ',this.ruleForm);
				}
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (this.ruleForm.confirm) {
							this.close()
							this.ruleForm.confirm({content: this.ruleForm.content})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.PopAddBase {
		.tianjiazizhanghao {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						.upload-demo {
							
							// background: #FFFFFF;
							// width: 100px;
							// height: 100px;
							// overflow: hidden;
							// border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;
							
							/deep/ {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
									
							.zhizhao-upload {
								width: 100px;
								height: 100px;
								background: #F6F8FC;
								border-radius: 4px 4px 4px 4px;
								box-sizing: border-box;
								border: 1px dashed #3571DA;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
									
								.zu-icon {
									width: 28px;
									height: 28px;
									background-image: url('../assets/043.png');
									background-size: 100% 100%;
								}
									
								.zu-label {
									margin-top: 8px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #8D9094;
									line-height: 16px;
								}
							}
						}
						
						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						
						.yanzhengma {
							position: relative;
							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
						
						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 560px;
							}
						}
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>