import config from './config.js';


// 文件/图片路径
export function imgUrl(url, showDefault = false, defaultUrl) {
	if (url) {
		if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('/static')) {
			return url
		} else {
			if (config.imgRoot[config.imgRoot.length - 1] == '/') {
				if (url[0] == '/') {
					return config.imgRoot + url.slice(1)
				} else {
					return config.imgRoot + url
				}
			} else {
				if (url[0] == '/') {
					return config.imgRoot + url
				} else {
					return config.imgRoot + '/' + url
				}
			}
		}
	} else if (showDefault) {
		if (defaultUrl) {
			if (defaultUrl.startsWith('http://') || defaultUrl.startsWith('https://') || defaultUrl.startsWith(
					'/static')) {
				return defaultUrl
			} else {
				if (config.imgRoot[config.imgRoot.length - 1] == '/') {
					if (defaultUrl[0] == '/') {
						return config.imgRoot + defaultUrl.slice(1)
					} else {
						return config.imgRoot + defaultUrl
					}
				} else {
					if (defaultUrl[0] == '/') {
						return config.imgRoot + defaultUrl
					} else {
						return config.imgRoot + '/' + defaultUrl
					}
				}
			}
		} else {
			return '/static/053.png'
		}
	}
}

// 去除数字中的逗号
export function quChuDouHao(num) {
	return num.replace(/,/g, '')
}

// 拼接url
export function getUrl(api, data = {}) {
	console.log('data: ', data);
	let keys = Object.keys(data)
	if (keys.length > 1) {
		api += '?'
		keys.forEach((key, index) => {
			api += key + '=' + data[key] + '&'
		})
		api = api.slice(0, api.length - 1)
	} else if (keys.length == 1) {
		api += '?' + keys[0] + '=' + data[keys[0]]
	}
	console.log('api: ', api);
	return api
}

// 星星屏蔽
export function starFilter(data = {}) {
	// console.log('arguments: ',arguments);
	if (typeof arguments[0] == 'object') {
		//对象模式
		var {
			str = '',
				start = 1,
				length = 0,
				unit = '*'
		} = data
	} else {
		//字符串模式
		var str = arguments[0] || ''
		var start = arguments[1] || 1
		var length = arguments[2] || 0
		var unit = arguments[3] || '*'
	}

	var arr = str.split("")
	var num = arr.splice(start - 1, length).length
	var placeholder = ''
	for (var i = 0; i < num; i++) {
		placeholder += unit
	}
	arr.splice(start - 1, 0, placeholder)
	var result = arr.join('')
	return result
}

// 文件下载
export function downloadFile(data = {}) {
	console.log('arguments: ',arguments);
	if (typeof arguments[0] == 'object') {
		//对象模式
		var {
			url = '',
		} = data
	} else {
		//字符串模式
		var url = arguments[0] || ''
	}
	
	url = imgUrl(url)
	console.log('url: ',url);

	const a = document.createElement('a');
	a.style.display = 'none';
	a.download = 'xx';
	a.href = url;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	/*
	 * download: HTML5新增的属性
	 * url: 属性的地址必须是非跨域的地址
	 */
	return url
}


/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

export function getHalfPath(fullUrl) {
	var href = config.imgRoot
	var path = fullUrl.replace(href, '').replace(/^\/+/, '');
	return path;
}


// 获取文件名称
export function getFileName(fileName) {
  return fileName.substring(0, fileName.lastIndexOf('.'))
}

// 获取文件格式
export function getFileExt(fileName) {
  return fileName.split('.').pop();
}

// 验证是否为blob格式
export async function blobValidate(data) {
	console.log('data: ',data);
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}
