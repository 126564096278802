<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false" :destroy-on-close="true">
			<div class="weixiucailiao">
				<div class="pop-header">
					<div class="ph-title">{{ serviceType == 1 ? '安装材料' : '维修材料' }}</div>
					<div class="ph-close" @click="dialogVisible= false"></div>
				</div>
				<div class="pop-body">
					<div v-if="item.productVideo" v-for="(item,index) in detail" :key="index">
						<div class="pb-text-1">{{ serviceType == 1 ? '安装视频' : '故障点视频' }}</div>
						<div class="li-album" v-if="item.productVideo">
							<Media :value="item.productVideo"></Media>
						</div>
					</div>
					<div :class="{'detail-item': index > 0 || detail.some(v => v.productVideo)}" v-for="(item,index) in detail" :key="index">
						<template v-if="item.applyAccessories == 1">
							<div class="pb-text-1">
								配件图
								<div class="pf-confirm btn-fill" @click="openPeijianfahuo(item)"
									v-if="!item.trackingNum">填写配件发货信息
								</div>
								<div class="pf-complete" @click="peijianfahuoVisible = true"
									v-else>已填写配件发货信息
								</div>
							</div>
							<div class="li-album" v-if="item.productPic" style="max-width: 700px;">
								<Media :value="item.productPic"></Media>
							</div>
						</template>
						<div class="pb-field">
							<div class="pbf-label">配件信息</div>
							<div class="pbf-value">{{ item.remarks || '无' }}</div>
						</div>
						<div class="pb-field" v-if="item.applyAccessories == 1">
							<div class="pbf-label">物流公司</div>
							<div class="pbf-value">{{ item.company || '无' }}</div>
						</div>
						<div class="pb-field" v-if="item.applyAccessories == 1">
							<div class="pbf-label">物流单号</div>
							<div class="pbf-value">
								{{ item.trackingNum || '无' }}
								<span class="blue"
									v-if="item.trackingNum"
									@click="$refs.wuliu.open(item.trackingNum,item.company)">查看物流></span>
							</div>
						</div>
					</div>
					<div class="pb-field" v-if="detail.applyAccessories == 1">
						<div class="pbf-label">收件信息</div>
						<div class="pbf-shoujianxinxi">
							<div class="pbfs-row-1">
								<div class="sr-name">{{ detail.realName }}</div>
								<div class="sr-phone">{{ detail.phone }}</div>
								<div class="sr-shengfen">客服</div>
								<div class="sr-copy btn-fill" @click="copy(orderListitem,orderListindex)"></div>
							</div>
							<div class="pbfs-row-2">
								<div class="sr-address">
									{{ detail.provinceName + detail.cityName + detail.districtName + detail.detailAddress }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="dialogVisible = false">关闭</div>
				</div>
			</div>
			
		</el-dialog>
		<video-show ref="videoshwo"></video-show>
		<el-dialog :visible.sync="peijianfahuoVisible" :lock-scroll="false" :destroy-on-close="true">
			<div class="peijianfahuo">
				<div class="pop-header">
					<div class="ph-title">填写配件发货信息</div>
					<div class="ph-close pointer" @click="peijianfahuoVisible = false"></div>
				</div>
				<el-form class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">快递品牌：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-select class="select type-3" v-model="company" filterable clearable
									placeholder="请选择物流公司" value-key="phone">
									<el-option v-for="item in wuliuCompanyList" :key="item.name" :label="item.name"
										:value="item.name">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">快递单号：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-input class="input type-1" v-model="trackingNum" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row"
						v-if="company && (company.indexOf('顺丰') != -1)">
						<div class="cbr-label">快递收件人</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-input v-model="phone" class="input type-1"
									placeholder="请输入收件人号码" clearable></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="peijianfahuoVisible = false">关闭</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
		<PopOrderWuliu ref="wuliu"></PopOrderWuliu>
	</div>
</template>


<script>
	import {
		submissionAdd,
		getSubmissionM,
		WuliuCompanyList
	} from '../common/api.js'
	import VideoShow from "@/components/videoShow";
	import PopOrderWuliu from "@/components/PopOrderWuliu";

	export default {
		name: 'WeixuiPop',
		components: {
			PopOrderWuliu,
			VideoShow
		},
		//维修安装材料
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				peijianfahuoVisible: false,
				orderId: '',
				serviceType: '', // 1=安装2=维修
				detail: {},
				//配件物流
				trackingNum: '',
				phone: '',
				company: '',
				wuliuCompanyList: [],
				isApprovalAccessories: false,
				materialSubmissionId: '',
				isFilter: false,
				
			}
		},
		created() {
			this.getWuliuCompanyList()
		},
		methods: {
			openPeijianfahuo(item) {
				this.company = ''
				this.trackingNum = ''
				this.phone = ''
				this.materialSubmissionId = item.id
				this.peijianfahuoVisible = true
			},
			open(id, serviceType, materialSubmissionId='') {
				this.orderId = id
				// this.isApprovalAccessories = isApprovalAccessories
				this.serviceType = serviceType
				if (materialSubmissionId) {
					this.isFilter = true
					this.materialSubmissionId = materialSubmissionId
				} else {
					this.isFilter = false
				}
				this.loadData()
				this.dialogVisible = true
			},
			// 查询快递公司编码列表
			getWuliuCompanyList() {
				WuliuCompanyList().then(res => {
					this.wuliuCompanyList = res.data
				})
			},
			loadData() {
				getSubmissionM(this.orderId).then(res => {
					if (this.isFilter && this.materialSubmissionId) {
						this.detail = res.data.filter(v => v.id == this.materialSubmissionId)
					} else {
						this.detail = res.data
					}
				})
			},
			close() {
				this.dialogVisible = false
			},
			submit() {
				let {
					trackingNum,
					company,
					orderId,
					phone,
					materialSubmissionId
				} = this
				submissionAdd({
					trackingNum,
					company,
					orderId,
					phone,
					materialSubmissionId
				}).then(
					res => {
						this.toast({
							message: '提交成功',
							type: 'success'
						})
						// this.close()
						this.peijianfahuoVisible = false
						this.$emit('success')
						this.loadData()
					})
			},
			copy() {
				var userName = document.querySelector(`.sr-name`)
				var phone = document.querySelector(`.sr-phone`)
				var address = document.querySelector(`.sr-address`)
				let content = userName.innerHTML + phone.innerHTML + address.innerHTML
				this.copyData(content)
			},

		}
	}
</script>

<style lang="scss" scoped>
	.weixiucailiao {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 56px;
			display: flex;
			flex-direction: column;
			max-height: 60vh;
			overflow-y: auto;
			
			.detail-item {
				border-top: 1px solid #EDEDED;
				margin-top: 30px;
			}
			

			.pb-text-1 {
				margin: 20px 0;
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.pf-confirm {
					// width: 88px;
					padding: 0 16px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
				
				.pf-complete {
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 32px;
					margin: 0 10px;
				}
			}

			.li-album {
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;

				.lia-item {
					width: 120px;
					height: 120px;
					margin-right: 20px;
					position: relative;
					border-radius: 4px;
					overflow: hidden;

					&:nth-child(4n) {
						margin-right: 0;
					}

					.liai-pic {
						width: 120px;
						height: 120px;
					}
				}
			}

			.pb-field {
				display: flex;
				margin-top: 20px;

				.pbf-label {
					margin-right: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}

				.pbf-value {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;

					.blue {
						color: #3571DA;
						margin-left: 10px;
						cursor: pointer;
					}
				}

				.pbf-shoujianxinxi {
					flex: 1;

					.pbfs-row-1 {
						display: flex;
						align-items: center;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #666666;

						.sr-name {
							margin-right: 10px;
						}

						.sr-phone {
							margin-right: 10px;
						}

						.sr-shengfen {
							width: 44px;
							height: 22px;
							background: #FFFFFF;
							border-radius: 8px 8px 8px 8px;
							opacity: 1;
							border: 1px solid #3571DA;
							box-sizing: border-box;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 20px;
							text-align: center;
						}

						.sr-copy {
							width: 20px;
							height: 20px;
							background-image: url('../assets/068.png');
							background-size: 100% 100%;
							margin-left: auto;
						}
					}

					.pbfs-row-2 {
						margin-top: 20px;

						.sr-address {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}
					}
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				// width: 88px;
				padding: 0 16px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}

	.peijianfahuo {
		min-width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 0 20px;
			display: flex;
			flex-direction: column;

			.cb-row {
				display: flex;
				align-items: center;
				margin: 20px 0 0;

				.cbr-label {
					width: 100px;
					display: flex;
					// justify-content: flex-end;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					box-sizing: border-box;
					text-align: left;
					// padding-right: 10px;

					.star {
						color: #FF0000;
					}
				}

				.cbr-content {
					flex: 1;
					display: flex;
					// align-items: center;
					
					/deep/.select {
						input {
							height: 32px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							padding-left: 8px;
						}
					
						.el-input__suffix {
							display: flex;
							align-items: center;
						}
					
						&.type-1 {
							width: 216px;
						}
					}

					/deep/ .input {
						input {
							height: 32px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							padding-left: 8px;
						}

						&.type-1 {
							width: 320px;
						}
					}
				}
			}

		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>