<template>
	<div class="zhangHaoSafety">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">账号安全</div>
					<div class="chh-line"></div>
				</div>
			</div>
			<div class="c-body">
				<div class="cb-row row-1">
					<div class="cbr-left">
						<div class="cbrl-icon success"></div>
						<div class="cbrl-label">登录密码</div>
						<div class="cbrl-content blue" v-if="isLogin">已设置</div>
					</div>
					<div class="cbr-right">
						<div class="edit-password btn-fill" @click="openPopUpdatePass">修改登录密码</div>
					</div>
				</div>
				<div class="cb-row row-2">
					<div class="cbr-left">
						<div class="cbrl-icon success"></div>
						<div class="cbrl-label">手机验证</div>
						<div class="cbrl-content" v-if="isLogin">
							已绑定手机
							<span class="highlight">{{userInfo.phone | starFilter(4,6,'*')}}</span>
						</div>
					</div>
					<div class="cbr-right">
						<div class="edit-phone btn-fill" @click="openPopUpdatePhone">更换手机号码</div>
					</div>
				</div>
				<div class="cb-row row-3">
					<div class="cbr-left">
						<div class="cbrl-icon err"></div>
						<div class="cbrl-label">注销账户</div>
						<div class="cbrl-content red">一旦注销成功，所有数据将被删除且无法恢复、无法申请售后。</div>
					</div>
					<div class="cbr-right">
						<div class="login-off btn-fill" @click="openPopLogoff">注销账号</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改密码 -->
		<PopUpdatePass :show.sync="showPopUpdatePass"></PopUpdatePass>
		<!-- 修改手机号 -->
		<PopUpdatePhone :show.sync="showPopUpdatePhone"></PopUpdatePhone>
		<!-- 注销账号 -->
		<PopLogoff :show.sync="showPopLogoff"></PopLogoff>
	</div>
</template>

<script>
	import PopUpdatePass from '../components/PopUpdatePass.vue'
	import PopUpdatePhone from '../components/PopUpdatePhone.vue'
	import PopLogoff from '../components/PopLogoff.vue'
	export default {
		components: {
			PopUpdatePass,
			PopUpdatePhone,
			PopLogoff
		},
		data() {
			return {
				showPopUpdatePass: false,
				showPopUpdatePhone: false,
				showPopLogoff: false,

			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			openPopUpdatePass() {
				this.showPopUpdatePass = true
			},
			openPopUpdatePhone() {
				this.showPopUpdatePhone = true
			},
			openPopLogoff() {
				this.showPopLogoff = true
			},

		}
	}
</script>

<style lang="scss" scoped>
	.zhangHaoSafety {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 56px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;
				.ch-headingbox {
					position: relative;
					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}
					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95,177,77,0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
			}
			.c-body {
				box-sizing: border-box;
				.cb-row {
					height: 52px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 20px;
					.cbr-left {
						display: flex;
						align-items: center;
						.cbrl-icon {
							width: 20px;
							height: 20px;
							&.success {
								background-image: url('../assets/041.png');
								background-size: 100% 100%;
							}
							&.err {
								background-image: url('../assets/042.png');
								background-size: 100% 100%;
							}
						}
						.cbrl-label {
							width: 90px;
							box-sizing: border-box;
							padding-left: 10px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						.cbrl-content {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							.highlight {
								color: #3571DA;
							}
							&.blue {
								color: #3571DA;
							}
							&.red {
								color: #FC474C;
							}
						}
					}
					.cbr-right {
						.edit-password,
						.edit-phone {
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							box-sizing: border-box;
							padding: 0 16px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 32px;
						}
						.login-off {
							// width: 88px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #3571DA;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 32px;
							padding: 0 16px;
						}
					}
				}
			}
		}
	}
</style>