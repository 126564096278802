<template>
	<div class="orderPay L">
		<div class="orderPay-content M">
			<div class="c-header">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text">首页</div>
					<div class="chl-line">/</div>
					<div class="chl-text">立即下单</div>
					<div class="chl-line">/</div>
					<div class="chl-text">确认付款</div>
				</div>
			</div>
			<template v-if="Object.keys(order).length">
				<template v-if="!success">
					<div class="process">
						<div class="progress-item success">
							<div class="pi-num">1</div>
							<div class="pi-icon-box">
								<div class="pi-icon"></div>
							</div>
							<div class="pin-text">发布需求</div>
							<div class="pin-line"></div>
						</div>
						<div class="progress-item active">
							<div class="pi-num">2</div>
							<div class="pi-icon-box">
								<div class="pi-icon"></div>
							</div>
							<div class="pin-text">等待付款</div>
							<div class="pin-line"></div>
						</div>
						<div class="progress-item">
							<div class="pi-num">3</div>
							<div class="pi-icon-box">
								<div class="pi-icon"></div>
							</div>
							<div class="pin-text">下单完成</div>
						</div>
					</div>
					<div class="dingdanxiangqing card">
						<div class="card-header">
							<div class="ch-headingbox">
								<div class="chh-text">订单详情</div>
								<div class="chh-line"></div>
							</div>
							<!-- <div class="ch-right">
								<div class="chr-number">
									共
									<span class="highlight">1</span>
									个订单
								</div>
							</div> -->
						</div>
						<div class="cb-row">
							<div class="cbr-left">订单号：{{order.orderNo}}</div>
						</div>
						<div class="cb-row" v-for="(productListitem,productListindex) in order.productList" :key="`productList-${productListindex}`">
							<div class="cbr-left">产品信息：{{`${productListitem.categoryName}(${productListitem.modelName})`}}x{{productListitem.number}}</div>
							<div class="cbr-right">
								订单合计：
								<span class="highlight">￥{{order.parole == 1 ? productListitem.price * productListitem.number:productListitem.guidePrice * productListitem.number}}</span>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-left">付款方式</div>
						</div>
						<div class="cb-row">
							<div class="pay-item type-1 pointer active">
								<div class="pi-icon"></div>
								<div class="pi-text">余额支付</div>
							</div>
							<div class="pay-item type-2 notAllowed" style="filter: grayscale(1);">
								<div class="pi-icon"></div>
								<div class="pi-text">微信支付</div>
							</div>
							<div class="pay-item type-3 notAllowed" style="filter: grayscale(1);">
								<div class="pi-icon"></div>
								<div class="pi-text">支付宝</div>
							</div>
						</div>
					</div>
					<div class="footer-card card">
						<div class="cb-row" v-for="(omsOrderExpensesitem,omsOrderExpensesindex) in order.omsOrderExpenses" :key="`omsOrderExpenses-${omsOrderExpensesindex}`">
							<div class="cbr-right">
								{{omsOrderExpensesitem.name}}： 
								<span class="value">
									<template v-if="omsOrderExpensesitem.type == 1">￥{{omsOrderExpensesitem.price}}</template>
									<template v-if="omsOrderExpensesitem.type == 2">-￥{{omsOrderExpensesitem.price}}</template>
								</span>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-right">
								合计金额： 
								<span class="highlight bold">￥{{order.totalPrice}}</span>
							</div>
						</div>
						<div class="cb-row">
							<div class="pay-btn btn-fill" @click="hasPermi('app:order:pay', {toast: true}) && submit()">立即付款</div>
						</div>
					</div>
				</template>
				<div class="pay-success card" v-if="success">
					<div class="ps-icon"></div>
					<div class="ps-title">{{order.parole == 1 ? '支付成功':'下单成功'}}</div>
					<div class="ps-description">订单已发布，等待师傅接单</div>
					<div class="ps-btns">
						<div class="ps-check btn-fill" @click="toOrderDetail">查看订单</div>
						<div class="ps-again btn-fill" v-if="hasPermi('app:order:add')" @click="toOrderCreate">再次下单</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		OrderDetail,
		OrderPay
	} from '../common/api.js'
	export default {
		props: ['orderId'],
		data() {
			return {
				success: false,
				order: { // 订单详情
					// status: '', // 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
					// parole: '', //保内外1保内2保外
				},
				
			}
		},
		created() {
			console.log('this.orderId: ',this.orderId);
			this.getOrderDetail()
		},
		mounted() {

		},
		methods: {
			submit() {
				OrderPay(this.orderId).then(res => {
					this.success = true
				})
			},
			getOrderDetail() {
				OrderDetail(this.orderId).then(res => {
					this.order = res.data
					if (this.order.parole == 2) {
						this.success = true
					}
				})
			},
			toOrderDetail() {
				this.$router.push({ name: 'orderDetail', query: { id: this.orderId } })
			},
			toOrderCreate() {
				this.$router.push({ name: 'orderCreate' })
			},

		}
	}
</script>

<style lang="scss" scoped>
	.orderPay {
		.orderPay-content {
			padding-bottom: 40px;
			.c-header {
				display: flex;
				align-items: center;
				height: 62px;
			
				.ch-left {
					display: flex;
					align-items: center;
			
					.chl-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/048.png');
						background-size: 100% 100%;
					}
			
					.chl-text {
						margin: 0 5px;
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}
			
					.chl-line {
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}
				}
			
				.ch-right {
					margin-left: auto;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					
					
				}
			}
			.process {
				display: flex;
				justify-content: center;
				padding: 20px;
				background-color: #FFFFFF;
				.progress-item {
					display: flex;
					align-items: center;
					&.active {
						.pi-num {
							background-color: #3571DA;
							border-color: #3571DA;
							color: #fff;
						}
						.pin-text {
							color: #18191A;
						}
					}
					&.success {
						.pi-num {
							display: none;
						}
						.pi-icon-box {
							display: flex;
						}
						.pin-text {
							color: #18191A;
						}
						.pin-line {
							background: #3571DA;
						}
					}
					.pi-num {
						width: 40px;
						height: 40px;
						box-sizing: border-box;
						border: 2px solid #B4B9BF;
						font-size: 20px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #8D9094;
						line-height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
					}
					.pi-icon-box {
						display: none;
						width: 40px;
						height: 40px;
						box-sizing: border-box;
						border: 2px solid #3571DA;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						.pi-icon {
							width: 20px;
							height: 20px;
							background-image: url('../assets/007.png');
							background-size: 100% 100%;
						}
					}
					.pin-text {
						margin-left: 6px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #8D9094;
						line-height: 24px;
						white-space: nowrap;
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.pin-line {
						margin: 0 10px 0 6px;
						width: 152px;
						height: 4px;
						background: #CED4DB;
					}
				}
			}
			
			.card {
				background-color: #FFFFFF;
				margin-top: 20px;
				box-sizing: border-box;
				padding: 0 20px;
				overflow: hidden;
				border-radius: 8px;
			}
			
			.card-header {
				display: flex;
				align-items: center;
				height: 56px;
				border-bottom: 1px solid #EDEDED;
			
				.ch-headingbox {
					position: relative;
			
					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}
			
					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
				
				.ch-right {
					display: flex;
					margin-left: auto;
					
					.cbr-add-btn {
						margin-left: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						background-color: #3571DA;
					
						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/059.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}
					
						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 14px;
						}
					}
			
					.chr-number {
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
			
						.highlight {
							color: #3571DA;
						}
					}
				}
			}
			
			.dingdanxiangqing {
				
			}
			
			.footer-card {
				
			}
			
			.cb-row {
				margin: 20px 0;
				display: flex;
				.cbr-left {
					flex: 1;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.cbr-right {
					margin-left: auto;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					.value {
						margin-left: 10px;
					}
					.highlight {
						font-size: 18px;
						color: #3571DA;
					}
					.bold {
						font-weight: bold;
					}
				}
				
				.pay-btn {
					width: 207px;
					height: 48px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 18px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 48px;
					text-align: center;
					margin-left: auto;
				}
				
				.pay-item {
					width: 140px;
					height: 56px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #EDEDED;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 10px;
					.pi-icon {
						width: 28px;
						height: 28px;
						background-size: 100% 100%;
						margin-right: 12px;
					}
					.pi-text {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
					&.active {
						border-color: #3571DA;
					}
					&.type-1 {
						.pi-icon {
							background-image: url('../assets/063.png');
						}
					}
					&.type-2 {
						.pi-icon {
							background-image: url('../assets/064.png');
						}
					}
					&.type-3 {
						.pi-icon {
							background-image: url('../assets/065.png');
						}
					}
				}
			}
			
			.pay-success {
				height: 390px;
				background: #FFFFFF;
				border-radius: 8px 8px 8px 8px;
				opacity: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.ps-icon {
					width: 64px;
					height: 64px;
					background-image: url('../assets/009.png');
					background-size: 100% 100%;
				}
				.ps-title {
					margin-top: 20px;
					font-size: 20px;
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					color: #333333;
				}
				.ps-description {
					margin-top: 20px;
					font-size: 14px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					color: #999999;
				}
				.ps-btns {
					margin-top: 20px;
					display: flex;
					align-items: center;
					.ps-check {
						width: 96px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}
					.ps-again {
						width: 96px;
						height: 32px;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						opacity: 1;
						border: 1px solid #3571DA;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 30px;
						text-align: center;
						box-sizing: border-box;
					}
				}
			}
		}
	}
</style>