<template>
	<div class="productAdd">
		<div class="card">
			<div class="c-header">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text btn-text" @click="openHome">首页</div>
					<div class="chl-line">/</div>
					<div class="chl-text btn-text" @click="navigateBack()">产品管理</div>
					<div class="chl-line">/</div>
					<div class="chl-text">添加产品</div>
				</div>
				<div class="ch-right btn-fill" @click="navigateBack()">返回></div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="c-body">
				<div class="cb-row">
					<div class="cbr-label"><span class="star">*</span>选择产品：</div>
					<div class="cbr-content">
						<el-form-item prop="categoryId" class="form-item">
							<el-cascader class="select select-1" v-model="erjiCategoryValue" :options="erjiCategoryList"
								clearable
								:props="{ expandTrigger: 'hover', label: 'name', value: 'id', children: 'child' }"
								@blur="$refs.ruleForm.validateField(['categoryId'])"
								@change="handleErjiCategoryChange()">
							</el-cascader>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label"><span class="star">*</span>型号名称：</div>
					<div class="cbr-content">
						<el-form-item prop="modelName" class="form-item">
							<el-input v-model="ruleForm.modelName" class="input type-2" placeholder="请输入"
								clearable></el-input>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label" style="align-self: flex-start;">产品图片：</div>
					<div class="cbr-content flex-column">
						<el-form-item prop="" class="form-item">
							<div class="tip">建议上传产品图片，便于师傅了解产品</div>
						</el-form-item>
						<el-form-item prop="" class="form-item">
							<FileUpload class="upload-demo" className="w100h100" v-model="ruleForm.productPicture"
								:limit="1" dir="productPicture">
								<div class="upload-item">
									<div class="ui-icon"
										:style="{backgroundImage: `url('${require('../assets/051.png')}')`}"></div>
									<div class="ui-text">上传产品图片</div>
								</div>
							</FileUpload>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label" style="align-self: flex-start;">操作视频：</div>
					<div class="cbr-content flex-column">
						<el-form-item prop="" class="form-item">
							<div class="tip">建议添加操作视频，师傅按照视频操作</div>
						</el-form-item>
						<el-form-item prop="" class="form-item">
							<FileUpload class="upload-demo" customView="view-1" customBtn isShowEdit
								className="w100h100" keyName="fileUrl" style="width: auto;overflow-y: hidden;"
								@edit="editPopUpload" v-model="ruleForm.productVideo" :limit="Infinity"
								:fileType="videoFileType" dir="productVideo">
								<div class="upload-item pointer" style="margin-right: 0;" @click="openPopUpload(2)">
									<div class="ui-icon"
										:style="{backgroundImage: `url('${require('../assets/052.png')}')`}"></div>
									<div class="ui-text">操作视频</div>
								</div>
							</FileUpload>
						</el-form-item>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-label" style="align-self: flex-start;">完工示例图：</div>
					<div class="cbr-content flex-column">
						<el-form-item prop="" class="form-item">
							<div class="tip">建议上传完工模板，师傅按照要求拍照</div>
						</el-form-item>
						<el-form-item prop="" class="form-item">
							<FileUpload class="upload-demo" customView="view-1" customBtn isShowEdit
								className="w100h100" keyName="fileUrl" style="width: auto;overflow-y: hidden;"
								@edit="editPopUpload" v-model="ruleForm.finishedDrawing" :limit="Infinity"
								dir="finishedDrawing">
								<div class="upload-item pointer" style="margin-right: 0;" @click="openPopUpload(1)">
									<div class="ui-icon"
										:style="{backgroundImage: `url('${require('../assets/052.png')}')`}"></div>
									<div class="ui-text">完工示例图</div>
								</div>
							</FileUpload>
						</el-form-item>
					</div>
				</div>
				<el-form-item prop="price" class="form-item cb-row-wrap">
					<div class="cb-row">
						<div class="cbr-label"><span class="star">*</span>价格：</div>
						<div class="cbr-content">
							<div class="input-wrap">
								<div class="iw-label">安装</div>
								<el-form-item prop="installation" class="form-item">
									<el-input v-model="ruleForm.installation" class="input type-3" placeholder="请输入"
										clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'installation',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
							<div class="input-wrap">
								<div class="iw-label">大修</div>
								<el-form-item prop="overhaul" class="form-item">
									<el-input v-model="ruleForm.overhaul" class="input type-3" placeholder="请输入"
										clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'overhaul',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label"></div>
						<div class="cbr-content">
							<div class="input-wrap">
								<div class="iw-label">中修</div>
								<el-form-item prop="medium" class="form-item">
									<el-input v-model="ruleForm.medium" class="input type-3" placeholder="请输入" clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'medium',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
							<div class="input-wrap">
								<div class="iw-label">小修</div>
								<el-form-item prop="minor" class="form-item">
									<el-input v-model="ruleForm.minor" class="input type-3" placeholder="请输入" clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'minor',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label"></div>
						<div class="cbr-content">
							<div class="input-wrap">
								<div class="iw-label">普通</div>
								<el-form-item prop="repair" class="form-item">
									<el-input v-model="ruleForm.repair" class="input type-3" placeholder="请输入" clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'repair',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
							<div class="input-wrap">
								<div class="iw-label">清洗</div>
								<el-form-item prop="cleanse" class="form-item">
									<el-input v-model="ruleForm.cleanse" class="input type-3" placeholder="请输入"
										clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'cleanse',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label"></div>
						<div class="cbr-content">
							<div class="input-wrap">
								<div class="iw-label">调制/检测/定位/教学</div>
								<el-form-item prop="debug" class="form-item">
									<el-input v-model="ruleForm.debug" class="input type-3" placeholder="请输入" clearable
										@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'debug',})"></el-input>
								</el-form-item>
								<div class="iw-unit">元</div>
							</div>
						</div>
					</div>
				</el-form-item>
				<div class="cb-row">
					<div class="cbr-label"></div>
					<div class="cbr-content">
						<div class="cbrc-submit btn-fill" @click="submit">提交</div>
					</div>
				</div>
			</el-form>
		</div>

		<!-- 上传 -->
		<PopUpload ref="PopUpload" :fileType="fileType" @save="PopUploadSave"></PopUpload>
	</div>
</template>

<script>
	import {
		CategoryList,
		ProductsAdd
	} from '../common/api.js'
	import PopUpload from '../components/PopUpload.vue'


	var ruleForm = {
		cleanse: '', //清洗价格
		debug: '', //调试/定价/检测价格
		finishedDrawing: [], //完工示例图
		id: '',
		installation: '', //安装价格
		medium: '', //中修价格
		minor: '', //小修价格
		modelName: '', //型号名称
		overhaul: '', //大修价格
		productPicture: '', //产品图片
		productVideo: [], //操作视频
		repair: '', //维修价格

	}
	export default {
		components: {
			PopUpload
		},
		data() {
			var categoryIdChecked = (rule, value, callback) => {
				if (this.categoryList.length > 1) {
					if (this.erjiCategoryValue[2]) {
						callback();
					} else {
						return callback(new Error('请选择产品'));
					}
				} else if (this.categoryList.length == 1) {
					if (this.erjiCategoryValue[1]) {
						callback();
					} else {
						return callback(new Error('请选择产品'));
					}
				} else {
					return callback(new Error('请选择产品'));
				}
			}
			var priceItemChecked = (rule, value, callback) => {
				if (this.ruleForm.installation ||
					this.ruleForm.overhaul ||
					this.ruleForm.medium ||
					this.ruleForm.minor ||
					this.ruleForm.repair ||
					this.ruleForm.cleanse ||
					this.ruleForm.debug 
				) {
					this.$refs.ruleForm.validateField(['price'])
					callback();
				} else {
					this.$refs.ruleForm.validateField(['price'])
					// return callback(new Error('请选择价格'));
				}
			}
			var priceChecked = (rule, value, callback) => {
				if (
					this.ruleForm.cleanse ||
					this.ruleForm.debug ||
					this.ruleForm.installation ||
					this.ruleForm.medium ||
					this.ruleForm.minor ||
					this.ruleForm.overhaul ||
					this.ruleForm.repair
				) {
					callback();
				} else {
					return callback(new Error('以上价格至少填写一项'));
				}
			}
			return {
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: '',

				categoryList: [],
				parentId: '',
				erjiCategoryList: [],
				erjiCategoryValue: [],

				ruleForm: JSON.parse(JSON.stringify(ruleForm)),
				rules: {
					categoryId: [{
						required: true,
						message: '请选择产品',
						trigger: 'blur',
						validator: categoryIdChecked
					}],
					price: [{
						required: true,
						// message: '请选择价格',
						validator: priceChecked
					}],
					cleanse: [{
						required: true,
						// message: '请输入清洗价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					debug: [{
						required: true,
						// message: '请输入调试/定价/检测价格',
						trigger: 'blur',
						validator: priceItemChecked
					}],
					installation: [{
						required: true,
						// message: '请输入安装价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					medium: [{
						required: true,
						// message: '请输入中修价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					minor: [{
						required: true,
						// message: '请输入小修价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					modelName: [{
						required: true,
						message: '请输入型号名称',
						trigger: 'blur'
					}],
					overhaul: [{
						required: true,
						// message: '请输入大修价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					repair: [{
						required: true,
						// message: '请输入维修价格',
						trigger: ['blur', 'change'],
						validator: priceItemChecked
					}],
					
				},

				keyWords: '',
				fileType: 1,



			}
		},
		created() {
			this.getCategoryList()
		},
		mounted() {

		},
		methods: {
			getCategoryList() {
				let data = {
					name: '',
					parentId: '',
					type: 1
				}
				CategoryList(data).then(res => {
					this.categoryList = res.data
					if (res.data.length > 1) {
						this.erjiCategoryList = res.data
					} else if (res.data.length == 1) {
						this.erjiCategoryList = res.data[0].child
					}
				})

			},
			handleErjiCategoryChange(value) {
				this.$refs.ruleForm.validateField(['categoryId'])
			},
			submit() {
				console.log('this.ruleForm: ',this.ruleForm);
				console.log('this.$refs.ruleForm: ',this.$refs.ruleForm);
				this.$refs.ruleForm.validate((valid) => {
					console.log('valid: ',valid);
					if (valid) {
						let data = {
							categoryId: this.erjiCategoryValue[this.erjiCategoryValue.length - 1],
							cleanse: this.ruleForm.cleanse,
							debug: this.ruleForm.debug,
							finishedDrawing: this.ruleForm.finishedDrawing,
							id: this.ruleForm.id,
							installation: this.ruleForm.installation,
							medium: this.ruleForm.medium,
							minor: this.ruleForm.minor,
							modelName: this.ruleForm.modelName,
							overhaul: this.ruleForm.overhaul,
							productPicture: this.ruleForm.productPicture,
							productVideo: this.ruleForm.productVideo,
							repair: this.ruleForm.repair,
						}
						ProductsAdd(data).then(res => {
							this.$rootApp.$refs.PopTip.open({
								content: '提交成功，是否继续添加？',
								type: 'success',
								confirmText: '添加',
								confirm: () => {
									this.ruleForm = JSON.parse(JSON.stringify(ruleForm))
									this.$nextTick(() => {
										this.$refs.ruleForm.clearValidate()
									})
								},
								cancel: () => {
									this.$router.go(-1)
								}
							})

						})
					} else {
						return false;
					}
				});
			},
			openPopUpload(fileType) {
				this.fileType = fileType
				this.$refs.PopUpload.open()
			},
			editPopUpload(e, index) {
				console.log('e: ', e);
				this.fileType = e.fileType
				let data = {
					fileName: e.fileName,
					fileType: e.fileType,
					fileUrl: e.fileUrl,
					fileIndex: index,
				}
				this.$refs.PopUpload.open(data)
			},
			PopUploadSave(e) {
				console.log('e: ', e);
				let data = {
					fileName: e.fileName,
					fileType: e.fileType,
					fileUrl: e.fileUrl
				}
				let key = ''
				if (e.fileType == 1) {
					key = 'finishedDrawing'
				} else if (e.fileType == 2) {
					key = 'productVideo'
				}
				if (e.fileIndex > -1) {
					this.ruleForm[key].splice(e.fileIndex, 1, data)
				} else {
					this.ruleForm[key].push(data)
				}
			},

		}
	}
</script>

<style lang="scss" scoped>
	.productAdd {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 62px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-left {
					display: flex;
					align-items: center;

					.chl-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/048.png');
						background-size: 100% 100%;
					}

					.chl-text {
						margin: 0 5px;
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}

					.chl-line {
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}
				}

				.ch-right {
					margin-left: auto;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
				}
			}

			.c-body {
				width: 970px;
				margin: 0 auto;

				/deep/.cb-row-wrap {
					margin-bottom: 40px;

					.el-form-item__error {
						left: 140px;
					}
				}

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0;

					&.leixing {
						align-items: flex-start;
					}

					.cbr-label {
						width: 140px;
						display: flex;
						justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						align-items: center;
						
						&.flex-column {
							flex-direction: column;
							align-items: flex-start;
						}
						
						.tip {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FF0000;
							margin-bottom: 10px;
						}

						.cbrc-submit {
							width: 88px;
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 32px;
							text-align: center;
						}

						.input-wrap {
							display: flex;
							align-items: center;

							.iw-label {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;
								margin-right: 10px;
							}

							.iw-unit {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;
								margin: 0 60px 0 10px;
							}
						}

						.leimu-list {
							display: flex;
							flex-wrap: wrap;

							.ll-item {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								width: 80px;
								height: 80px;
								background-color: #F6F8FC;
								border-radius: 8px;
								margin-right: 20px;
								margin-bottom: 20px;

								.lli-icon {
									width: 32px;
									height: 32px;
									margin-bottom: 10px;
									background-image: url('../assets/050.png');
									background-size: 100% 100%;
								}

								.lli-text {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								&.active {
									background-color: #3571DA;

									.lli-icon {
										background-image: url('../assets/049.png');
									}

									.lli-text {
										color: #FFFFFF;
									}
								}
							}
						}

						.form-item {}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
								margin-right: 10px;
							}

							&.type-2 {
								width: 216px;
							}

							&.type-3 {
								width: 216px;
							}

						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.select-1 {
								width: 216px;
							}
						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.textarea-1 {
								width: 580px;
							}
						}

						.cbr-search-btn {
							display: flex;
							align-items: center;
							width: 84px;
							height: 32px;
							background-color: #3571DA;
							border-radius: 4px;
							justify-content: center;

							.cbrs-icon {
								background-image: url('../assets/047.png');
								background-size: 100% 100%;
								width: 14px;
								height: 14px;
								margin-right: 10px;
							}

							.cbrs-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
						}

						.yikoujia {
							width: 160px;
							height: 60px;
							border-radius: 8px;
							background-color: #F6F8FC;
							display: flex;
							justify-content: center;
							align-items: center;

							.yk-icon {
								width: 32px;
								height: 32px;
								background-image: url('../assets/057.png');
								background-size: 100% 100%;
								margin-right: 10px;
							}

							.yk-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 12px;
							}
						}

						.upload-demo {
							background: transparent;
							width: 100px;
							// height: 100px;
							overflow: hidden;
							border-radius: 4px;
							// box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;

							&:hover {

								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}

							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}

						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px;
							border: 1px dashed #CED4DB;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 10px;

							.ui-icon {
								width: 24px;
								height: 24px;
								background-image: url('../assets/052.png');
								background-size: 100% 100%;
								margin-bottom: 8px;
							}

							.ui-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 12px;
							}

						}
					}
				}
			}

		}
	}
</style>