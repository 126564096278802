<template>
	<div class="PopOrderCustomerModify">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="xiugaikehuxinxi">
				<div class="pop-header">
					<div class="ph-title">
						<span>修改客户信息</span>
					</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">客户姓名：</div>
						<div class="cbr-content">
							<el-form-item prop="realName" class="form-item">
								<el-input v-model="ruleForm.realName" class="input type-1" placeholder="请输入" clearable></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">客户手机：</div>
						<div class="cbr-content">
							<el-form-item prop="phone" class="form-item">
								<el-input v-model="ruleForm.phone" class="input type-2" placeholder="请输入" clearable></el-input>
							</el-form-item>
							<template v-if="checkedPhoneExtension">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.phoneExtension" class="input type-3" placeholder="请输入..."
										clearable></el-input>
								</el-form-item>
							</template>
							<div class="mul-checkbox pointer" @click="checkedPhoneExtensionClick">
								<div class="checkbox">
									<div class="no" v-if="!checkedPhoneExtension"></div>
									<div class="yes" v-else></div>
								</div>
								<div class="label">虚拟号</div>
							</div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">客户地址：</div>
						<div class="cbr-content">
							<el-form-item prop="listProvinceCityValue" class="form-item">
								<el-cascader ref="refSubCat" class="select type-1"
									v-model="listProvinceCityValue" :options="listProvinceCity" clearable
									:props="{ expandTrigger: 'hover' }"
									@change="listProvinceCityChange($event)">
								</el-cascader>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row" style="align-items: flex-start;">
						<div class="cbr-label">详细地址：</div>
						<div class="cbr-content">
							<el-form-item prop="detailAddress" class="form-item">
								<!-- <el-input v-model="ruleForm.detailAddress" class="input type-1" placeholder="请输入" clearable></el-input> -->
								<el-input v-model="ruleForm.detailAddress" class="textarea type-1" type="textarea" :show-word-limit="true"
									:maxlength="400" :rows="2"
									placeholder="请输入" clearable></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">取消</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		OrderCustomerModify,
		ListProvinceCity
	} from '../common/api.js'
	var that = {}
	var ruleForm = {
		cityId: '', // 客户地址城市ID
		cityName: '', // 客户地址城市名称
		detailAddress: '', // 客户地址详细地址
		districtId: '', // 客户地址区ID
		districtName: '', // 客户地址区名称
		orderId: '', // 订单ID
		phone: '', // 客户地址收货人手机
		phoneExtension: '', // 客户地址收货人分手机
		provinceId: '', // 客户地址省ID
		provinceName: '', // 客户地址省名称
		realName: '', // 客户地址收货人名称
	}
	var numberChecked = (rule, value, callback) => {
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			callback();
		} else {
			return callback(new Error('请勿输入数字以外的字符'));
		}
	};
	var listProvinceCityValueChecked = (rule, value, callback) => {
		if (that.listProvinceCityValue.length) {
			callback();
		} else {
			return callback(new Error('请选择省市区'));
		}
	};
	export default {
		name: 'PopOrderCustomerModify',//修改客户信息
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {...ruleForm},
				rules: {
					realName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 11,
						max: 11,
						message: '手机号位数不正确',
						trigger: 'blur'
					}],
					detailAddress: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					listProvinceCityValue: [{
						required: true,
						message: '请选择省市区',
						validator: listProvinceCityValueChecked
					}],
				},
				listProvinceCity: [],
				listProvinceCityValue: [],
				checkedPhoneExtension: false,
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
					}
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
			this.getListProvinceCity()
		},
		methods: {
			open(data) {
				if (!data) {
					this.ruleForm = {...ruleForm}
				} else {
					this.ruleForm.cityId = data.cityId // 客户地址城市ID
					this.ruleForm.cityName = data.cityName // 客户地址城市名称
					this.ruleForm.detailAddress = data.detailAddress // 客户地址详细地址
					this.ruleForm.districtId = data.districtId // 客户地址区ID
					this.ruleForm.districtName = data.districtName // 客户地址区名称
					this.ruleForm.orderId = data.id // 订单ID
					this.ruleForm.phone = data.phone // 客户地址收货人手机
					this.ruleForm.phoneExtension = data.phoneExtension // 客户地址收货人手机
					this.ruleForm.provinceId = data.provinceId // 客户地址省ID
					this.ruleForm.provinceName = data.provinceName // 客户地址省名称
					this.ruleForm.realName = data.realName // 客户地址收货人名称
					
					this.listProvinceCityValue = [data.provinceId, data.cityId, data.districtId]
				}
				
				if (this.ruleForm.phoneExtension) {
					this.checkedPhoneExtension = true
				} else {
					this.checkedPhoneExtension = false
				}
				
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							cityId: this.ruleForm.cityId, // 客户地址城市ID
							cityName: this.ruleForm.cityName, // 客户地址城市名称
							detailAddress: this.ruleForm.detailAddress, // 客户地址详细地址
							districtId: this.ruleForm.districtId, // 客户地址区ID
							districtName: this.ruleForm.districtName, // 客户地址区名称
							orderId: this.ruleForm.orderId, // 订单ID
							phone: this.ruleForm.phone, // 客户地址收货人手机
							phoneExtension: this.ruleForm.phoneExtension, // 客户地址收货人手机
							provinceId: this.ruleForm.provinceId, // 客户地址省ID
							provinceName: this.ruleForm.provinceName, // 客户地址省名称
							realName: this.ruleForm.realName, // 客户地址收货人名称
						}
						console.log('data: ',data);
						OrderCustomerModify(data).then(res => {
							this.toast({
								message: '修改成功',
								type: 'success'
							})
							this.close()
							this.$emit('success')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 获取省市区
			getListProvinceCity() {
				ListProvinceCity().then(res => {
					this.listProvinceCity = res.data
				})
			},
			// 选择客户地址省市区
			listProvinceCityChange(value) {
				console.log('value: ', value);
				console.log('this.$refs.refSubCat: ', this.$refs.refSubCat);
				let node = this.$refs.refSubCat.getCheckedNodes(true)[0]
				console.log('node: ', node);
				if (value.length) {
					this.ruleForm.provinceId = node.path[0]
					this.ruleForm.cityId = node.path[1]
					this.ruleForm.districtId = node.path[2]
					this.ruleForm.provinceName = node.pathLabels[0]
					this.ruleForm.cityName = node.pathLabels[1]
					this.ruleForm.districtName = node.pathLabels[2]
				} else {
					this.ruleForm.provinceId = ''
					this.ruleForm.cityId = ''
					this.ruleForm.districtId = ''
					this.ruleForm.provinceName = ''
					this.ruleForm.cityName = ''
					this.ruleForm.districtName = ''
				}
			},
			checkedPhoneExtensionClick() {
				this.ruleForm.phoneExtension = ''
				this.checkedPhoneExtension = !this.checkedPhoneExtension
			},
		}
	}
</script>

<style lang="scss" scoped>
	.mul-checkbox {
		display: flex;
		align-items: center;
		margin-left: 10px;
		
		&:hover {
			.checkbox {
				.no {
					border: 1px solid #3571da;
				}
			}
		}
	
		.checkbox {
			width: 18px;
			height: 18px;
	
			.no {
				width: 100%;
				height: 100%;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #CDCDCD;
				box-sizing: border-box;
			}
	
			.yes {
				width: 100%;
				height: 100%;
				background-image: url('../assets/039.png');
				background-size: 100% 100%;
			}
		}
	
		.label {
			margin-left: 10px;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 18px;
	
			.num {
				margin: 0 5px;
			}
		}
	}
	
	.PopOrderCustomerModify {
		.xiugaikehuxinxi {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;
		
				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;
					.cbr-label {
						width: 100px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;
					
						.star {
							color: #FF0000;
						}
					}
					
					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;
						
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						
							&.type-2 {
								width: 210px;
							}
						
							&.type-3 {
								margin-left: 10px;
								width: 100px;
							}
						}
						
						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							.el-input__suffix {
								display: flex;
								align-items: center;
							}
						
							&.type-1 {
								width: 320px;
							}
						
						}
						
						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						
							&.type-1 {
								width: 320px;
							}
						}
					}
				}
				
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>