<template>
	<div class="component-upload-image" :class="className">
		<template v-if="customView">
			<div class="img-list" @mouseenter="imgShow = index" @mouseleave="imgShow = ''"
				v-for="(item, index) in fileList" :key="index">
				<slot name="images">
					<template v-if="customView == 'view-1' ">
						<div class="custom-view-1 image-box">
							<el-image :src="item.url" alt="" class="image" />
							<div class="image-tip txt-ellipsis-1">{{item[fileName]}}</div>
						</div>
					</template>
					<template v-if="customView == 'default'">
						<img :src="item.url" class="avatar" />
					</template>
				</slot>
				<label class="el-upload-list__item-status-label" v-if="imgShow !== index">
					<i class="el-icon-upload-success el-icon-check"></i>
				</label>
				<!-- <el-progress v-if="true" type="circle" :stroke-width="6" :percentage="parsePercentage(item.percentage)"> </el-progress> -->
				<transition name="el-zoom-in">
					<div class="img-tools" v-show="imgShow === index">
						<i class="el-icon-zoom-in img-view " @click="handlePictureCardPreview(item)"></i>
						<i class="el-icon-delete img-del  " @click="handleRemove(item)"></i>
						<i class="el-icon-edit img-edit" v-if="isShowEdit" @click="handleEdit(item,index)"></i>
					</div>
				</transition>
			</div>
		</template>
		<template v-if="customBtn">
			<div style="margin-bottom: 10px;">
				<slot></slot>
			</div>
		</template>
		<template v-if="!customBtn">
			<el-upload drag :data="data" name="file" :file-list="fileList" :show-file-list="!customView"
				:list-type="listType" :limit="limit" :multiple="limit>1" :action="action" :headers="headers"
				:auto-upload="true" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess"
				:on-error="handleUploadError" :on-exceed="handleExceed" :on-remove="handleRemove"
				:on-preview="handlePictureCardPreview" :on-progress="handleUploadProgress" :on-change="handleUploadChange"
				class="upload-content" :class="{hide: fileList.length >= limit, margin0: limit == 1}">
				<slot></slot>
			</el-upload>
		</template>
		<!-- 上传提示 -->
		<!-- <div class="el-upload__tip" slot="tip" v-if="showTip">
      请上传
      <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
      的文件
    </div> -->

		<!-- 预览 -->
		<el-dialog :visible.sync="dialogVisible" append-to-body :lock-scroll="false">
			<template v-if="previewFile[0].type == 'video'">
				<div class="preview-box">
					<div class="preview">
						<div class="pv-title">预览</div>
						<div class="pv-close" @click="dialogVisible = false"></div>
					</div>
					<video class="video" controls="controls" v-if="dialogVisible">
						<source :src="previewFile[0].value" />
					</video>
				</div>
			</template>
		</el-dialog>

		<image-viewer v-if="showViewer" :z-index="999999" :initial-index="previewIndex" :on-close="closeViewer"
			:url-list="previewFile" />

		<!-- <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body :lock-scroll="false">
			<template v-if="previewFile.type == 'video'">
				<video style="display: block; width: auto;height: 50vh; margin: 0 auto" controls="controls">
					<source :src="previewFile.value" />
				</video>
			</template>
			<template v-if="previewFile.type == 'picture'">
				<img :src="previewFile.value" style="display: block; max-width: 100%; margin: 0 auto;height: 50vh;" />
			</template>
		</el-dialog> -->
	</div>
</template>

<script>
	import {
		OssToken
	} from '../common/api.js'
	import config from "../common/config.js";
	import ImageViewer from 'element-ui/packages/image/src/image-viewer';
	// import {
	// 	uploadFileOSS
	// } from '../common/uploadOss.js'
	export default {
		name: 'FileUpload',
		components: {
			ImageViewer
		},
		props: {
			value: [String, Object, Array],
			// 图片数量限制
			limit: {
				type: Number,
				default: 5,
			},
			// 大小限制(MB)
			fileSize: {
				type: Number,
				default: 0,
			},
			// 文件类型, 例如['png', 'jpg', 'jpeg']
			fileType: {
				type: Array,
				default: () => ["png", "jpg", "jpeg"],
			},
			// 是否显示提示
			isShowTip: {
				type: Boolean,
				default: true
			},
			dir: {
				type: String,
				default: ''
			},
			fileName: {
				type: String,
				default: 'fileName'
			},
			listType: {
				type: String,
				default: 'picture-card'
			},
			className: {
				type: String,
				default: ''
			},
			keyName: {
				type: String,
				default: ''
			},
			customView: {
				type: String,
				default: ''
			},
			customBtn: {
				type: Boolean,
				default: false
			},
			isShowEdit: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				number: 0,
				uploadList: [],
				previewFile: [{}],
				previewIndex: 0,
				dialogVisible: false,
				showViewer: false,
				hideUpload: false,
				action: '', // 上传的图片服务器地址
				headers: {
					Authorization: "Bearer " + this.$store.state.token,
					userRole: "manufacturer"
				},
				fileList: [],
				videoType: ['mp4', 'avi', 'wmv'],
				imageType: ["png", "jpg", "jpeg"],
				data: {},
				platform: '', // backstage 后台 aliOss 阿里云
				imgShow: "",
			};
		},
		watch: {
			value: {
				handler(val) {
					if (val) {
						// 首先将值转为数组
						const list = Array.isArray(val) ? val : this.value.split(',');
						// 然后将数组转为对象数组
						this.fileList = list.map(item => {
							if (typeof item === "string") {

								//文件名操作
								// 1.//获取文件后缀
								// var suffix = "文件路径".substring("文件路径".lastIndexOf("."));
								// 2.//获取文件名
								// var fileName = "文件路径".substring("文件路径".lastIndexOf("/")+1);//或\


								item = {
									name: item.substring(item.lastIndexOf("/") + 1),
									value: this.imgUrl(item),
									type: '',
									url: ''
								};
								if (this.videoType.some(s => item.value.endsWith(s))) {
									if (this.platform == 'aliOss') {
										item.url = item.value +
											'?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
									} else {
										item.url = item.value
									}
									item.type = 'video'
								} else if (this.imageType.some(s => item.value.endsWith(s))) {
									item.url = item.value
									item.type = 'picture'
								} else {
									item.url = item.value
									item.type = 'file'
								}
							} else {
								console.log(1);
								//文件名操作
								// 1.//获取文件后缀
								// var suffix = "文件路径".substring("文件路径".lastIndexOf("."));
								// 2.//获取文件名
								// var fileName = "文件路径".substring("文件路径".lastIndexOf("/")+1);//或\

								item = {
									name: item[this.keyName].substring(item[this.keyName].lastIndexOf("/") +
										1),
									value: this.imgUrl(item[this.keyName]),
									type: '',
									url: '',
									...item
								};
								if (this.videoType.some(s => item.value.endsWith(s))) {
									if (this.platform == 'aliOss') {
										item.url = item.value +
											'?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
									} else {
										item.url = item.value
									}
									item.type = 'video'
								} else if (this.imageType.some(s => item.value.endsWith(s))) {
									item.url = item.value
									item.type = 'picture'
								} else {
									item.url = item.value
									item.type = 'file'
								}
							}
							return item;
						});
					} else {
						this.fileList = [];
						return [];
					}
				},
				deep: true,
				immediate: true
			}
		},
		computed: {
			// 是否显示提示
			showTip() {
				return this.isShowTip && (this.fileType || this.fileSize);
			},
		},
		created() {
			if (config.imgRoot.endsWith('aliyuncs.com')) {
				this.platform = 'aliOss'
				this.action = config.imgRoot
			} else {
				this.platform = 'backstage'
				this.action = config.host + "/api/common/uploadFile?type=" + this.dir
			}
		},
		methods: {
			// 上传前loading加载
			handleBeforeUpload(file) {
				return new Promise(async (resolve, reject) => {
					let isImg = false;
					if (this.fileType.length) {
						let fileExtension = "";
						if (file.name.lastIndexOf(".") > -1) {
							fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
						}
						isImg = this.fileType.some(type => {
							if (file.type.indexOf(type) > -1) return true;
							if (fileExtension && fileExtension.indexOf(type) > -1) return true;
							return false;
						});
					} else {
						isImg = file.type.indexOf("image") > -1;
					}
					if (!isImg) {
						this.$modal.msgError(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
						reject()
						return false;
					}


					if (this.fileSize) {
						const isLt = file.size / 1024 / 1024 < this.fileSize;
						if (!isLt) {
							this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
							reject()
							return false;
						}
					} else {
						if (file.type == 'picture') {
							const isLt = file.size / 1024 / 1024 < 10;
							if (!isLt) {
								this.$modal.msgError(`上传图片大小不能超过 10 MB!`);
								reject()
								return false;
							}
						} else if (file.type == 'video') {
							const isLt = file.size / 1024 / 1024 < 500;
							if (!isLt) {
								this.$modal.msgError(`上传视频大小不能超过 500 MB!`);
								reject()
								return false;
							}
						}
					}

					if (this.platform == 'aliOss') {
						await OssToken().then(
							res => {
								let nameSplit = file.name.split('.');
								let fileUrl = res.data.dir + '' + new Date().getTime() + '' + this.randomn(
									5) + 'file.' + nameSplit[nameSplit.length - 1];
								this.data = {
									policy: res.data.policy,
									OSSAccessKeyId: res.data.OSSAccessKeyId,
									signature: res.data.signature,
									success_action_status: '200',
									callback: res.data.callback,
									key: fileUrl
								}

							},
							err => {
								this.$modal.msgError(`无法获取ossToken!`);
								reject()
							}
						)
					}
					this.$modal.loading("正在上传图片，请稍候...");
					this.number++;
					resolve()
				})


			},
			// 文件上传时的钩子
			handleUploadProgress(event, file, fileList) {

			},
			// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
			handleUploadChange(file, fileList) {
				Object.defineProperty(file, 'name', {
					writable: true, //设置属性为可写
				})
				file.name = '文件上传中...'
			},
			// 上传成功回调
			handleUploadSuccess(res) {
				// console.log('res: ',res.data);
				if (res.data) {
					let obj = {}
					if (this.platform == 'aliOss') {
						obj = {
							name: res.data.fileName.substring(res.data.fileName.lastIndexOf("/") + 1),
							value: this.imgUrl(res.data.fileName),
							url: '',
						}
						if (this.videoType.some(s => obj.value.endsWith(s))) {
							obj.url = obj.value + '?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
						} else {
							obj.url = obj.value
						}
					} else if (this.platform == 'backstage') {
						obj = {
							name: res.data.fileName.substring(res.data.fileName.lastIndexOf("/") + 1),
							value: this.imgUrl(res.data.fileUrl),
							url: this.imgUrl(res.data.fileUrl)
						}
					}
					this.uploadList.push(obj);
					// console.log('this.uploadList: ',this.uploadList);
					if (this.uploadList.length === this.number) {
						this.fileList = this.fileList.concat(this.uploadList);
						// console.log('this.fileList: ', this.fileList);
						this.uploadList = [];
						this.number = 0;
						var a = ''
						if (!this.keyName) {
							a = this.listToString(this.fileList)
						} else {

							a = [...this.fileList]
						}
						this.$emit("input", a);
						this.$modal.closeLoading();
					}
				} else {
					this.$modal.msgError("上传图片失败，请重试");
					// 接口错误处理-针对阿里云
					let fileList = [...this.fileList]
					this.uploadList = [];
					this.number = 0;
					this.$modal.closeLoading();
					this.$emit("input", 0);
					this.$nextTick(() => {
						var a = ''
						if (!this.keyName) {
							a = this.listToString(fileList)
						} else {

							a = [...fileList]
						}
						this.$emit("input", a);
					})
				}

			},
			// 上传失败
			handleUploadError() {
				console.log(4);
				this.number = 0;
				this.$modal.msgError("上传图片失败，请重试");
				this.$modal.closeLoading();
			},
			// 文件个数超出
			handleExceed() {
				this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
			},
			// 预览
			handlePictureCardPreview(file) {
				if (file.type == 'file') {
					window.open(file.url)
				} else if (file.type == 'picture') {
					this.previewFile = this.fileList.map(v => v.url)
					let index = this.fileList.findIndex(v => v.url == file.url)
					if (index > -1) {
						this.previewIndex = index
					} else {
						this.previewIndex = 0
					}
					console.log('index: ', index);
					this.showViewer = true
				} else {
					this.previewFile = [file]
					this.dialogVisible = true;
				}
			},
			// 删除图片
			handleRemove(file, fileList) {
				console.log('file: ', file);
				const findex = this.fileList.map(f => f.name).indexOf(file.name);
				if (findex > -1) {
					this.fileList.splice(findex, 1);
					var a = ''
					if (!this.keyName) {
						a = this.listToString(this.fileList)
					} else {

						a = [...this.fileList]
					}
					this.$emit("input", a);
				}
			},
			handleEdit(file, index) {
				this.$emit("edit", file, index);
			},
			// 对象转成指定字符串分隔
			listToString(list, separator) {
				let strs = "";
				separator = separator || ",";
				for (let i in list) {
					strs += list[i].value.replace(config.imgRoot, "") + separator;
				}
				return strs != '' ? strs.substr(0, strs.length - 1) : '';
			},
			// 随机数
			randomn(n) {
				if (n > 21) return null
				var re = new RegExp("(\\d{" + n + "})(\\.|$)")
				var num = (Array(n - 1).join(0) + Math.pow(10, n) * Math.random()).match(re)[1]
				return num
			},
			closeViewer() {
				this.showViewer = false;
			}

		}
	};
</script>
<style scoped lang="scss">
	.component-upload-image {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -10px;
		


		.img-list {
			position: relative;
			margin-right: 10px;
			margin-bottom: 10px;
			border-radius: 6px;
			overflow: hidden;

			.image-box {
				&.custom-view-1 {
					width: 100px;
					height: 100px;
					// margin-left: 20px;
					// margin-top: 20px;
					// border-radius: 4px;
					position: relative;
					overflow: hidden;
					box-sizing: border-box;
					padding: 4px;
					position: relative;
					border: 1px dashed #3571DA;

					.image {
						width: 90px;
						height: 90px;
						border-radius: 6px;
						display: block;
					}

					.image-tip {
						width: 100%;
						height: 20px;
						background: rgba(#000000, 0.4);
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 12px;
						color: #FFFFFF;
						line-height: 20px;
						text-align: center;
						font-style: normal;
						transform: translateY(-100%);
					}

					.video-play {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.4);
						display: flex;
						justify-content: center;
						align-items: center;

						.vp-icon {
							width: 30px;
							height: 30px;
							background-image: url(../assets/083.png);
							background-size: 100% 100%;
						}
					}
				}
			}

			.avatar,
			.img-btn {
				width: 100px;
				height: 100px;
			}

			.el-upload-list__item-status-label {
				display: block;
				position: absolute;
				right: -0.078125rem;
				top: -0.03125rem;
				width: 0.208333rem;
				height: 0.125rem;
				background: #13ce66;
				text-align: center;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-box-shadow: 0 0 1pc 0.005208rem rgba(0, 0, 0, .2);
				box-shadow: 0 0 1pc 0.005208rem rgba(0, 0, 0, .2);

				i {
					font-size: 0.0625rem;
					margin-top: 0.057292rem;
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
					color: #FFF;
				}
			}

			.img-tools {
				width: 100px;
				height: 100px;
				background: rgba($color: #000000, $alpha: 0.5);
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					margin-right: 15px;
					&:last-child {
						margin-right: 0;
					}
				}
			}

			.img-del,
			.img-view,
			.img-edit{
				color: #fff;
				font-size: 20px;
				cursor: pointer;
			}
		}

		&.w100h100 {
			/deep/ {
				.el-upload-list--picture-card .el-upload-list__item {
					width: 100px;
					height: 100px;
				}

				.el-progress--circle {
					display: block;
				}

				.el-progress-circle {
					width: 100px !important;
					height: 100px !important;
					margin-left: 12px;
				}
			}
		}
	}

	.upload-video {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
	}

	::v-deep.upload-content {

		display: flex;
		flex-direction: wrap;

		// .el-upload-list--picture-card {
		// 	display: flex;
		// }

		// .el-upload-list__item {
		// 	display: block !important;
		// }

		.el-upload--picture-card {
			// display: flex;
			// flex-direction: row;
			// align-items: center;
			// flex-wrap: wrap;
			width: auto;
			height: auto;
			border: none;
		}

		.el-upload-dragger {
			width: auto;
			height: auto;
			border: none;
		}
	}

	::v-deep.margin0 {
		.el-upload-list__item {
			margin: 0 !important;
		}

		.el-upload--picture-card {
			margin: 0 !important;
		}
	}

	// .el-upload--picture-card 控制加号部分
	::v-deep.hide .el-upload--picture-card {
		display: none !important;
	}

	// 去掉动画效果
	::v-deep .el-list-enter-active,
	::v-deep .el-list-leave-active {
		transition: all 0s;
	}

	::v-deep .el-list-enter,
	.el-list-leave-active {
		opacity: 0;
		transform: translateY(0);
	}

	.preview-box {
		.preview {
			background-color: #FFFFFF;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px 10px 0 0;

			.pv-title {
				color: #333333;
				font-size: 16px;
			}

			.pv-close {
				width: 17px;
				height: 17px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
				margin-right: 10px
			}

		}

		.video {
			background-color: #000000;
			display: block;
			width: 700px;
			height: 500px;
			margin: 0 auto;
		}
	}
</style>