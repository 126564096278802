<template>
	<div class="afterSale">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">售后管理</div>
					<div class="chh-line"></div>
				</div>
			</div>
			<div class="c-body">
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">订单信息：</div>
						<el-input class="input input-1" v-model="form.keyword" placeholder="请输入订单号/客户手机/客户姓名/师傅手机/师傅姓名"
							clearable></el-input>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">款项类型：</div>
						<el-select class="select select-1" v-model="form.status" placeholder="请选择">
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">选择日期：</div>
						<el-date-picker v-model="form.time" class="date type-1" type="daterange"
							value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
							:default-time="['00:00:00', '23:59:59']">
						</el-date-picker>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="table-wrap">
					<div class="table">
						<el-table v-loading="isLoading && afterSalesList.length" :data="afterSalesList" style="width: 100%" header-row-class-name="header-row-class"
							@row-click="afterSalesListRowClick"
							header-cell-class-name="header-cell-class" cell-class-name="cell-class"
							row-class-name="row-class" >
							<div class="empty-wrap" v-loading="isLoading" v-if="!afterSalesList.length" slot="empty" >
								<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"
									></el-empty>
							</div>
							<el-table-column prop="" label="原工单号" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.newOrderNo || scope.row.oldOrderNo}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="" label="售后类型" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.type == 1">赔偿</span>
									<span v-if="scope.row.type == 2">售后</span>
								</template>
							</el-table-column>
							<el-table-column prop="createTime" label="付款时间" align="center">
							</el-table-column>
							<el-table-column prop="" label="支付金额" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.money || '--'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="" label="客户信息" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.realName}}</span>
									<span>{{scope.row.phone}}{{scope.row.phoneExtension | phoneExtensionFilter}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="field_7" label="审核状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.checkStatus == 0">审核中</span>
									<span v-if="scope.row.checkStatus == 1">已通过</span>
									<span v-if="scope.row.checkStatus == 2">已驳回</span>
									<span v-if="scope.row.checkStatus == 3">已取消</span>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="" label="操作" align="center">
								<template slot-scope="scope">
									<span>售后详情</span>
								</template>
							</el-table-column> -->
						</el-table>
					</div>
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination background :current-page.sync="pageNum" :page-size.sync="pageSize" :page-sizes="pageSizesOptions"
							layout="sizes, prev, pager, next" :total="total" @size-change="loadData"
							@current-change="loadData">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		AfterSalesList
	} from '../common/api.js'
	var form = {
		keyword: '', // 订单号/客户手机号/客户姓名
		status: '', // 状态 0=申请中1=处理中2=已完成3已关闭
		maxTime: '', //结束时间
		minTime: '', //开始时间
		time: ''
	}
	export default {
		data() {
			return {
				statusOptions: [{ //0=申请中1=处理中2=已完成3已关闭
					value: 0,
					label: '申请中'
				}, {
					value: 1,
					label: '处理中'
				}, {
					value: 2,
					label: '已完成'
				}, {
					value: 3,
					label: '已关闭'
				}],

				total: 0,
				pageNum: 1,
				pageSize: 10,
				isLoading: true,

				afterSalesList: [],
				form: {
					...form
				},

			}
		},
		created() {
			this.loadData()
		},
		mounted() {

		},
		methods: {
			reload() {
				this.pageNum = 1
				this.loadData()
			},
			loadData() {
				let data = {
					keyword: this.form.keyword, // 订单号/客户手机号/客户姓名
					status: this.form.status, // 状态 0=申请中1=处理中2=已完成3已关闭
					maxTime: '', //结束时间
					minTime: '', //开始时间
					pageSize: this.pageSize,
					pageNum: this.pageNum
				}

				data.minTime = this.form.time[0] || ''
				data.maxTime = this.form.time[1] || ''

				this.isLoading = true
				AfterSalesList(data).then(res => {
					this.afterSalesList = res.rows
					this.total = res.total
					this.$bus.$emit('backTop')
				}).finally(() => {
						this.isLoading = false
					})

			},
			// 筛选
			filter() {
				this.reload()
			},
			// 重置
			reset() {
				this.form = {
					...form
				}
				this.reload()
			},
			afterSalesListRowClick(row, column, event) {
				console.log('row: ',row);
				this.$router.push({
					name: 'afterSaleDetail',
					query: {
						id: row.id
					}
				})
			},

		}
	}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.afterSale {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 56px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-headingbox {
					position: relative;

					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
			}

			.c-body {
				padding-top: 20px;

				.cb-row {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					margin-bottom: 20px;

					.cbr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;

						.cbri-label {
							width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						/deep/.input {
							width: 440px;

							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.select-1 {
								width: 280px;
							}
						}

						/deep/.date {
							height: 32px;
							padding-left: 8px;
							padding-right: 8px;

							input {
								// height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								text-align: left;
							}

							.el-input__icon {
								height: initial;
							}

							.el-icon-date {
								order: 1;
							}

							&.type-1 {
								width: 280px;
							}
						}
					}

					.cbr-shaixuan-btn {
						width: 60px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}

					.cbr-reset-btn {
						width: 60px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.cbr-add-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;

						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/045.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}

						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 14px;
						}
					}
				}

				.table-wrap {
					margin-top: 20px;

					/deep/.table {
						width: 970px;
						border: 1px solid #DFDFDF;
						border-radius: 8px 8px 0 0;
						margin: 0 auto;

						colgroup {
							col {
								&:nth-child(3) {
									width: 150px;
								}
							}
						}

						.header-row-class {}

						.row-class {
							&:last-child {
								.cell-class {
									border: none;
								}
							}
						}

						.header-cell-class {
							background-color: #F7F7F7;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}

						.cell-class {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;

							.cell {
								padding: 0;
							}

							&:nth-child(3) {
								.cell {
									white-space: nowrap;
								}
							}

							&:nth-child(4) {
								.cell {
									color: #3571DA;
								}
							}

							&:nth-child(5) {
								.cell {
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
								}
							}
						}

						.eye-btn {}

						.download-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/046.png');
							background-size: 20px 20px;
						}

						.edit-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/070.png');
							background-size: 20px 20px;
						}

						.del-btn {
							display: inline-block;
							width: 20px;
							height: 20px;
							background-image: url('../assets/071.png');
							background-size: 20px 20px;
						}

					}
				}

				.pagination-wrap {
					padding: 20px 0;

					/deep/.pagination {
						display: flex;
						justify-content: center;
					}
				}

			}
		}
	}
</style>