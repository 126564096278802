<template>
	<div class="PopCustomerService">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="lianxikefu">
				<div class="pop-header">
					<div class="ph-title">联系客服</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<div class="pop-body">
					<div class="pb-text-1">{{popData.name}}</div>
					<div class="pb-text-2">联系电话</div>
					<div class="pb-text-3">{{popData.phone}}</div>
					<div class="pb-text-4">微信二维码</div>
					<img :src="imgUrl(popData.wechatPic)" alt="" class="pb-erqode" />
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">关闭</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	var that = {}
	export default {
		name: 'PopCustomerService',//联系客服
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				popData: {},
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			open(data) {
				if (!data) {
					this.popData = {}
				} else {
					this.popData = {...data}
				}
				console.log('this.popData: ',this.popData);
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			
			
		}
	}
</script>

<style lang="scss" scoped>
	.PopCustomerService {
		.lianxikefu {
			min-width: 600px;
			min-height: 504px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
			display: flex;
			flex-direction: column;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				flex: 1;
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
		
				.pb-text-1 {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
				.pb-text-2 {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					line-height: 12px;
				}
				.pb-text-3 {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 16px;
				}
				.pb-text-4 {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					line-height: 12px;
				}
				.pb-erqode {
					margin-top: 20px;
					width: 140px;
					height: 140px;
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>