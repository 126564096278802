import Vue from 'vue'
import VueRouter from 'vue-router'
// 登录
import Login from '../views/login.vue'
// 首页
import Index from '../views/index.vue'
// 管理
import Manage from '../views/manage.vue'
// 账号管理
import ZhangHao from '../views/zhangHao.vue'
// 账号安全
import ZhangHaoSafety from '../views/zhangHaoSafety.vue'
// 账号基本信息
import ZhangHaoInfo from '../views/zhangHaoInfo.vue'
// 账户充值
import AccountRecharge from '../views/accountRecharge.vue'
// 账户
import Account from '../views/account.vue'
// 知识库
import ZhiShiKu from '../views/zhiShiKu.vue'
// 售后管理
import AfterSale from '../views/afterSale.vue'
// 完工图库
import WanGongAlbum from '../views/wanGongAlbum.vue'
// 产品管理
import Product from '../views/product.vue'
// 添加产品
import ProductAdd from '../views/productAdd.vue'
// 我的订单
import Order from '../views/order.vue'
// 订单详情
import OrderDetail from '../views/orderDetail.vue'
// 售后详情
import AfterSaleDetail from '../views/afterSaleDetail.vue'
// 订单详情
import OrderCreate from '../views/orderCreate.vue'
// 支付下单
import OrderPay from '../views/orderPay.vue'
// 弹窗
import Pop from '../views/pop.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	component: Index
}, {
	path: '/orderCreate',
	name: 'orderCreate',
	component: OrderCreate,
	props: route => ({type: route.query.type}),
}, {
	path: '/pop',
	name: 'pop',
	component: Pop
}, {
	path: '/orderPay',
	name: 'orderPay',
	component: OrderPay,
	props: route => ({orderId: route.query.id}),
}, {
	path: '/login',
	name: 'login',
	component: Login,
	props: route => ({typeNum: route.query.type}),
}, {
	path: '/manage',
	name: 'manage',
	component: Manage,
	children: [{
		path: 'order',
		name: 'order',
		component: Order,
	}, {
		path: 'account',
		name: 'account',
		component: Account,
	}, {
		path: 'zhangHaoInfo',
		name: 'zhangHaoInfo',
		component: ZhangHaoInfo,
	}, {
		path: 'product',
		name: 'product',
		component: Product,
	}, {
		path: 'productAdd',
		name: 'productAdd',
		component: ProductAdd,
	}, {
		path: 'wanGongAlbum',
		name: 'wanGongAlbum',
		component: WanGongAlbum,
	}, {
		path: 'afterSale',
		name: 'afterSale',
		component: AfterSale,
	}, {
		path: 'zhiShiKu',
		name: 'zhiShiKu',
		component: ZhiShiKu,
	}, {
		path: 'accountRecharge',
		name: 'accountRecharge',
		component: AccountRecharge,
	}, {
		path: 'zhangHaoSafety',
		name: 'zhangHaoSafety',
		component: ZhangHaoSafety,
	}, {
		path: 'zhangHao',
		name: 'zhangHao',
		component: ZhangHao,
	}, {
		path: 'orderDetail',
		name: 'orderDetail',
		component: OrderDetail,
		props: route => ({orderId: route.query.id}),
	}, {
		path: 'orderDetail',
		name: 'afterSaleOrderDetail',
		component: OrderDetail,
		props: route => ({orderId: route.query.id}),
	}, {
		path: 'afterSaleDetail',
		name: 'afterSaleDetail',
		component: AfterSaleDetail,
		props: route => ({id: route.query.id}),
	}]
}]

// const router = new VueRouter({
// 	mode: 'history',
// 	base: process.env.BASE_URL,
// 	routes
// })

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop) {
	  if (saveTop) {
	    return new Promise((resolve, reject) => {
	      setTimeout(() => {
	        resolve(saveTop)
	      }, 0)
	    })
	    // return saveTop
	  } else {
		  return {
		    x: 0,
		    y: 0
		  }
	  }
  },
})

export default router