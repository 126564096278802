import Vue from 'vue'
import axios from 'axios'
import config from './config.js'
import {
	Notification,
	MessageBox,
	Message,
	Loading
} from 'element-ui'
import store from '../store/index.js'
import QS from 'qs'
import md5 from 'js-md5'
import router from '../router/index.js'
import {
	tansParams,
	blobValidate
} from "./h5.js";
import JSZip from 'jszip'
import {
	saveAs
} from 'file-saver'


// create an axios instance
const service = axios.create({
	baseURL: `${config.host}`, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 50000 // request timeout，
})

const ossService = axios.create({
	baseURL: `/oss-api`, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 50000 // request timeout，
})

// request interceptor
service.interceptors.request.use(
	requestConfig => {
		// console.log('requestConfig: ',requestConfig);
		let TIMESTAMP = new Date().getTime();
		// 生成签名
		let siData = ''
		if (requestConfig.method == 'get') {
			let arr = []
			for (let key in requestConfig.params) {
				arr.push(`${key}=${requestConfig.params[key]}`)
			}
			// 创建一个基于中国区域设置的排序器
			var collator = new Intl.Collator('zh-CN');
			// 使用排序器对数组进行排序
			arr.sort(collator.compare);

			// function compareNoCase(a, b) {
			//   a = a || '';
			//   b = b || '';
			//   a = a.toLowerCase();
			//   b = b.toLowerCase();
			//   if (a < b) {
			//     return -1;
			//   }
			//   if (a > b) {
			//     return 1;
			//   }
			//   return 0;
			// }
			// arr.sort(compareNoCase);
			siData = arr.join('&') + TIMESTAMP + config.md5.manufacturer
		} else if (requestConfig.method == 'post') {
			siData = JSON.stringify(requestConfig.data) + TIMESTAMP + config.md5.manufacturer
		}
		CalcuMD5(siData).then(sign => {
			requestConfig.headers['Yw-Sign'] = sign
			requestConfig.headers['SIGN'] = sign
			requestConfig.headers['userRole'] = 'manufacturer'
			requestConfig.headers['Yw-Time'] = (Date.parse(new Date())) / 1000
			requestConfig.headers['TIMESTAMP'] = TIMESTAMP
			requestConfig.headers['token'] = localStorage.getItem('token') || ''
		})
		return requestConfig
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		// 统一定义状态码为200时，表示请求成功，其他请求失败
		// console.log(response,"13")
		// console.log('response: ', response);
		if (!response.data.code) {
			return Promise.resolve(response.data)
		}

		switch (response.data.code) {
			case 200:
				return Promise.resolve(response.data)
			case 400:
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '接口错误，请联系管理员'}`,
				})
				return Promise.reject(response.data)
			case 401:
				store.dispatch('updateToken')
				Vue.prototype.$bus.$emit('login')
				return Promise.reject(response.data)
			case 403:
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '没有权限'}`,
				})
				return Promise.reject(response.data)
			case 404:
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '接口不存在'}`,
				})
				return Promise.reject(response.data)
			case 500:
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '服务器内部错误'}`,
				})
				return Promise.reject(response.data)
			case 1401:
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '签名错误'}`,
				})
				return Promise.reject(response.data)
			default:
				//失败
				Notification.error({
					title: response.data.code,
					message: `${response.data.msg || '发生错误了，请联系管理员'}`,
				})
				return Promise.reject(response.data);
		}
	},
	error => {
		const res = error.response.data
		const status = error.response.status
		switch (status) {
			case 500:
				if (res.message) {
					Notification.error({
						title: res.code,
						message: res.message
					})
				}
				return Promise.reject(new Error(res.message || 'Error'))
			case 401:
				// 401 重新登录
				return Promise.reject(error)
			case 404:
				Notification.error({
					title: status,
					message: '系统更新中，请稍后再试'
				})
				return Promise.reject(error)
			case 422:
				if (res.message) {
					Notification.error({
						title: res.code,
						message: res.message
					})
				}
				return Promise.reject(new Error(res.message || 'Error'))
			default:
				return Promise.reject(error)
		}
	}
)

/**
 * 作者：TKE
 * 时间：2019/12/17 0017
 * 功能：对外接口
 * 描述：
 */

let ignoreUrl = []

export default function request(options) {
	// 数据处理
	const params = options.data || {}
	const method = options.method.toUpperCase()
	const url = options.url
	const responseType = options.responseType || 'json'
	const ContentType = options.ContentType || 'application/json;charset=UTF-8'
	const isIgnore = options.isIgnore == -1 ? false:true
	//  'application/x-www-form-urlencoded;application/vnd.ms-excel;charset=UTF-8'

	if (isIgnore) {
		if (method == 'get') {
			if (Object.keys(params).length) {
				for (let key in params) {
					if (url.indexOf('?') == -1) {
						url = `${url}?${key}=${params[key]}`
					} else {
						url = `${url}&${key}=${params[key]}`
					}
				}
				params = {}
			}
		}
		let index = ignoreUrl.indexOf(url)
		if (index > -1) {
			return new Promise((resolve, reject) => {
				console.log(`重复请求已被拦截:${url}`);
				reject()
			})
		} else {
			// console.log('添加url: ', url);
			ignoreUrl.push(url)
		}
	}
	return new Promise((resolve, reject) => {
		begincommin({
			url,
			method,
			params,
			responseType,
			ContentType
		}).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			if (isIgnore) {
				// console.log('移除url: ', url);
				setTimeout(() => {
					let index = ignoreUrl.indexOf(url)
					if (index > -1) {
						ignoreUrl.splice(index, 1)
					}
				}, 200)
			}
		});
	})

}

const begincommin = ({
	url,
	method,
	params,
	responseType,
	ContentType
}) => {
	return new Promise((resolve, reject) => {
		if (method === 'GET') {
			service({
				url,
				method,
				params,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'POST') {
			axios.defaults.headers.post['Content-Type'] = ContentType
			const data = params //QS.stringify(params)
			service({
				url,
				method,
				data,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'PUT' || method === 'PATCH') {
			axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

			const data = QS.stringify(params)
			service({
				url,
				method,
				data,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'DELETE') {
			service({
				url,
				method,
				params,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		}
	})
}

/**
 * 作者： TKE
 * 时间： 2019/12/17 0017
 * 功能：md5加密请求体
 * 描述：
 */
function CalcuMD5(obj) {
	return new Promise(resolve => {
		// 如果不是对象  也就是说使用的GET和DELETE请求 那么不用加密  直接返回空
		// const key = process.env.VUE_APP_AESKEY
		// let sign = ''
		// if (Object.prototype.toString.call(obj) !== '[object Object]') {
		// 	sign = key + '--' + key
		// } else {
		// 	if (JSON.stringify(obj) === '{}') {
		// 		sign = key + '--' + key
		// 	} else {
		// 		Object.keys(obj).sort().forEach((k) => {
		// 			sign += key + k + '--' + obj[k] + key
		// 		})
		// 	}
		// }
		// console.warn('签名==>'+sign);
		resolve(md5(obj).toUpperCase())
	})
}

// 通用下载方法
export function download(url, params, filename) {
	let downloadLoadingInstance = Loading.service({
		text: "正在下载数据，请稍候",
		spinner: "el-icon-loading",
		background: "rgba(0, 0, 0, 0.7)",
	})
	return service.post(url, params, {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		responseType: 'blob'
	}).then(async (data) => {
		let flag = await blobValidate(data)
		if (flag) {
			const blob = new Blob([data])
			saveAs(blob, filename)
			downloadLoadingInstance.close();
		} else {
			const text = await data.text();
			let res = JSON.parse(text);
			Notification.error({
				title: `${res.code}`,
				message: `${res.msg}`,
			})
			downloadLoadingInstance.close();
		}
	}).catch((r) => {
		console.error(r)
		// Message.error('下载文件出现错误，请联系管理员！')
		downloadLoadingInstance.close();
	})
}