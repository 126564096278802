import request from './request.js'

//订单提示
export function OrderShowMessage(phone) {
	return request({
		method: 'get',
		url: `/api/app/order/showMessage?phone=${phone}`
	})
}

//订单物流
export function PeiJianWuLiuInformation(id) {
	return request({
		method: 'get',
		url: `/api/app/detail/information/${id}`
	})
}

//订单物流
export function OrderLogistics(orderId) {
	return request({
		method: 'get',
		url: `/api/app/order/logistics?orderId=${orderId}`
	})
}

//订单复制
export function OrderClickCopy(orderId) {
	return request({
		method: 'get',
		url: `/api/app/order/clickCopy/${orderId}`
	})
}

//完工确认
export function OrderConfirmation(id) {
	return request({
		method: 'post',
		url: `/api/app/order/confirmation/${id}`
	})
}

//根据协议类型查询协议信息-公共
export function ConfigAgreement(key) {
	return request({
		method: 'get',
		url: `/api/app/config/agreement/${key}`
	})
}

//取消售后申请
export function AfterSalesCancel(id) {
    return request({
        method: 'post',
        url: `/api/app/afterSales/cancel/${id}`
    })
}

//获取售后详细信息
export function AfterSalesDetail(id) {
    return request({
        method: 'get',
        url: `/api/app/afterSales/${id}`
    })
}

//导出工单列表
export function OrderExport(data) {
    return request({
        method: 'post',
        url: `/api/app/order/export`,
		data
    })
}

//联系人信息
export function ContactInfo() {
    return request({
        method: 'get',
        url: `/api/app/order/getContactInfo`
    })
}

//地址解析-快递物流姓名、电话、地址自动识别
export function MaintainAreaResolution(content) {
    return request({
        method: 'post',
        url: `/api/common/maintain/area/resolution/${content}`
    })
}

//完工图片
export function WangongList(data) {
    return request({
        method: 'get',
        url: `/api/app/application/list`,
		data
    })
}

//查询售后列表
export function AfterSalesList(data) {
    return request({
        method: 'get',
        url: `/api/app/afterSales/list`,
		data
    })
}

//原因列表,type 原因类型 1=赔偿 2=售后
export function AfterSalesReason(type) {
    return request({
        method: 'post',
        url: `/api/app/afterSales/selectCmsReasonList/${type}`
    })
}

//申请售后
export function AfterSalesApply(data) {
    return request({
        method: 'post',
        url: `/api/app/afterSales/application`,
		data
    })
}

//获取远程费申请详细信息
export function FeesDetail(id) {
    return request({
        method: 'get',
        url: `/api/app/fees/${id}`
    })
}

//修改子账号菜单权限
export function UpDataSubAccountsMenu(userId, data) {
    return request({
        method: 'post',
        url: `/api/app/user/upDataSubAccountsMenu/${userId}`,
        data
    })
}

//忘记密码
export function UpdateForget(data) {
    return request({
        method: 'post',
        url: `/api/update/forget`,
        data
    })
}

//手机号+验证码登录
export function LoginMobile(data) {
    return request({
        method: 'post',
        url: `/api/login/mobile`,
        data
    })
}

//删除产品
export function ProductsDelete(ids) {
    return request({
        method: 'post',
        url: `/api/app/products/delete/${ids}`
    })
}

//获取ossToken
export function OssToken() {
    return request({
        method: 'post',
        url: `/api/common/getToken`
    })
}

//查询地址经纬度
export function LonlatByAddress(data) {
    return request({
        method: 'get',
        url: `/api/common/maintain/area/getLonlatByAddress`,
        data
    })
}

//查询工单附加费信息
export function QuerySurcharge(id) {
    return request({
        method: 'get',
        url: `/api/app/surcharge/${id}`
    })
}

//查询快递公司编码列表
export function WuliuCompanyList(data) {
    return request({
        method: 'get',
        url: `/api/app/company/list`,
        data
    })
}

//删除知识库
export function RemoveBase(id) {
    return request({
        method: 'post',
        url: `/api/app/base/remove/${id}`
    })
}

//修改知识库
export function EditBase(data) {
    return request({
        method: 'post',
        url: `/api/app/base/edit`,
        data
    })
}

//新增知识库
export function AddBase(data) {
    return request({
        method: 'post',
        url: `/api/app/base`,
        data
    })
}

//查询知识库列表
export function BaseList(data) {
    return request({
        method: 'get',
        url: `/api/app/base/list`,
        data
    })
}

//账号充值
export function RecordssAction(data) {
    return request({
        method: 'post',
        url: `/api/app/recordss`,
        data
    })
}

//查询钱包记录列表
export function RecordsList(data) {
    return request({
        method: 'get',
        url: `/api/app/records/list`,
        data
    })
}

//查询我的账户详细信息
export function WalletGetInfo(data) {
    return request({
        method: 'get',
        url: `/api/app/wallet/getInfo`,
        data
    })
}

//催单
export function OrderReminder(data) {
    return request({
        method: 'post',
        url: `/api/app/order/reminder`,
        data
    })
}

//工单加急
export function OrderUrgent(data) {
    return request({
        method: 'post',
        url: `/api/app/order/orderUrgent`,
        data
    })
}

//支付订单
export function OrderPay(id) {
    return request({
        method: 'post',
        url: `/api/app/order/pay/${id}`
    })
}

//取消订单
export function OrderCancel(data) {
    return request({
        method: 'post',
        url: `/api/app/order/cancel`,
		data
    })
}

//子账号菜单全选/取消全选
export function UserSetSubAccountMenuAll(userId, type) {
    return request({
        method: 'post',
        url: `/api/app/user/setSubAccountMenuAll/${userId}/${type}`
    })
}

//修改客户信息
export function OrderCustomerModify(data) {
    return request({
        method: 'post',
        url: `/api/app/order/customer/modify`,
        data
    })
}

//删除子账号菜单权限
export function UserDelectSubAccountMenu(menuId, userId) {
    return request({
        method: 'post',
        url: `/api/app/user/delectSubAccountMenu/${menuId}/${userId}`
    })
}

//添加子账号菜单权限
export function UserAddSubAccountsMenu(userId, data) {
    return request({
        method: 'post',
        url: `/api/app/user/addSubAccountsMenu/${userId}`,
        data
    })
}

//删除子账号
export function UserDeleteSubAccounts(id) {
    return request({
        method: 'post',
        url: `/api/app/user/deleteSubAccounts/${id}`
    })
}

//修改子账号
export function UserUpSubAccounts(data) {
    return request({
        method: 'post',
        url: `/api/app/user/upSubAccounts`,
        data
    })
}

//获取子账号菜单
export function UserGetSubAccountMenu(userId) {
    return request({
        method: 'post',
        url: `/api/app/user/getSubAccountMenu/${userId}`
    })
}

//获取子账号
export function UserGetSubAccounts(data) {
    return request({
        method: 'post',
        url: `/api/app/user/getSubAccounts`,
        data
    })
}

//添加子账号
export function UserAddSubAccounts(data) {
    return request({
        method: 'post',
        url: `/api/app/user/addSubAccounts`,
        data
    })
}

//账号注销
export function UserLogoff(data) {
    return request({
        method: 'post',
        url: `/api/logoff`,
        data
    })
}

//发送旧账号手机号验证码
export function SendPhoneSmsCode(data) {
    return request({
        method: 'post',
        url: `/api/sendPhoneSmsCode`,
        data
    })
}

//更换手机号校验验证码
export function UpdateChangePhoneCaptcha(data) {
    return request({
        method: 'post',
        url: `/api/update/changePhoneCaptcha`,
        data
    })
}

//更换手机号
export function UpdateChangePhone(data) {
    return request({
        method: 'post',
        url: `/api/update/changePhone`,
        data
    })
}

//获取用户详细信息
export function UserGetUserInfo(data) {
    return request({
        method: 'get',
        url: `/api/app/user/getUserInfo`,
        data
    })
}

//修改密码
export function UpdatePass(data) {
    return request({
        method: 'post',
        url: `/api/update/pass`,
        data
    })
}

//获取工单详细信息
export function OrderDetail(id) {
    return request({
        method: 'get',
        url: `/api/app/order/${id}`
    })
}

/**
 * 查询材料提交明细*
 * @param id
 * @returns {*}
 */
export function getSubmissionM(id) {
    return request({
        method: 'get',
        url: `/api/app/submission/info/${id}`
    })
}

/**
 * 填写配件信息物流单号*
 * @param data
 * @returns {*}
 */
export function submissionAdd(data) {
    return request({
        method: 'post',
        url: `/api/app/submission/add`,
        data
    })
}

//查询已接单总数
export function OrderReceiveOrderAllCount(data) {
    return request({
        method: 'get',
        url: `/api/app/order/receiveOrderAllCount`,
        data
    })
}

//查询待处理工单
export function OrderDealtWithOrder(data) {
    return request({
        method: 'get',
        url: `/api/app/order/dealtWithOrder`,
        data
    })
}

//查询工单列表
export function OrderList(data) {
    return request({
        method: 'get',
        url: `/api/app/order/list`,
        data,
		isIgnore: -1
    })
}

//创建工单
export function OrderAdd(data) {
    return request({
        method: 'post',
        url: `/api/app/order/add`,
        data
    })
}

//查询增值服务配置列表
export function ServiceList(data) {
    return request({
        method: 'get',
        url: `/api/app/service/list`,
        data
    })
}

//获取厂商账号信息
export function UserGetManufacturerInfo(data) {
    return request({
        method: 'get',
        url: `/api/app/user/getManufacturerInfo`,
        data
    })
}

//修改厂商账号信息
export function UserEditManufacturerInfo(data) {
    return request({
        method: 'post',
        url: `/api/app/user/editManufacturerInfo`,
        data
    })
}

//获取省市区
export function ListProvinceCity(data) {
    return request({
        method: 'post',
        url: `/api/common/listProvinceCity`,
        data
    })
}

//获取上次订单的产品列表
export function ProductsLastOrderProduct() {
    return request({
        method: 'get',
        url: `/api/app/products/lastOrderProduct`
    })
}

//快速下单产品
export function OrderQuicklyOrder(data) {
    return request({
        method: 'get',
        url: `/api/app/order/quicklyOrder`,
        data
    })
}

//查询产品列表
export function ProductsList(data) {
    return request({
        method: 'get',
        url: `/api/app/products/list`,
        data: {
			...data,
			pageSize: 9999
		}
    })
}

//新增产品
export function ProductsAdd(data) {
    return request({
        method: 'post',
        url: `/api/app/products`,
        data
    })
}

//查询类目列表
export function CategoryList(data) {
    return request({
        method: 'get',
        url: `/api/app/category/list`,
        data
    })
}

//公告查询
export function NoticeList() {
    return request({
        method: 'get',
        url: `/api/app/notice/list`
    })
}

//根据组获取配置
export function ConfigGroup(groupKey = '') {
    return request({
        method: 'get',
        url: `/api/app/config/group/${groupKey}`
    })
}

//获取系统配置详细信息
export function Config(key) {
    return request({
        method: 'get',
        url: `/api/app/config/${key}`
    })
}

//快速下单产品
export function QuicklyOrder(data) {
    return request({
        method: 'get',
        url: `/api/app/order/quicklyOrder`,
        data
    })
}

//账号密码登录
export function Login(data) {
    return request({
        method: 'post',
        url: `/api/login`,
        data
    })
}

//退出
export function Quit(data) {
    return request({
        method: 'post',
        url: `/api/quit`,
        data
    })
}

//订单统计
export function OrderStatistics(data) {
    return request({
        method: 'get',
        url: `/api/app/order/orderStatistics`,
        data
    })
}

//注册接口
export function Register(data) {
    return request({
        method: 'post',
        url: `/api/register`,
        data
    })
}

//发送短信验证码
export function SendCode(data) {
    return request({
        method: 'post',
        url: `/api/sendCode`,
        data
    })
}


/**
 * 完工信息*
 * @param applicationId
 * @returns {*}
 */
export function getApplication(applicationId) {
    return request({
        method: 'get',
        url: `/api/app/application/getLast/${applicationId}`,
    })
}

/**
 * 根据返件ID获取返件信息详细信息*
 * @param id
 * @returns {*}
 */
export function information(id) {
    return request({
        method: 'get',
        url: `/api/app/information/getInfo/${id}`,
    })
}

/**
 * *获取返件信息详细信息
 * @param orderId
 * @returns {*}
 */
export function informationByOrder(orderId) {
    return request({
        method: 'get',
        url: `/api/app/information/${orderId}`,
    })
}

/**
 * 返件信息审核
 * @param data
 * @returns {*}
 */
export function informationAudit(orderId,reason,audit) {
    return request({
        method: 'post',
        url: `/api/app/information/audit?orderId=${orderId}&audit=${audit}&reason=${reason}`,
    })
}

/**
 * 促好评审核*
 * @param data
 * @returns {*}
 */
export function praiseAudit(data) {
    return request({
        method: 'get',
        url: `/api/app/praise/audit`,
        data
    })
}

/**
 * 获取促好评申请详细信息*
 * @param orderId
 * @returns {*}
 */
export function praiseInfo(orderId) {
    return request({
        method: 'get',
        url: `/api/app/praise/getInfo/${orderId}`,
    })
}

/**
 * 根据 ID获取促好评申请详细信息*
 * @param id
 * @returns {*}
 */
export function praiseInfoById(id) {
    return request({
        method: 'get',
        url: `/api/app/praise/info/${id}`,
    })
}


/**
 * *获取物流信息详细信息
 * @param logisticsNum
 * @param logisticsCompany
 * @returns {*}
 */
export function logisticsDetail(logisticsNum, logisticsCompany) {
    return request({
        method: 'get',
        url: `/api/common/information/getInfo/${logisticsNum}/${logisticsCompany}`,
    })
}




