<template>
	<div class="account">
		<div class="card">
			<div class="z-header">
				<div class="zh-tab">
					<div class="zht-item active">
						<div class="zhti-text">账户明细</div>
						<div class="zhti-line"></div>
					</div>
				</div>
			</div>
			<div class="c-body">
				<div class="zhanghu-yue">
					<div class="zy-label">账户余额</div>
					<div class="zy-num">{{walletGetInfo.balance}}</div>
					<div class="zy-btn btn-fill" @click="toAccountRecharge">充值</div>
				</div>
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">订单号：</div>
						<el-input v-model="ruleForm.orderNo" class="input input-1" placeholder="请输入" clearable></el-input>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">收支类型：</div>
						<el-select class="select type-1" v-model="ruleForm.changeType" placeholder="请选择" clearable>
							<el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">付款时间：</div>
						<el-date-picker
						      v-model="ruleForm.payTime"
							  class="date type-1"
							  value-format="yyyy-MM-dd"
						      type="daterange"
						      start-placeholder="开始日期"
						      end-placeholder="结束日期"
						      :default-time="['00:00:00', '23:59:59']">
						    </el-date-picker>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">款项类型：</div>
						<el-select class="select type-1" v-model="ruleForm.paymentType" placeholder="请选择" clearable>
							<el-option v-for="item in paymentTypeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="cb-row">
					<div class="cbr-add-btn btn-fill" @click="handleExport()">
						<div class="cbra-icon"></div>
						<div class="cbra-text">导出</div>
					</div>
				</div>
				<div class="table-wrap">
					<div class="table">
						<el-table 
						v-loading="isLoading && recordsList.length"
						:data="recordsList" 
						style="width: 100%" 
						header-row-class-name="header-row-class"
						header-cell-class-name="header-cell-class"
						cell-class-name="cell-class"
						row-class-name="row-class">
							<div class="empty-wrap" v-loading="isLoading" slot="empty" v-if="!recordsList.length">
								<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"
									></el-empty>
							</div>
							<el-table-column prop="orderNo" label="订单编号" align="center">
							</el-table-column>
							<el-table-column prop="paymentType" label="款项类型" align="center">
								<template slot-scope="scope">
									<span>{{scope.row.paymentType | paymentTypeFilter}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="payTime" label="付款时间" align="center">
							</el-table-column>
							<el-table-column prop="" label="支付金额" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.changeType == 1">+</span>
									<span>{{scope.row.payAmount}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="beforeBalance" label="当前余额" align="center">
							</el-table-column>
							<el-table-column prop="transactionFlowNo" label="交易流水号" align="center">
							</el-table-column>
							<el-table-column prop="" label="账号" align="center">
							</el-table-column>
							<el-table-column prop="operator" label="操作人" align="center">
							</el-table-column>
							<el-table-column prop="" label="支付状态" align="center">
								<!-- 支付状态 1=已支付 2= 待确认 3=已确认 4=已退款 -->
								<template slot-scope="scope">
									<span v-if="scope.row.payStatus == 1">已支付</span>
									<span v-if="scope.row.payStatus == 2">待确认</span>
									<span v-if="scope.row.payStatus == 3">已确认</span>
									<span v-if="scope.row.payStatus == 4">已退款</span>
								</template>
							</el-table-column>
							<el-table-column prop="" label="支付方式" align="center">
								<!-- 支付方式 1=余额支付 2=收款码 3= 已退款 -->
								<template slot-scope="scope">
									<span v-if="scope.row.payType == 1">余额支付</span>
									<span v-if="scope.row.payType == 2">收款码</span>
									<span v-if="scope.row.payType == 3">已退款</span>
								</template>
							</el-table-column>
							<el-table-column prop="" label="收款账号" align="center">
							</el-table-column>
							<el-table-column prop="remarks" label="备注" align="center">
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination background :current-page.sync="pageNum" :page-size.sync="pageSize" :page-sizes="pageSizesOptions"
							layout="sizes, prev, pager, next" :total="total" @size-change="loadData"
							@current-change="loadData">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WalletGetInfo,
		RecordsList
	} from '../common/api.js'
	import {
		download
	} from '../common/request.js'
	import moment from 'moment';
	var that = ''
	var ruleForm = {
		changeType: '', // 变动类型 0-支出 1-收入
		paymentType: '', // 1=工单 2 充值 3=平台充值  4=社区奖赏 5=工单赔偿 6=平台扣除 7=工单退款 8=变更附加费 9=促好评 10=远程费 11= 加急费   12=返件快递费
		maxPayTime: '', // 付款时间结束
		minPayTime: '', // 付款时间开始
		orderNo: '', // 订单号
		payTime: [],
	}
	export default {
		data() {
			return {
				typeOptions: [{
					value: '0',
					label: '支出'
				}, {
					value: '1',
					label: '收入'
				}],
				paymentTypeOptions: [{
					value: '1',
					label: '工单'
				}, {
					value: '2',
					label: '充值'
				}, {
					value: '3',
					label: '平台充值'
				}, {
					value: '4',
					label: '社区奖赏'
				}, {
					value: '5',
					label: '工单赔偿'
				}, {
					value: '6',
					label: '平台扣除'
				}, {
					value: '7',
					label: '工单退款'
				}, {
					value: '8',
					label: '变更附加费'
				}, {
					value: '9',
					label: '促好评'
				}, {
					value: '10',
					label: '远程费'
				}, {
					value: '11',
					label: '加急费'
				}, {
					value: '12',
					label: '返件快递费'
				}],
				tableData: [],
				
				walletGetInfo: {}, //
				recordsList: [], //
				
				ruleForm: {...ruleForm},
				rules: {
					reminderContent: [{
						required: true,
						message: '请输入催单备注',
						trigger: 'blur'
					}],
				},
				
				total: 0,
				pageNum: 1,
				pageSize: 10,
				isLoading: true,

			}
		},
		filters: {
			paymentTypeFilter(val) {
				let result = ''
				that.paymentTypeOptions.forEach(v => {
					if (v.value == val) {
						result = v.label
					}
				})
				return result
			}
		},
		created() {
			that = this
			this.getWalletGetInfo()
			this.loadData()
		},
		mounted() {
			
		},
		methods: {
			reload() {
				this.pageNum = 1
				this.loadData()
			},
			// 查询我的账户详细信息
			getWalletGetInfo() {
				WalletGetInfo().then(res => {
					this.walletGetInfo = res.data
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				this.loadData('export')
			},
			// 查询钱包记录列表
			loadData(type) {
				let data = {
					changeType: this.ruleForm.changeType, // 变动类型 0-支出 1-收入
					paymentType: this.ruleForm.paymentType, //
					maxPayTime: '', // 付款时间结束
					minPayTime: '', // 付款时间开始
					orderNo: this.ruleForm.orderNo, // 订单号
					pageSize: this.pageSize,
					pageNum: this.pageNum
				}
				if (this.ruleForm.payTime) {
					data.minPayTime = this.ruleForm.payTime[0] || ''
					data.maxPayTime = this.ruleForm.payTime[1] || ''
				}
				
				console.log('data: ',data);
				
				if (type == 'export') {
					download('/api/app/records/export', {
						...data
					}, `账户明细记录_${moment().format('YYYY_MM_DD HH_mm_ss')}.xlsx`)
				} else {
					this.isLoading = true
					RecordsList(data).then(res => {
						this.recordsList = res.rows
						this.total = res.total
						this.$bus.$emit('backTop')
					}).finally(() => {
						this.isLoading = false
					})
				}
				
			},
			// 筛选
			filter() {
				this.reload()
			},
			// 重置
			reset() {
				this.ruleForm = {
					...ruleForm
				}
				this.reload()
			},
			toAccountRecharge() {
				this.$router.push({ name: 'accountRecharge'})
			},

		}
	}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.account {
		.card {
			.z-header {
				height: 50px;
				border-bottom: 1px solid #EDEDED;
			
				.zh-tab {
					padding-left: 20px;
					display: flex;
			
					.zht-item {
						height: 50px;
						display: flex;
						position: relative;
						margin-right: 30px;
						box-sizing: border-box;
						padding-top: 20px;
			
						.zhti-text {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 16px;
						}
			
						.zhti-line {
							display: none;
							width: 44px;
							height: 0;
							border-bottom: 4px solid #3571DA;
							border-left: 4px solid transparent;
							border-right: 4px solid transparent;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translate(-50%, 0);
						}
			
						&.active {
							.zhti-text {
								font-weight: bold;
								color: #3571DA;
							}
			
							.zhti-line {
								display: initial;
							}
						}
					}
				}
			}
			.c-body {
				.zhanghu-yue {
					width: 362px;
					height: 72px;
					margin: 20px 0 20px 20px;
					border-radius: 4px;
					background-color: #5FB14D;
					display: flex;
					align-items: center;
					.zy-label {
						margin: 0 20px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
					}
					.zy-num {
						font-size: 24px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #FFFFFF;
					}
					.zy-btn {
						margin: 0 20px 0 auto;
						width: 60px;
						height: 32px;
						background: #FFFFFF;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #5FB14D;
						line-height: 32px;
						text-align: center;
					}
				}
				.cb-row {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					margin-bottom: 20px;
					.cbr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;
						.cbri-label {
							width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
						/deep/.input {
							width: 280px;
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						}
						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
							.el-input__suffix {
								display: flex;
								align-items: center;
							}
							&.type-1 {
								width: 280px;
							}
						}
						/deep/.date {
							height: 32px;
							padding-left: 8px;
							padding-right: 8px;
							input {
								// height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								text-align: left;
							}
							.el-input__icon {
								height: initial;
							}
							.el-icon-date {
								order: 1;
							}
							&.type-1 {
								width: 280px;
							}
						}
					}
					.cbr-shaixuan-btn {
						width: 60px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}
					.cbr-reset-btn {
						width: 60px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.cbr-add-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
					
						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/045.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}
					
						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 14px;
						}
					}
				}
				.table-wrap {
					margin-top: 20px;
					/deep/.table {
						width: 970px;
						border: 1px solid #DFDFDF;
						border-radius: 8px 8px 0 0;
						margin: 0 auto;
						
						// colgroup {
						// 	col {
						// 		&:nth-child(1) {
						// 			width: 100px;
						// 		}
						// 		&:nth-child(3) {
						// 			width: 80px;
						// 		}
						// 		&:nth-child(12) {
						// 			width: calc(970px - 180px - 740px);
						// 		}
						// 	}
						// }
						
						.header-row-class {}
						.row-class {
							&:last-child {
								.cell-class {
									border: none;
								}
							}
						}
						.header-cell-class {
							background-color: #F7F7F7;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #666666;
						}
						.cell-class {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							.cell {
								padding: 0;
							}
							// &:nth-child(1) {
							// 	.cell {
							// 		white-space: nowrap;
							// 	}
							// }
						}
						
					}
				}
				.pagination-wrap {
					padding: 20px 0;
					/deep/.pagination {
						display: flex;
						justify-content: center;
					}
				}
				
			}
		}
	}
</style>