<template>
	<div class="orderCreate L">
		<div class="orderCreate-content M">
			<div class="c-header">
				<div class="ch-left">
					<div class="chl-icon"></div>
					<div class="chl-text btn-text" @click="openHome">首页</div>
					<div class="chl-line">/</div>
					<div class="chl-text">立即下单</div>
				</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="c-body" v-loading="!isReady">
				<div class="shangpin-xinxi card" style="margin-top: 0;">
					<div class="card-header">
						<div class="ch-headingbox">
							<div class="chh-text">产品信息</div>
							<div class="chh-line"></div>
						</div>
						<div class="ch-tip">注：快速下单只对安装单开放，维修、清洗、调制/检测/定位/教学需手动下单</div>
						<div class="ch-right">
							<div class="chr-number">
								已有
								<span class="highlight">{{orderReceiveOrderAllCount || 0}}</span>
								个订单成功找到师傅
							</div>
						</div>
					</div>
					<div class="cb-row-wrap" v-for="(productListitem,productListindex) in ruleForm.productList"
						:key="`cb-row-wrap-${productListindex}`">
						<div class="cb-row kuaisuXiadan" v-if="productsLastOrderProduct.length">
							<div class="cbr-label"><span class="star">*</span>快速下单：</div>
							<div class="cbr-content">
								<div class="kuaisu-xiadan">
									<div @click="productsLastOrderProductitemClick(productListitem,productsLastOrderProductitem)"
										v-for="(productsLastOrderProductitem,productsLastOrderProductindex) in productsLastOrderProduct"
										:key="`kx-item-${productListindex}-${productsLastOrderProductindex}`">
										<el-popover placement="top" width="" trigger="hover">
											<div class="kxbi-popover-content">
												<div class="kxbip-label">
													{{`${productsLastOrderProductitem.modelName}(${productsLastOrderProductitem.categoryName})`}}
												</div>
												<!-- <div class="kxbip-num">{{productsLastOrderProductitem.omsOrder.serviceType | orderServiceTypeFilter}}X{{productsLastOrderProductitem.number}}</div> -->
											</div>
											<div class="kx-item pointer" slot="reference">
												<el-image :src="imgUrl(productsLastOrderProductitem.productPicture)"
													alt="" fit="cover" class="kxi-pic" />
												<div class="kxi-title txt-ellipsis-1">
													{{`${productsLastOrderProductitem.modelName}(${productsLastOrderProductitem.categoryName})`}}
												</div>
											</div>
										</el-popover>
										<!-- <div class="kxi-close"></div> -->
									</div>
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>选择产品：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-cascader class="select type-1" v-model="productListitem.erjiCategoryValue"
										:options="erjiCategoryList" clearable
										:props="{ expandTrigger: 'hover', label: 'name', value: 'id', children: 'child' }"
										@change="handleErjiCategoryChange($event,productListitem,productListindex)">
									</el-cascader>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>选择型号：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-1" v-model="productListitem.productId"
										placeholder="请选择"
										@change="productListitemOptionsChange($event,productListitem,productListindex)">
										<el-option v-for="item in productListitem.options" :key="`options-${item.id}`"
											:label="item.modelName" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<div class="cbr-add-btn btn-fill" @click="toProductAdd">
									<div class="cbra-icon"></div>
									<div class="cbra-text">添加</div>
								</div>
							</div>
						</div>
						<div class="cb-row uploadPic">
							<div class="cbr-label">产品图片：</div>
							<div class="cbr-content">
								<div class="upload-item pic" v-if="productListitem.product.productPicture">
									<Media :value="productListitem.product.productPicture">
										<div class="ui-tip" slot="ext">产品图片</div>
									</Media>
								</div>
								<div class="upload-item" v-else>
									<div class="ui-icon"></div>
									<div class="ui-text">上传产品图片</div>
								</div>
								<div class="group-box">
									<div class="gb-text">第 {{productListindex+1}} 组</div>
									<div class="gb-icon pointer" v-if="ruleForm.productList.length > 1"
										@click="productListDel(productListitem,productListindex)"></div>
								</div>
							</div>
						</div>
						<div class="cb-row shangpinBeizhu">
							<div class="cbr-label">安维说明：</div>
							<div class="cbr-content">
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="productListitem.remarks" class="textarea type-1"
											type="textarea" :show-word-limit="true" :maxlength="400" :rows="2"
											placeholder="请在此处给师傅留言,师傅可见"
											clearable></el-input>
									</el-form-item>
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>收费类型：</div>
							<div class="cbr-content">
								<div class="select-btn pointer" :class="{active: ruleForm.parole == 1}"
									@click="paroleClick(1,productListitem)">保内</div>
								<div class="select-btn pointer" :class="{active: ruleForm.parole == 2}"
									@click="paroleClick(2,productListitem)">保外</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>服务项目：</div>
							<div class="cbr-content">
								<div class="select-btn pointer"
									:class="{active: ruleForm.serviceType == 1,notAllowed: ruleForm.parole == 1 ? (productListitem.product.installation ? false:true):(productListitem.product.guideInstallation ? false:true)}"
									@click="serviceTypeClick(1,productListitem)">安装</div>
								<div class="select-btn pointer" :class="{active: ruleForm.serviceType == 2}"
									@click="serviceTypeClick(2,productListitem)">维修</div>
								<div class="select-btn pointer"
									:class="{active: ruleForm.serviceType == 3,notAllowed: ruleForm.parole == 1 ? (productListitem.product.cleanse ? false:true):(productListitem.product.guideCleanse ? false:true)}"
									@click="serviceTypeClick(3,productListitem)">清洗</div>
								<div class="select-btn pointer"
									:class="{active: ruleForm.serviceType == 4,notAllowed: ruleForm.parole == 1 ? (productListitem.product.debug ? false:true):(productListitem.product.guideDebug ? false:true)}"
									@click="serviceTypeClick(4,productListitem)">调制/检测/定位/教学</div>
							</div>
						</div>
						<template v-if="ruleForm.serviceType == 2">
							<div class="cb-row">
								<div class="cbr-label"><span class="star">*</span>维修类型：</div>
								<div class="cbr-content">
									<div class="select-btn pointer"
										:class="{active: productListitem.maintenanceType == 1,notAllowed: ruleForm.parole == 1 ? (productListitem.product.repair ? false:true):(productListitem.product.guideRepair ? false:true)}"
										style="cursor: ;"
										@click="maintenanceTypeClick(1,productListitem,productListindex)">
										普通</div>
									<div class="select-btn pointer"
										:class="{active: productListitem.maintenanceType == 2,notAllowed: ruleForm.parole == 1 ? (productListitem.product.overhaul ? false:true):(productListitem.product.guideOverhaul ? false:true)}"
										@click="maintenanceTypeClick(2,productListitem,productListindex)">大修</div>
									<div class="select-btn pointer"
										:class="{active: productListitem.maintenanceType == 3,notAllowed: ruleForm.parole == 1 ? (productListitem.product.medium ? false:true):(productListitem.product.guideMedium ? false:true)}"
										@click="maintenanceTypeClick(3,productListitem,productListindex)">中修</div>
									<div class="select-btn pointer"
										:class="{active: productListitem.maintenanceType == 4,notAllowed: ruleForm.parole == 1 ? (productListitem.product.minor ? false:true):(productListitem.product.guideMinor ? false:true)}"
										@click="maintenanceTypeClick(4,productListitem,productListindex)">小修</div>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label" style="align-self: flex-start;">维修类型备注：</div>
								<div class="cbr-content">
									<div class="weixiu-remark">
										{{productListitem.erjiCategoryValue | ruleFilter(erjiCategoryList,productListitem.maintenanceType) || '--'}}
									</div>
								</div>
							</div>
						</template>
						<div class="cb-row uploadPic" style="margin-top: 0;">
							<div class="cbr-label">操作视频：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-1" v-model="productListitem.productVideoSelect"
										multiple collapse-tags placeholder="请选择" value-key="fileUrl">
										<el-option v-for="item in productListitem.product.productVideo"
											:key="`options-${item.fileUrl}`" :label="item.fileName" :value="item">
											<span style="float: left">{{ item.fileName }}</span>
											<span style="float: right" @click.stop="">
												<Media mediaClass="none" :value="[item]" keyName="fileUrl" :limit="1">
													<span>查看</span>
												</Media>
											</span>
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row uploadPic">
							<div class="cbr-label"></div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<FileUpload class="upload-demo" customView="view-1" customBtn isShowEdit
										className="w100h100" keyName="fileUrl" style="width: auto;overflow-y: hidden;"
										@edit="editPopUpload(arguments,productListindex)"
										v-model="productListitem.newProductVideo" :limit="Infinity"
										:fileType="videoFileType" dir="productVideo">
										<div class="upload-item pointer" style="margin-right: 0;"
											@click="openPopUpload(2,productListindex)">
											<div class="ui-icon"
												:style="{backgroundImage: `url('${require('../assets/052.png')}')`}">
											</div>
											<div class="ui-text">操作视频</div>
										</div>
									</FileUpload>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row uploadPic">
							<div class="cbr-label">完工示例图：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-1" v-model="productListitem.finishedDrawingSelect"
										multiple collapse-tags placeholder="请选择" value-key="fileUrl">
										<el-option v-for="item in productListitem.product.finishedDrawing"
											:key="`options-${item.fileUrl}`" :label="item.fileName" :value="item">
											<span style="float: left">{{ item.fileName }}</span>
											<span style="float: right" @click.stop="">
												<Media mediaClass="none" :value="[item]" keyName="fileUrl" :limit="1">
													<span>查看</span>
												</Media>
											</span>
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row uploadPic">
							<div class="cbr-label"></div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<FileUpload class="upload-demo" customView="view-1" customBtn isShowEdit
										className="w100h100" keyName="fileUrl" style="width: auto;overflow-y: hidden;"
										@edit="editPopUpload(arguments,productListindex)"
										v-model="productListitem.newFinishedDrawing" :limit="Infinity"
										dir="finishedDrawing">
										<div class="upload-item pointer" style="margin-right: 0;"
											@click="openPopUpload(1,productListindex)">
											<div class="ui-icon"
												:style="{backgroundImage: `url('${require('../assets/052.png')}')`}">
											</div>
											<div class="ui-text">完工示例图</div>
										</div>
									</FileUpload>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>产品数量：</div>
							<div class="cbr-content">
								<div class="input-number-box">
									<el-input-number v-model="productListitem.number" :min="1" :max="10"
										label="产品数量"></el-input-number>
									<div class="ib-unit">套</div>
								</div>
								<div class="price-box">
									<div class="pb-label">价格：</div>
									<div class="pb-number">
										￥{{productListitem.number | priceFilter(ruleForm.parole,ruleForm.serviceType,productListitem.maintenanceType,productListitem)}}
									</div>
								</div>
							</div>
						</div>
						<template v-if="ruleForm.parole == 1">
							<div class="cb-row" style="align-items: flex-start;margin-bottom: 0;">
								<div class="cbr-label" style="line-height: 32px;">增值服务：</div>
								<div class="cbr-content"
									style="display: flex;flex-direction: column;align-items: flex-start;">
									<template
										v-for="(omsSurchargeListitem,omsSurchargeListindex) in productListitem.omsSurchargeList">
										<div class="cbr-chaijiu" v-if="omsSurchargeListitem.pzId">
											<div class="cbrc-text">{{omsSurchargeListitem.name}}</div>
											<div class="input-number-box">
												<el-input-number v-model="omsSurchargeListitem.num" :min="0"
													label="请输入价格"></el-input-number>
											</div>
											<div class="cbrc-text">价格：{{omsSurchargeListitem.remarks}}</div>
										</div>
										<div style="display: flex;align-items: center;margin-bottom: 20px;" v-else>
											<el-form-item prop="" class="form-item">
												<el-input v-model="omsSurchargeListitem.name" class="input type-2"
													placeholder="请输入名称" clearable></el-input>
											</el-form-item>
											<el-form-item prop="" class="form-item">
												<el-input v-model="omsSurchargeListitem.price" class="input type-2"
													placeholder="请输入价格" clearable
													@input="verifyPositiveInteger(omsSurchargeListitem,{key1: 'price'})"></el-input>
											</el-form-item>
											<div class="input-number-box">
												<el-input-number v-model="omsSurchargeListitem.num" :min="1"
													label="描述文字"></el-input-number>
											</div>
											<div class="cbr-add-btn btn-fill"
												@click="omsSurchargeListitemAdd(productListitem,omsSurchargeListitem,omsSurchargeListindex)">
												<div class="cbra-icon"></div>
												<div class="cbra-text">添加</div>
											</div>
											<div class="cbr-add-btn del btn-fill"
												v-if="productListitem.omsSurchargeList.length - productListitem.omsSurchargeList.filter(v => v.id).length  > 1"
												@click="omsSurchargeListitemDel(productListitem,omsSurchargeListitem,omsSurchargeListindex)">
												<div class="cbra-text">删除</div>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
					</div>
					<el-divider v-if="ruleForm.parole == 1 || (ruleForm.serviceType == 1 && ruleForm.parole == 1)"></el-divider>
					<template v-if="ruleForm.parole == 1">
						<div class="cb-row">
							<div class="cbr-label">加急订单：</div>
							<div class="cbr-content">
								<el-radio v-model="ruleForm.urgentFlag" label="0">不加急</el-radio>
								<el-radio v-model="ruleForm.urgentFlag" label="1">加急</el-radio>
								<!-- <div :style="ruleForm.urgentFlag == 1 | visibilityFilter" style="display: flex;">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.urgentName" class="input type-6" placeholder="请输入加急项目名称"
											clearable></el-input>
									</el-form-item>
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.urgentPrice" class="input type-6"
											placeholder="请输入加急项目金额" clearable
											@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'urgentPrice',})"></el-input>
									</el-form-item>
								</div> -->
							</div>
						</div>
						<template v-if="ruleForm.urgentFlag == 1">
							<div class="cb-row">
								<div class="cbr-label"></div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.urgentPrice" class="input type-5"
											placeholder="加急费30元起步,请输入" clearable
											@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'urgentPrice',})"></el-input>
									</el-form-item>
								</div>
							</div>
						</template>
					</template>
					<template v-if="ruleForm.serviceType == 1 && ruleForm.parole == 1">
						<div class="cb-row">
							<div class="cbr-label">促好评服务：</div>
							<div class="cbr-content">
								<el-radio v-model="ruleForm.praiseFlag" label="0">不需要促好评</el-radio>
								<el-radio v-model="ruleForm.praiseFlag" label="1">需要促好评</el-radio>
							</div>
						</div>
						<template v-if="ruleForm.praiseFlag == 1">
							<div class="cb-row">
								<div class="cbr-label"></div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.praisePrice" class="input type-5"
											placeholder="请输入好评金额" clearable
											@input="verifyPositiveInteger('this',{key1: 'ruleForm',key2: 'praisePrice',})"></el-input>
									</el-form-item>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label"></div>
								<div class="cbr-content">
									<div class="cbrc-haopinTip">*3图以上+5星好评+10字以上评论</div>
								</div>
							</div>
						</template>
					</template>
				</div>
				<div class="kehu-xinxi card">
					<div class="card-header">
						<div class="ch-headingbox">
							<div class="chh-text">客户信息</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="kx-content">
						<div class="kxc-left">
							<div class="cb-row shibieDizhi">
								<div class="cbr-label">智能识别地址：</div>
								<div class="cbr-content">
									<div class="cbr-content">
										<el-form-item prop="" class="form-item">
											<el-input class="textarea type-2" type="textarea" :show-word-limit="true"
												v-model="content" :maxlength="400" :rows="2"
												placeholder="请输入内容。这是一个文本框占位示例。可固定高度或者使用自动高度。" clearable></el-input>
										</el-form-item>
									</div>
									<div class="cbr-shibie btn-fill" @click="setMaintainAreaResolution">立即识别</div>
								</div>
							</div>
						</div>
						<div class="kxc-right">
							<div class="cb-row">
								<div class="cbr-label"><span class="star">*</span>客户姓名：</div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.realName" class="input type-3" placeholder="请输入..."
											clearable></el-input>
									</el-form-item>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label"><span class="star">*</span>客户手机：</div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.phone" class="input type-7" placeholder="请输入..."
											clearable></el-input>
									</el-form-item>
									<template v-if="checkedPhoneExtension">
										<el-form-item prop="" class="form-item">
											<el-input v-model="ruleForm.phoneExtension" class="input type-8"
												placeholder="请输入虚拟号" clearable></el-input>
										</el-form-item>
									</template>
									<div class="mul-checkbox pointer" @click="checkedPhoneExtensionClick">
										<div class="checkbox">
											<div class="no" v-if="!checkedPhoneExtension"></div>
											<div class="yes" v-else></div>
										</div>
										<div class="label">虚拟号</div>
									</div>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label"><span class="star">*</span>客户地址：</div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-cascader ref="refSubCat" class="select type-2"
											v-model="listProvinceCityValue" :options="listProvinceCity" clearable
											:props="{ expandTrigger: 'hover', checkStrictly: true }"
											@change="listProvinceCityChange($event)">
										</el-cascader>
									</el-form-item>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label"><span class="star">*</span>详细地址：</div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-input v-model="ruleForm.detailAddress" class="input type-3"
											placeholder="请输入..." clearable></el-input>
									</el-form-item>
								</div>
							</div>
							<div class="cb-row">
								<div class="cbr-label">期望上门时间：</div>
								<div class="cbr-content">
									<el-form-item prop="" class="form-item">
										<el-date-picker class="datetime type-1" v-model="ruleForm.doorTime"
											format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
											:defaultValueForTime="{hours: 15,minutes: 30,seconds: 0}"
											:picker-options="doorTimePickerOptions" type="datetime" placeholder="选择日期时间"
											@change="dateChange">
										</el-date-picker>
									</el-form-item>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="wuliu-xinxi card">
					<div class="card-header">
						<div class="ch-headingbox">
							<div class="chh-text">物流信息</div>
							<div class="chh-line"></div>
						</div>
						<div class="ch-right">
							<div class="cbr-add-btn btn-fill" @click="logisticsAdd()">
								<div class="cbra-icon"></div>
								<div class="cbra-text">添加</div>
							</div>
						</div>
					</div>
					<div class="wuliuXinxi"
						v-for="(logisticsVoListitem,logisticsVoListindex) in ruleForm.logisticsVoList"
						:key="`wuliuXinxi-${logisticsVoListindex}`">
						<div class="cb-row">
							<div class="cbr-label">选择产品：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-3" v-model="logisticsVoListitem.productId" clearable
										placeholder="请选择">
										<el-option v-for="item in ruleForm.productList"
											:key="`wuliuXinxi-${logisticsVoListindex}-${item.product.id}`"
											:label="item.label" :value="item.product.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">物流信息：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-3" v-model="logisticsVoListitem.courierCompany"
										filterable clearable placeholder="请选择物流公司" value-key="phone">
										<el-option v-for="item in wuliuCompanyList"
											:key="`wuliuXinxi-${logisticsVoListindex}-${item.id}`" :label="item.name"
											:value="item.name">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-content ml-20">
								<el-form-item prop="" class="form-item">
									<el-input v-model="logisticsVoListitem.courierNum" class="input type-4"
										placeholder="请输入物流单号" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row"
							v-if="logisticsVoListitem.courierCompany && (logisticsVoListitem.courierCompany.indexOf('顺丰') != -1)">
							<!-- <div class="cbr-label"></div> -->
							<div class="cbr-content ml-20">
								<el-form-item prop="" class="form-item">
									<el-input v-model="logisticsVoListitem.phone" class="input type-4"
										placeholder="请输入收件人号码" clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cbr-add-btn del btn-fill" v-if="ruleForm.logisticsVoList.length  > 1"
							@click="logisticsDel(ruleForm.logisticsVoList,logisticsVoListitem,logisticsVoListindex)">
							<div class="cbra-text">删除</div>
						</div>
					</div>
				</div>
				<div class="dindan-lianxiren card">
					<div class="card-header">
						<div class="ch-headingbox">
							<div class="chh-text">订单联系人</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-tip">订单异常或服务异常师傅会通过以下方式联系您。设置一次，以后下单免填写</div>
					</div>
					<div class="dindanLianxiren">
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>订单联系人：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.contactName" class="input type-5" placeholder="请输入..."
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"><span class="star">*</span>联系电话：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.contactPhone" class="input type-5" placeholder="请输入..."
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
					</div>
					<div class="dindanLianxiren">
						<div class="cb-row">
							<div class="cbr-label">技术员：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-select class="select type-4" v-model="ruleForm.technicalContacts[0]"
										placeholder="请选择" clearable value-key="phone">
										<el-option v-for="item in userGetManufacturerInfo.technicalUserVoList"
											:key="`technicalUserVoList-${item.phone}`" :label="item.name" :value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">联系电话：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.technicalContacts[0].phone" class="input type-5"
										placeholder="选择技术员后自动带入" clearable disabled></el-input>
								</el-form-item>
							</div>
						</div>
					</div>
				</div>
				<div class="qita-fuwu card">
					<div class="card-header">
						<div class="ch-headingbox">
							<div class="chh-text">其他服务</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="cb-row" v-if="ruleForm.serviceType == 2">
						<div class="cbr-label">返件寄回：</div>
						<div class="cbr-content">
							<el-radio v-model="ruleForm.returnParts" label="0">不需返件</el-radio>
							<el-radio v-model="ruleForm.returnParts" label="1">需要返件</el-radio>
						</div>
					</div>
					<div class="cb-row" v-if="ruleForm.returnParts == 1">
						<div class="cbr-label"></div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-select class="select type-5" v-model="ruleForm.addressId" placeholder="请选择返件地址"
									value-key="phone">
									<el-option v-for="item in userGetManufacturerInfo.umsUserAddressList"
										:key="`umsUserAddressList-${item.id}`" :label="item.label" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">店铺名称：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-input v-model="ruleForm.orderSource" class="input type-5" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">电商编号：</div>
						<div class="cbr-content">
							<el-form-item prop="" class="form-item">
								<el-input v-model="ruleForm.orderSourceNum" class="input type-5" placeholder="请输入"
									clearable></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row shangpinBeizhu">
						<div class="cbr-label">发单人备注：</div>
						<div class="cbr-content">
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.remarks" class="textarea type-1" type="textarea"
										:show-word-limit="true" :maxlength="400" :rows="2" placeholder="此处为发单人备忘录,仅自己可见,请输入"
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
					</div>
				</div>
			</el-form>
		</div>
		<div class="orderCreate-footer L">
			<div class="orderCreate-footer-content M">
				<div class="product-add-btn btn-fill" @click="productListAdd()">继续添加产品</div>
				<div class="of-right">
					<el-popover placement="top-start" width="" trigger="hover">
						<div class="feiyongmingxi">
							<div class="fy-item" v-if="omsOrderExpensesitem.name != '合计'"
								v-for="(omsOrderExpensesitem,omsOrderExpensesindex) in omsOrderExpenses"
								:key="`omsOrderExpenses-${omsOrderExpensesindex}`">
								<div class="fyi-label">{{omsOrderExpensesitem.name}}</div>
								<div class="fyi-value">
									<template v-if="omsOrderExpensesitem.name == '优惠价格'">
										-￥{{omsOrderExpensesitem.price}}
									</template>
									<template v-else>
										￥{{omsOrderExpensesitem.price}}
									</template>
								</div>
							</div>
						</div>
						<div class="ofr-container pointer" slot="reference">
							<div class="ofr-text-5">合计：</div>
							<div class="ofr-text-6">￥</div>
							<div class="ofr-text-7" v-if="Object.keys(omsOrderExpenses).length">
								{{omsOrderExpenses.find(v => v.name == '合计').price}}
							</div>
							<div class="ofr-arrow"></div>
						</div>
					</el-popover>
					<div class="submit-btn btn-fill" @click="verify">提交订单</div>
				</div>
			</div>
		</div>

		<!-- 上传 -->
		<PopUpload ref="PopUpload" :fileType="fileType" @save="PopUploadSave"></PopUpload>
		
	</div>
</template>

<script>
	import PopUpload from '../components/PopUpload.vue'
	import {
		CategoryList,
		ListProvinceCity,
		ProductsLastOrderProduct,
		ProductsList,
		UserGetManufacturerInfo,
		ServiceList,
		OrderAdd,
		OrderReceiveOrderAllCount,
		WuliuCompanyList,
		QuerySurcharge,
		LonlatByAddress,
		MaintainAreaResolution,
		ContactInfo,
		OrderShowMessage,
		Config
	} from '../common/api.js'
	import {
		mapState
	} from 'vuex'

	var logisticsVoList = {
		courierCompany: '', //快递公司
		courierNum: '', //快递单号
		productId: '', //产品Id
		phone: '', //快递手机号
	} // 工单物流列表

	var omsSurchargeList = {
		name: '', //名称
		price: '', //费用金额（总价》
		num: '', //数量
		id: '',
		pzId: ''
	}

	var productList = {
		maintenanceType: '', //维修类型1普通维修2大修3中修4小修
		number: 1, //产品数量
		omsSurchargeList: [{
			...omsSurchargeList
		}], //附加费用
		productId: '', //产品ID
		remarks: '', //安维说明
		options: [], //产品列表
		product: {}, //产品
		erjiCategoryValue: [],
		productVideoSelect: [],
		finishedDrawingSelect: [],
		newProductVideo: [],
		newFinishedDrawing: [],

	} // 工单产品列表

	export default {
		props: ['type'],
		components: {
			PopUpload
		},
		data() {
			return {
				num: 12,

				categoryList: [],
				erjiCategoryList: [],

				productsLastOrderProduct: [],

				ruleForm: {
					cityId: '', // 客户地址城市ID
					cityName: '', // 客户地址城市名称
					detailAddress: '', // 客户地址详细地址
					districtId: '', // 客户地址区ID
					districtName: '', // 客户地址区名称
					parole: 1, // 保内外1保内2保外
					phone: '', // 客户地址收货人手机
					phoneExtension: '', // 客户手机分机号
					provinceId: '', // 客户地址省ID
					provinceName: '', // 客户地址省名称
					realName: '', // 客户地址收货人名称
					serviceType: '', // 服务项目 1=安装2=维修3=清洗4=调试
					technicalContacts: [{
						name: '', // 姓名
						phone: '', // 手机号
					}], // 技术指导员
					addressId: '', // 厂商收货地址
					contactName: '', // 联系人姓名
					contactPhone: '', // 联系人手机
					createType: '1', // 下单模式1=一口价 默认一口价
					doorTime: '', // 上门时间
					id: '', // id
					logisticsVoList: [{
						...logisticsVoList
					}], // 工单物流列表
					orderSource: '', // 工单来源
					orderSourceNum: '', // 工单来源订单号
					paymentType: '', // 返件付款类型0垫付1到付
					praiseFlag: '0', // 是否促好评0否1是 默认不促好评
					praisePrice: '', // 促好评金额
					productList: [{
						...JSON.parse(JSON.stringify(productList))
					}], // 工单产品列表
					remarks: '', // 服务备注
					returnParts: '0', // 是否返件0不返件1需返件 默认不返件
					urgentFlag: '0', // 是否加急0不加急1加急 默认不加急
					urgentName: '', // 加急项目
					urgentPrice: '', // 加急项目金额

				},
				rules: {

				},

				listProvinceCity: [],
				listProvinceCityValue: [],
				userGetManufacturerInfo: {}, //厂商账号信息

				serviceList: {},

				keyWords: '',
				parentId: '',

				orderReceiveOrderAllCount: '', //

				wuliuCompanyList: [],


				doorTimePickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000;
					}
				},

				content: '',
				checkedPhoneExtension: false,
				isReady: false,
				isReadyUpdated: false,

				fileType: 1,
				productIndex: 0,
				
				create_order_discount: {
					// configValue: ''
				},

			}
		},
		computed: {
			...mapState(['quicklyOrder', 'againOrder']),
			omsOrderExpenses() {
				let arr = []
				let num = 0
				let productPrice = {
					name: '产品总价',
					price: 0
				}
				let discountPrice = {
					name: '优惠价格',
					price: 0
				}
				let omsSurchargePrice = {
					name: '增值服务',
					price: 0
				}
				let urgentPrice = {
					name: '加急',
					price: parseFloat(this.ruleForm.urgentPrice) || 0
				}
				let total = {
					name: '合计',
					price: 0
				}
				if (this.ruleForm.productList && this.ruleForm.productList.length) {
					this.ruleForm.productList.forEach(v => {
						console.log('v: ', v);
						if (v.productId) {
							num += v.number
							productPrice.price += this.$options.filters['priceFilter'](v.number, this.ruleForm
								.parole,
								this.ruleForm.serviceType, v.maintenanceType, v)
							if (v.omsSurchargeList && v.omsSurchargeList.length) {
								v.omsSurchargeList.forEach(v => {
									omsSurchargePrice.price += v.num * v.price
								})
							}

						}
					})
				}

				arr.push(productPrice)
				
				if (num >= 2) {
					discountPrice.price = productPrice.price * (1 - this.create_order_discount.configValue)
					arr.push(discountPrice)
				}
				
				arr.push(omsSurchargePrice)

				if (this.ruleForm.urgentFlag == 1) {
					arr.push(urgentPrice)
				}
				
				
				console.log('omsSurchargePrice.price: ', omsSurchargePrice.price);
				total.price = parseFloat(productPrice.price) + parseFloat(omsSurchargePrice.price) + parseFloat(urgentPrice
					.price) - discountPrice.price
				console.log('total: ', total);
				
				arr.push(total)
				arr = arr.map(v => {
					v.price = v.price ? v.price.toFixed(2) : 0
					return v
				})

				return arr
			}
		},
		filters: {
			ruleFilter(erjiCategoryValue, categoryList, maintenanceType) {
				console.log('categoryList: ', categoryList);
				console.log('erjiCategoryValue: ', erjiCategoryValue);
				if (erjiCategoryValue && erjiCategoryValue.length) {
					if (erjiCategoryValue.length == 3) {
						let l0 = categoryList.find(v => erjiCategoryValue[0] == v.id)
						let l1 = l0.child.find(v => erjiCategoryValue[1] == v.id)
						let l2 = l1.child.find(v => erjiCategoryValue[2] == v.id)
						if (maintenanceType == 1) {
							return l2.repairRule
						} else if (maintenanceType == 2) {
							return l2.overhaulRule
						} else if (maintenanceType == 3) {
							return l2.mediumRule
						} else if (maintenanceType == 4) {
							return l2.minorRule
						} else {
							return ''
						}
					} else if (erjiCategoryValue.length == 2) {

						let l1 = categoryList.find(v => erjiCategoryValue[0] == v.id)
						let l2 = l1.child.find(v => erjiCategoryValue[1] == v.id)
						if (maintenanceType == 1) {
							return l2.repairRule
						} else if (maintenanceType == 2) {
							return l2.overhaulRule
						} else if (maintenanceType == 3) {
							return l2.mediumRule
						} else if (maintenanceType == 4) {
							return l2.minorRule
						} else {
							return ''
						}
					} else {
						return ''
					}
				} else {
					return ''
				}
			},
			priceFilter(number, parole, serviceType, maintenanceType, productListitem) {
				if (!productListitem.productId || !serviceType) {
					return 0
				}
				if (parole == 1) {
					if (serviceType == 1) {
						return number * productListitem.product.installation
					} else if (serviceType == 2) {
						if (maintenanceType == 1) {
							return number * productListitem.product.repair
						} else if (maintenanceType == 2) {
							return number * productListitem.product.overhaul
						} else if (maintenanceType == 3) {
							return number * productListitem.product.medium
						} else if (maintenanceType == 4) {
							return number * productListitem.product.minor
						}
					} else if (serviceType == 3) {
						return number * productListitem.product.cleanse
					} else if (serviceType == 4) {
						return number * productListitem.product.debug
					}
				} else if (parole == 2) {
					if (serviceType == 1) {
						return number * productListitem.product.guideInstallation
					} else if (serviceType == 2) {
						if (maintenanceType == 1) {
							return number * productListitem.product.guideRepair
						} else if (maintenanceType == 2) {
							return number * productListitem.product.guideOverhaul
						} else if (maintenanceType == 3) {
							return number * productListitem.product.guideMedium
						} else if (maintenanceType == 4) {
							return number * productListitem.product.guideMinor
						}
					} else if (serviceType == 3) {
						return number * productListitem.product.guideCleanse
					} else if (serviceType == 4) {
						return number * productListitem.product.guideDebug
					}
				}
			}
		},
		watch: {
			'ruleForm.parole': function() {
				this.ruleForm.urgentFlag = '0'
				this.ruleForm.praiseFlag = '0'
				if (this.ruleForm.productList && this.ruleForm.productList.length) {
					this.ruleForm.productList.forEach(v => {
						if (this.ruleForm.serviceType) {
							let serviceList = JSON.parse(JSON.stringify(this.serviceList))
							v.omsSurchargeList = [...serviceList[this.ruleForm.serviceType.toString()], {
								...omsSurchargeList
							}]
						} else {
							v.omsSurchargeList = [{
								...omsSurchargeList
							}]
						}
					})
				}

			},
			'ruleForm.serviceType': function(value) {
				this.ruleForm.returnParts = '0'
				this.ruleForm.praiseFlag = '0'
				this.ruleForm.logisticsVoList = [{
					...logisticsVoList
				}]
				if (this.ruleForm.productList && this.ruleForm.productList.length) {
					this.ruleForm.productList.forEach(v => {
						if (value) {
							let serviceList = JSON.parse(JSON.stringify(this.serviceList))
							v.omsSurchargeList = [...serviceList[this.ruleForm.serviceType.toString()], {
								...omsSurchargeList
							}]
						} else {
							v.omsSurchargeList = [{
								...omsSurchargeList
							}]
						}
					})
				}

			},
			'ruleForm.returnParts': function() {
				this.ruleForm.addressId = ''
			},
			'ruleForm.praiseFlag': function() {
				this.ruleForm.praisePrice = ''
			},
			'ruleForm.urgentFlag': function() {
				this.ruleForm.urgentName = ''
				this.ruleForm.urgentPrice = ''
			},
		},
		async created() {
			
			Config('create_order_discount').then(res => {
				this.create_order_discount = res.data
			})
			
			if (this.type == 'quicklyOrder' && Object.keys(this.quicklyOrder).length) {
				console.log('this.QuicklyOrder: ', this.quicklyOrder);

				this.ruleForm.productList = []
				this.parentId = this.quicklyOrder.categoryIdOne

				this.categoryList = (await CategoryList({
					name: '',
					parentId: '',
					type: 1
				})).data

				if (this.categoryList.length > 1) {
					this.erjiCategoryList = this.categoryList
				} else if (this.categoryList.length == 1) {
					this.erjiCategoryList = this.categoryList[0].child
				}

				let CategoryListRes = await ServiceList()
				for (let x in CategoryListRes.data) {
					CategoryListRes.data[x] = CategoryListRes.data[x].map(v => {
						v.num = ''
						v.pzId = v.id
						return v
					})
				}
				this.serviceList = CategoryListRes.data
				this.ruleForm.parole = this.quicklyOrder.parole // 保内外1保内2保外

				this.ruleForm.serviceType = this.quicklyOrder
					.serviceType // 服务项目 1=安装2=维修3=清洗4=调试

				let productListitem = {}
				if (this.categoryList.length > 1) {
					productListitem.erjiCategoryValue = [this.quicklyOrder.categoryIdOne, this.quicklyOrder
						.categoryIdTwo, this.quicklyOrder.categoryId
					]
				} else if (this.categoryList.length == 1) {
					productListitem.erjiCategoryValue = [this.quicklyOrder.categoryIdTwo, this.quicklyOrder.categoryId]
				}

				productListitem.options = (await ProductsList({
					categoryId: this.quicklyOrder.categoryId, // 3级类目id
					categoryId1: '', // 1级类目id
					categoryId2: '', // 2级类目id
					keywords: '', //关键词 产品名称
					maxPrice: '', //最高价格
					minPrice: '', //最低价格
					isCheck: 1, //1已确认
				})).rows
				let index = productListitem.options.findIndex(v => v.id == this
					.quicklyOrder.productId)
				productListitem.product = productListitem.options[index]
				productListitem.productVideoSelect = this.quicklyOrder.productVideoSelect
				productListitem.finishedDrawingSelect = this.quicklyOrder.finishedDrawingSelect
				productListitem.label = productListitem.options[index]
					.categoryNameTwo + '/' + productListitem.options[
						index].categoryName + '/' + productListitem.options[index]
					.modelName
				productListitem.value = productListitem.options[index].categoryId

				productListitem.maintenanceType = this.quicklyOrder.maintenanceType
				productListitem.number = this.quicklyOrder.number
				productListitem.productId = this.quicklyOrder.productId
				productListitem.remarks = this.quicklyOrder.remarks
				productListitem.newProductVideo = []
				productListitem.newFinishedDrawing = []

				let QuerySurchargeRes = await QuerySurcharge(this.quicklyOrder.id)

				productListitem.omsSurchargeList = QuerySurchargeRes.data
					.map((v, i) => {
						let index = this.serviceList[this
								.ruleForm.serviceType]
							.findIndex(e => e.pzId == v
								.pzId)
						if (index != -1) {
							let obj = {
								...this.serviceList[
									this.ruleForm
									.serviceType][
									index
								],
								num: v.num
							}
							return obj
						} else {
							let obj = {
								name: v.name, //名称
								price: v
									.price, //费用金额（总价》
								num: v.num, //数量
								id: '',
								pzId: v.pzId
							}
							return obj
						}

					})

				productListitem.omsSurchargeList = [...productListitem.omsSurchargeList, {
					...omsSurchargeList
				}]

				this.ruleForm.productList.push(productListitem)

				let getOrderReceiveOrderAllCount = this.getOrderReceiveOrderAllCount()
				let getProductsLastOrderProduct = this.getProductsLastOrderProduct()
				let getListProvinceCity = this.getListProvinceCity()
				let getUserGetManufacturerInfo = this.getUserGetManufacturerInfo()
				let getWuliuCompanyList = this.getWuliuCompanyList()
				let getContactInfo = this.getContactInfo()

				Promise.all([
					// getOrderReceiveOrderAllCount,
					getProductsLastOrderProduct,
					// getListProvinceCity,
					// getUserGetManufacturerInfo,
					// getWuliuCompanyList,
					// getContactInfo,
				]).finally(() => {
					this.$nextTick(() => {
						this.isReady = true
					})
				})
			} else if (this.type == 'againOrder' && Object.keys(this.againOrder).length) {
				console.log('this.againOrder: ', this.againOrder);

				this.ruleForm.productList = []
				this.parentId = this.againOrder.productList[0].categoryIdOne

				this.categoryList = (await CategoryList({
					name: '',
					parentId: '',
					type: 1
				})).data

				if (this.categoryList.length > 1) {
					this.erjiCategoryList = this.categoryList
				} else if (this.categoryList.length == 1) {
					this.erjiCategoryList = this.categoryList[0].child
				}

				let CategoryListRes = await ServiceList()
				for (let x in CategoryListRes.data) {
					CategoryListRes.data[x] = CategoryListRes.data[x].map(v => {
						v.num = ''
						v.pzId = v.id
						return v
					})
				}
				this.serviceList = CategoryListRes.data

				this.ruleForm.serviceType = this.againOrder
					.serviceType // 服务项目 1=安装2=维修3=清洗4=调试

				this.ruleForm.parole = this.againOrder.parole // 保内外1保内2保外

				const asyncFunction = async () => {
					for (const productListitem of this.againOrder.productList) {
						// let productListitem = this.ruleForm.productList[0]
						productListitem.erjiCategoryValue = [productListitem.categoryIdTwo, productListitem
							.categoryId
						]

						if (this.categoryList.length > 1) {
							productListitem.erjiCategoryValue = [productListitem.categoryIdOne, productListitem
								.categoryIdTwo, productListitem
								.categoryId
							]
						} else if (this.categoryList.length == 1) {
							this.erjiCategoryList = this.categoryList[0].child
							productListitem.erjiCategoryValue = [productListitem.categoryIdTwo, productListitem
								.categoryId
							]
						}

						productListitem.options = (await ProductsList({
							categoryId: productListitem.categoryId, // 3级类目id
							categoryId1: '', // 1级类目id
							categoryId2: '', // 2级类目id
							keywords: '', //关键词 产品名称
							maxPrice: '', //最高价格
							minPrice: '', //最低价格
							isCheck: 1, //1已确认
						})).rows

						let index = productListitem.options.findIndex(v => v.id == productListitem.productId)
						productListitem.product = productListitem.options[index]
						productListitem.label = productListitem.options[index]
							.categoryNameTwo + '/' + productListitem.options[
								index].categoryName + '/' + productListitem.options[index]
							.modelName
						productListitem.value = productListitem.options[index].categoryId

						let QuerySurchargeRes = await QuerySurcharge(productListitem.id)

						productListitem.omsSurchargeList = QuerySurchargeRes.data
							.map((v, i) => {
								let index = this.serviceList[this
										.ruleForm.serviceType]
									.findIndex(e => e.pzId == v
										.pzId)
								if (index != -1) {
									let obj = {
										...this.serviceList[
											this.ruleForm
											.serviceType][
											index
										],
										num: v.num
									}
									return obj
								} else {
									let obj = {
										name: v.name, //名称
										price: v
											.price, //费用金额（总价》
										num: v.num, //数量
										id: '',
										pzId: v.pzId
									}
									return obj
								}

							})

						productListitem.omsSurchargeList = [...productListitem.omsSurchargeList, {
							...omsSurchargeList
						}]

						let item = {
							maintenanceType: productListitem.maintenanceType, //维修类型1普通维修2大修3中修4小修
							number: productListitem.number, //产品数量
							omsSurchargeList: productListitem.omsSurchargeList, //附加费用
							productId: productListitem.productId, //产品ID
							remarks: productListitem.remarks, //安维说明
							options: productListitem.options, //产品列表
							product: productListitem.product, //产品
							erjiCategoryValue: productListitem.erjiCategoryValue,
							productVideoSelect: productListitem.productVideoSelect,
							finishedDrawingSelect: productListitem.finishedDrawingSelect,
							newProductVideo: [],
							newFinishedDrawing: [],
						}
						this.ruleForm.productList.push(item)
					}
				}
				
				asyncFunction()

				// this.againOrder.productList.forEach(async (productListitem, productListindex) => {
				// 	// let productListitem = this.ruleForm.productList[0]
				// 	productListitem.erjiCategoryValue = [productListitem.categoryIdTwo, productListitem
				// 		.categoryId
				// 	]

				// 	if (this.categoryList.length > 1) {
				// 		productListitem.erjiCategoryValue = [productListitem.categoryIdOne, productListitem
				// 			.categoryIdTwo, productListitem
				// 			.categoryId
				// 		]
				// 	} else if (this.categoryList.length == 1) {
				// 		this.erjiCategoryList = this.categoryList[0].child
				// 		productListitem.erjiCategoryValue = [productListitem.categoryIdTwo, productListitem
				// 			.categoryId
				// 		]
				// 	}

				// 	productListitem.options = (await ProductsList({
				// 		categoryId: productListitem.categoryId, // 3级类目id
				// 		categoryId1: '', // 1级类目id
				// 		categoryId2: '', // 2级类目id
				// 		keywords: '', //关键词 产品名称
				// 		maxPrice: '', //最高价格
				// 		minPrice: '', //最低价格
				// 		isCheck: 1, //1已确认
				// 	})).rows

				// 	let index = productListitem.options.findIndex(v => v.id == productListitem.productId)
				// 	productListitem.product = productListitem.options[index]
				// 	productListitem.label = productListitem.options[index]
				// 		.categoryNameTwo + '/' + productListitem.options[
				// 			index].categoryName + '/' + productListitem.options[index]
				// 		.modelName
				// 	productListitem.value = productListitem.options[index].categoryId

				// 	let QuerySurchargeRes = await QuerySurcharge(productListitem.id)

				// 	productListitem.omsSurchargeList = QuerySurchargeRes.data
				// 		.map((v, i) => {
				// 			let index = this.serviceList[this
				// 					.ruleForm.serviceType]
				// 				.findIndex(e => e.pzId == v
				// 					.pzId)
				// 			if (index != -1) {
				// 				let obj = {
				// 					...this.serviceList[
				// 						this.ruleForm
				// 						.serviceType][
				// 						index
				// 					],
				// 					num: v.num
				// 				}
				// 				return obj
				// 			} else {
				// 				let obj = {
				// 					name: v.name, //名称
				// 					price: v
				// 						.price, //费用金额（总价》
				// 					num: v.num, //数量
				// 					id: '',
				// 					pzId: v.pzId
				// 				}
				// 				return obj
				// 			}

				// 		})

				// 	productListitem.omsSurchargeList = [...productListitem.omsSurchargeList, {
				// 		...omsSurchargeList
				// 	}]

				// 	let item = {
				// 		maintenanceType: productListitem.maintenanceType, //维修类型1普通维修2大修3中修4小修
				// 		number: productListitem.number, //产品数量
				// 		omsSurchargeList: productListitem.omsSurchargeList, //附加费用
				// 		productId: productListitem.productId, //产品ID
				// 		remarks: productListitem.remarks, //安维说明
				// 		options: productListitem.options, //产品列表
				// 		product: productListitem.product, //产品
				// 		erjiCategoryValue: productListitem.erjiCategoryValue,
				// 		productVideoSelect: productListitem.productVideoSelect,
				// 		finishedDrawingSelect: productListitem.finishedDrawingSelect,
				// 		newProductVideo: [],
				// 		newFinishedDrawing: [],
				// 	}
				// 	this.ruleForm.productList.push(item)
				// })

				let getOrderReceiveOrderAllCount = this.getOrderReceiveOrderAllCount()
				let getProductsLastOrderProduct = this.getProductsLastOrderProduct()
				let getListProvinceCity = this.getListProvinceCity()
				let getUserGetManufacturerInfo = this.getUserGetManufacturerInfo()
				let getWuliuCompanyList = this.getWuliuCompanyList()
				let getContactInfo = this.getContactInfo()

				Promise.all([
					getOrderReceiveOrderAllCount,
					getProductsLastOrderProduct,
					getListProvinceCity,
					getUserGetManufacturerInfo,
					getWuliuCompanyList,
					getContactInfo,
				]).finally(() => {
					this.$nextTick(() => {
						this.isReady = true
					})
				})

			} else {
				this.isReady = true

				this.categoryList = (await CategoryList({
					name: '',
					parentId: '',
					type: 1
				})).data

				if (this.categoryList.length > 1) {
					this.erjiCategoryList = this.categoryList
				} else if (this.categoryList.length == 1) {
					this.erjiCategoryList = this.categoryList[0].child
				}

				let CategoryListRes = await ServiceList()
				for (let x in CategoryListRes.data) {
					CategoryListRes.data[x] = CategoryListRes.data[x].map(v => {
						v.num = ''
						v.pzId = v.id
						return v
					})
				}
				this.serviceList = CategoryListRes.data

				// this.getOrderReceiveOrderAllCount()
				// this.getProductsLastOrderProduct()
				// this.getListProvinceCity()
				// this.getUserGetManufacturerInfo()
				// this.getWuliuCompanyList()
				// this.getContactInfo()

				let getOrderReceiveOrderAllCount = this.getOrderReceiveOrderAllCount()
				let getProductsLastOrderProduct = this.getProductsLastOrderProduct()
				let getListProvinceCity = this.getListProvinceCity()
				let getUserGetManufacturerInfo = this.getUserGetManufacturerInfo()
				let getWuliuCompanyList = this.getWuliuCompanyList()
				let getContactInfo = this.getContactInfo()


				// Promise.all([
				// 	// getOrderReceiveOrderAllCount,
				// 	getProductsLastOrderProduct,
				// 	// getListProvinceCity,
				// 	// getUserGetManufacturerInfo,
				// 	// getWuliuCompanyList,
				// 	// getContactInfo,
				// ]).finally(() => {
				// 	this.isReady = true
				// })

			}


		},
		mounted() {

		},
		methods: {
			dateChange(e) {
				console.log('dateChange: ', e);
			},
			verify() {
				if (!this.ruleForm.phone) {
					return this.toast('请输入客户手机号码')
				}
				OrderShowMessage(this.ruleForm.phone).then(res => {
					if (res.data == 1) {
						this.$rootApp.$refs.PopTip.open({
							content: res.msg,
							confirmText: '继续提交',
							confirm: () => {
								this.submit()
							}
						})
					} else {
						this.submit()
					}
				})
			},
			submit() {
				let data = {
					cityId: this.ruleForm.cityId, // 客户地址城市ID
					cityName: this.ruleForm.cityName, // 客户地址城市名称
					detailAddress: this.ruleForm.detailAddress, // 客户地址详细地址
					districtId: this.ruleForm.districtId, // 客户地址区ID
					districtName: this.ruleForm.districtName, // 客户地址区名称
					parole: this.ruleForm.parole, // 保内外1保内2保外
					phone: this.ruleForm.phone, // 客户地址收货人手机
					phoneExtension: this.ruleForm.phoneExtension, // 客户地址收货人虚拟号
					provinceId: this.ruleForm.provinceId, // 客户地址省ID
					provinceName: this.ruleForm.provinceName, // 客户地址省名称
					realName: this.ruleForm.realName, // 客户地址收货人名称
					serviceType: this.ruleForm.serviceType, // 服务项目 1=安装2=维修3=清洗4=调试
					technicalContacts: [], // 技术指导员
					addressId: this.ruleForm.addressId, // 厂商收货地址
					contactName: this.ruleForm.contactName, // 联系人姓名
					contactPhone: this.ruleForm.contactPhone, // 联系人手机
					createType: this.ruleForm.createType, // 下单模式1=一口价 默认一口价
					doorTime: this.ruleForm.doorTime, // 上门时间
					id: this.ruleForm.id, // id
					logisticsVoList: [], // 工单物流列表
					orderSource: this.ruleForm.orderSource, // 工单来源
					orderSourceNum: this.ruleForm.orderSourceNum, // 工单来源订单号
					paymentType: '', // 返件付款类型0垫付1到付
					praiseFlag: this.ruleForm.praiseFlag, // 是否促好评0否1是 默认不促好评
					praisePrice: this.ruleForm.praisePrice, // 促好评金额
					productList: [], // 工单产品列表
					remarks: this.ruleForm.remarks, // 服务备注
					returnParts: this.ruleForm.returnParts, // 是否返件0不返件1需返件 默认不返件
					urgentFlag: this.ruleForm.urgentFlag, // 是否加急0不加急1加急 默认不加急
					urgentName: this.ruleForm.urgentFlag == 1 ? '加急费' : '', // 加急项目
					urgentPrice: this.ruleForm.urgentPrice, // 加急项目金额

				}

				if (this.ruleForm.urgentFlag == 1) {
					if (this.ruleForm.urgentPrice < 30) {
						return this.toast('加急费30元起步，请重新输入')
					}
				}


				for (var i = 0; i < this.ruleForm.logisticsVoList.length; i++) {
					let v = this.ruleForm.logisticsVoList[i]
					if (v.productId || v.courierCompany || v.courierNum) {
						if (!v.productId || !v.courierCompany || !v.courierNum) {
							return this.toast('物流信息未填写完整')
						}
						if (v.courierCompany.indexOf('顺丰') != -1 && !v.phone) {
							return this.toast('物流信息未填写完整')
						}
						data.logisticsVoList.push(v)
					}

				}

				this.ruleForm.technicalContacts.forEach(v => {
					for (let x in v) {
						if (v[x]) {
							data.technicalContacts.push(v)
							break
						}
					}
				})

				this.ruleForm.productList.forEach(e => {
					let temp = JSON.parse(JSON.stringify(e))
					let omsSurchargeList = []
					e.omsSurchargeList.forEach(v => {
						if (v.num != 0 && v.name && v.price != 0) {
							omsSurchargeList.push({
								name: v.name,
								price: v.price,
								num: v.num,
								id: '',
								pzId: v.pzId || ''
							})
						}
					})
					temp.omsSurchargeList = omsSurchargeList
					data.productList.push(temp)
				})

				if (data.addressId) {
					this.userGetManufacturerInfo.umsUserAddressList.forEach(v => {
						if (data.addressId == v.id) {
							data.paymentType = v.paymentType
						}
					})
				}

				console.log('data: ', data);

				LonlatByAddress({
					address: data.detailAddress,
					area: data.districtName,
					city: data.cityName
				}).then(res => {
					if (res.data) {
						OrderAdd(data).then(res => {
							this.toast({
								message: '提交成功',
								type: 'success'
							})
							this.$router.push({
								name: 'orderPay',
								query: {
									id: res.data
								}
							})
						})
					} else {
						this.$rootApp.$refs.PopTip.open({
							content: '您下单的地址无法准确获取定位信息，可能会导致无法及时派单确定要继续提交吗?',
							confirm: () => {
								OrderAdd(data).then(res => {
									this.toast({
										message: '提交成功',
										type: 'success'
									})
									this.$router.push({
										name: 'orderPay',
										query: {
											id: res.data
										}
									})
								})
							}
						})
					}

				})



			},
			openPopUpload(fileType, productListindex) {
				this.productIndex = productListindex
				this.fileType = fileType
				this.$refs.PopUpload.open()
			},
			editPopUpload(event, productListindex) {
				let e = event[0]
				let index = event[1]
				this.productIndex = productListindex
				this.fileType = e.fileType
				let data = {
					fileName: e.fileName,
					fileType: e.fileType,
					fileUrl: e.fileUrl,
					fileIndex: index,
				}
				this.$refs.PopUpload.open(data)
			},
			PopUploadSave(e) {
				console.log('e: ', e);
				let data = {
					fileName: e.fileName,
					fileType: e.fileType,
					fileUrl: e.fileUrl
				}
				let key = ''
				if (e.fileType == 1) {
					key = 'newFinishedDrawing'
				} else if (e.fileType == 2) {
					key = 'newProductVideo'
				}
				if (e.fileIndex > -1) {
					this.ruleForm.productList[this.productIndex][key].splice(e.fileIndex, 1, data)
				} else {
					this.ruleForm.productList[this.productIndex][key].push(data)
				}
				console.log('this.ruleForm: ', this.ruleForm);
			},
			toOrderPay() {
				this.$router.push({
					name: 'orderPay'
				})
			},
			// 查询已接单总数
			getOrderReceiveOrderAllCount() {
				return OrderReceiveOrderAllCount().then(res => {
					this.orderReceiveOrderAllCount = 7646450 + (res.data || 0)
				})
			},
			// 查询快递公司编码列表
			getWuliuCompanyList() {
				return WuliuCompanyList().then(res => {
					this.wuliuCompanyList = res.data
				})
			},
			// 联系人信息
			getContactInfo() {
				return ContactInfo().then(res => {
					this.ruleForm.contactName = res.data.contactName
					this.ruleForm.contactPhone = res.data.contactPhone
				})
			},
			// 获取厂商账号信息
			getUserGetManufacturerInfo() {
				return UserGetManufacturerInfo().then(res => {
					this.userGetManufacturerInfo = res.data
					this.userGetManufacturerInfo.umsUserAddressList.forEach(v => {
						v.label =
							`${v.paymentType == 0 ? '垫付':'到付'} | ${v.realName} ${v.phone} ${v.detailAddress}`
					})
				})
			},
			// 查询增值服务配置列表
			getServiceList() {
				return ServiceList().then(res => {
					for (let x in res.data) {
						res.data[x] = res.data[x].map(v => {
							v.num = ''
							v.pzId = v.id
							return v
						})
					}
					this.serviceList = res.data
				})
			},
			getProductsLastOrderProduct() {
				return ProductsLastOrderProduct(this.parentId).then(res => {
					this.productsLastOrderProduct = res.data
				})
			},
			getListProvinceCity() {
				return ListProvinceCity().then(res => {
					this.listProvinceCity = res.data
				})
			},
			// // 查询已接单总数
			// getOrderReceiveOrderAllCount() {
			// 	OrderReceiveOrderAllCount().then(res => {
			// 		this.orderReceiveOrderAllCount = res.data
			// 	})
			// },
			// // 查询快递公司编码列表
			// getWuliuCompanyList() {
			// 	WuliuCompanyList().then(res => {
			// 		this.wuliuCompanyList = res.data
			// 	})
			// },
			// // 联系人信息
			// getContactInfo() {
			// 	ContactInfo().then(res => {
			// 		this.ruleForm.contactName = res.data.contactName
			// 		this.ruleForm.contactPhone = res.data.contactPhone
			// 	})
			// },
			// // 获取厂商账号信息
			// getUserGetManufacturerInfo() {
			// 	UserGetManufacturerInfo().then(res => {
			// 		this.userGetManufacturerInfo = res.data
			// 		this.userGetManufacturerInfo.umsUserAddressList.forEach(v => {
			// 			v.label =
			// 				`${v.paymentType == 0 ? '垫付':'到付'} | ${v.realName} ${v.phone} ${v.detailAddress}`
			// 		})
			// 	})
			// },
			// // 查询增值服务配置列表
			// getServiceList() {
			// 	ServiceList().then(res => {
			// 		for (let x in res.data) {
			// 			res.data[x] = res.data[x].map(v => {
			// 				v.num = ''
			// 				v.pzId = v.id
			// 				return v
			// 			})
			// 		}
			// 		this.serviceList = res.data
			// 	})
			// },
			// getProductsLastOrderProduct() {
			// 	ProductsLastOrderProduct(this.parentId).then(res => {
			// 		this.productsLastOrderProduct = res.data
			// 	})
			// },
			// getListProvinceCity() {
			// 	ListProvinceCity().then(res => {
			// 		this.listProvinceCity = res.data
			// 	})
			// },
			getCategoryList() {
				let data = {
					name: '',
					parentId: '',
					type: 1
				}
				CategoryList(data).then(res => {
					this.categoryList = res.data
					if (res.data.length > 1) {
						this.erjiCategoryList = res.data
					} else if (res.data.length == 1) {
						this.erjiCategoryList = res.data[0].child
					}
				})
			},
			// 选择一级类目
			categoryListitemClick(categoryListitem, categoryListindex) {
				if (this.parentId == categoryListitem.id) {
					return
				}
				this.parentId = categoryListitem.id
				this.getErjiCategoryList()
				console.log(111);
			},
			// 查子类目
			getErjiCategoryList() {
				let data = {
					name: '',
					parentId: this.parentId,
					type: 1
				}
				CategoryList(data).then(res => {
					this.erjiCategoryList = res.data
				})

			},
			handleErjiCategoryChange(value, productListitem, productListindex) {
				// this.$refs.ruleForm.validateField(['categoryId'])
				console.log(value);

				let data = {
					categoryId: value[value.length - 1], // 3级类目id
					categoryId1: '', // 1级类目id
					categoryId2: '', // 2级类目id
					keywords: '', //关键词 产品名称
					maxPrice: '', //最高价格
					minPrice: '', //最低价格
					isCheck: 1, //1已确认
				}
				ProductsList(data).then(res => {
					productListitem.options = res.rows
				})


			},
			// 选择保内、保外
			paroleClick(value, productListitem) {
				this.ruleForm.serviceType = ''
				productListitem.maintenanceType = ''
				this.ruleForm.parole = value
			},
			// 选择服务类型
			serviceTypeClick(value, productListitem) {
				if (value == 1) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.installation ? false : true) : (
							productListitem.product.guideInstallation ? false : true)) {
						return
					} else {
						this.ruleForm.serviceType = 1
					}
				} else if (value == 2) {
					this.ruleForm.serviceType = 2
				} else if (value == 3) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.cleanse ? false : true) : (productListitem
							.product.guideCleanse ? false : true)) {
						return
					} else {
						this.ruleForm.serviceType = 3
					}
				} else if (value == 4) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.debug ? false : true) : (productListitem
							.product.guideDebug ? false : true)) {
						return
					} else {
						this.ruleForm.serviceType = 4
					}
				}
				productListitem.maintenanceType = ''
			},
			// 选择维修类型
			maintenanceTypeClick(value, productListitem, productListindex) {
				if (value == 1) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.repair ? false : true) : (productListitem
							.product.guideRepair ? false : true)) {
						return
					} else {
						productListitem.maintenanceType = 1
					}
				} else if (value == 2) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.overhaul ? false : true) : (
							productListitem
							.product.guideOverhaul ? false : true)) {
						return
					} else {
						productListitem.maintenanceType = 2
					}
				} else if (value == 3) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.medium ? false : true) : (productListitem
							.product.guideMedium ? false : true)) {
						return
					} else {
						productListitem.maintenanceType = 3
					}
				} else if (value == 4) {
					if (this.ruleForm.parole == 1 ? (productListitem.product.minor ? false : true) : (productListitem
							.product.guideMinor ? false : true)) {
						return
					} else {
						productListitem.maintenanceType = 4
					}
				}
			},
			// 选择产品
			productListitemOptionsChange($event, productListitem, productListindex) {
				// console.log('$event: ',$event);
				let index = productListitem.options.findIndex(v => v.id == $event)
				productListitem.product = productListitem.options[index]
				productListitem.label = productListitem.options[index].categoryNameTwo + '/' + productListitem.options[
					index].categoryName + '/' + productListitem.options[index].modelName
				productListitem.value = productListitem.options[index].categoryId
				console.log('productListitem: ', productListitem);
			},
			// 选择客户地址省市区
			listProvinceCityChange(value) {
				console.log('value: ', value);
				console.log('this.listProvinceCityValue: ', this.listProvinceCityValue);
				console.log('this.$refs.refSubCat: ', this.$refs.refSubCat);
				let node = this.$refs.refSubCat.getCheckedNodes(true)[0]
				console.log('node: ', node);
				if (value.length) {
					this.ruleForm.provinceId = node.path[0]
					this.ruleForm.cityId = node.path[1]
					this.ruleForm.districtId = node.path[2]
					this.ruleForm.provinceName = node.pathLabels[0]
					this.ruleForm.cityName = node.pathLabels[1]
					this.ruleForm.districtName = node.pathLabels[2]
				} else {
					this.ruleForm.provinceId = ''
					this.ruleForm.cityId = ''
					this.ruleForm.districtId = ''
					this.ruleForm.provinceName = ''
					this.ruleForm.cityName = ''
					this.ruleForm.districtName = ''
				}
			},
			// 添加增值服务
			omsSurchargeListitemAdd(productListitem, omsSurchargeListitem, omsSurchargeListindex) {
				productListitem.omsSurchargeList.splice(omsSurchargeListindex + 1, 0, {
					...omsSurchargeList
				})
			},
			// 删除增值服务
			omsSurchargeListitemDel(productListitem, omsSurchargeListitem, omsSurchargeListindex) {
				productListitem.omsSurchargeList.splice(omsSurchargeListindex, 1)
			},
			// 添加物流
			logisticsAdd() {
				this.ruleForm.logisticsVoList.push({
					...logisticsVoList
				})
			},
			// 删除物流
			logisticsDel(logisticsVoList, logisticsVoListitem, logisticsVoListindex) {
				this.ruleForm.logisticsVoList.splice(logisticsVoListindex, 1)
			},
			// 添加产品
			productListAdd() {
				let obj = {
					...JSON.parse(JSON.stringify(productList))
				}
				if (this.ruleForm.serviceType) {
					let serviceList = JSON.parse(JSON.stringify(this.serviceList))
					obj.omsSurchargeList = [...serviceList[this.ruleForm.serviceType.toString()], {
						...omsSurchargeList
					}]
				} else {
					obj.omsSurchargeList = [{
						...omsSurchargeList
					}]
				}

				console.log('obj: ', obj);

				this.ruleForm.productList.push(obj)

			},
			// 删除产品
			productListDel(productListitem, productListindex) {
				this.ruleForm.productList.splice(productListindex, 1)
			},
			// 快速下单点击
			productsLastOrderProductitemClick(productListitem, productsLastOrderProductitem) {
				if (this.categoryList.length > 1) {
					this.erjiCategoryList = this.categoryList
					productListitem.erjiCategoryValue = [productsLastOrderProductitem.categoryIdOne,
						productsLastOrderProductitem.categoryIdTwo, productsLastOrderProductitem.categoryId
					]
				} else if (this.categoryList.length == 1) {
					this.erjiCategoryList = this.categoryList[0].child
					productListitem.erjiCategoryValue = [productsLastOrderProductitem.categoryIdTwo,
						productsLastOrderProductitem.categoryId
					]
				}

				ProductsList({
					categoryId: productsLastOrderProductitem.categoryId, // 3级类目id
					categoryId1: '', // 1级类目id
					categoryId2: '', // 2级类目id
					keywords: '', //关键词 产品名称
					maxPrice: '', //最高价格
					minPrice: '', //最低价格
					isCheck: 1, //1已确认
				}).then(res => {
					productListitem.options = res.rows
					productListitem.productVideoSelect = productsLastOrderProductitem.productVideoSelect
					productListitem.finishedDrawingSelect = productsLastOrderProductitem.finishedDrawingSelect
					productListitem.productId = productsLastOrderProductitem.id

					let index = productListitem.options.findIndex(v => v.id == productsLastOrderProductitem.id)
					productListitem.product = productListitem.options[index]
					productListitem.label = productListitem.options[index].categoryNameTwo + '/' + productListitem
						.options[
							index].categoryName + '/' + productListitem.options[index].modelName
					productListitem.value = productListitem.options[index].categoryId

				})
			},
			toProductAdd() {
				this.$router.push({
					name: 'productAdd'
				})
			},
			setMaintainAreaResolution() {
				if (!this.content) {
					return this.toast({
						message: '识别地址不能为空',
						type: 'error'
					})
				}
				MaintainAreaResolution(this.content).then(res => {
					if (res.data && res.data.length) {
						let address = res.data[0]
						this.ruleForm.realName = address.name || ''
						this.ruleForm.phone = address.mobile[0] || ''

						this.ruleForm.provinceId = address.xzq.provinceCode || ''
						this.ruleForm.cityId = address.xzq.pCode || ''
						this.ruleForm.districtId = address.xzq.code || ''
						this.ruleForm.provinceName = address.xzq.province || ''
						this.ruleForm.cityName = address.xzq.city || ''
						this.ruleForm.districtName = address.xzq.district || ''

						if (address.xzq.provinceCode && address.xzq.pCode && address.xzq.code) {
							this.listProvinceCityValue = [parseFloat(address.xzq.provinceCode), parseFloat(address
								.xzq.pCode), parseFloat(address.xzq.code)]
						} else if (address.xzq.provinceCode && address.xzq.pCode) {
							this.listProvinceCityValue = [parseFloat(address.xzq.provinceCode), parseFloat(address
								.xzq.pCode)]
						} else if (address.xzq.provinceCode) {
							this.listProvinceCityValue = [parseFloat(address.xzq.provinceCode)]
						} else {
							this.listProvinceCityValue = []
						}

						this.ruleForm.detailAddress = address.xzq.subArea || ''
						console.log('this.listProvinceCityValue: ', this.listProvinceCityValue);
						this.$nextTick(() => {
							this.$forceUpdate()
						})
					}

				})
			},
			checkedPhoneExtensionClick() {
				this.ruleForm.phoneExtension = ''
				this.checkedPhoneExtension = !this.checkedPhoneExtension
			},

		}
	}
</script>

<style lang="scss" scoped>
	.kxbi-popover-content {
		max-width: 970px;
		// padding: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.kxbip-label {
			// white-space: nowrap;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		.kxbip-num {
			// white-space: nowrap;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			margin-top: 8px;
		}
	}

	.mul-checkbox-wrap {
		position: absolute;
		top: 0;
		right: 0;
		width: 38px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			.checkbox {
				.no {
					border: 1px solid #3571da;
				}
			}
		}
	}

	.mul-checkbox {
		display: flex;
		align-items: center;
		margin-left: 10px;

		&:hover {
			.checkbox {
				.no {
					border: 1px solid #3571da;
				}
			}
		}

		.checkbox {
			width: 18px;
			height: 18px;

			.no {
				width: 100%;
				height: 100%;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #CDCDCD;
				box-sizing: border-box;
			}

			.yes {
				width: 100%;
				height: 100%;
				background-image: url('../assets/039.png');
				background-size: 100% 100%;
			}
		}

		.label {
			margin-left: 10px;
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 18px;

			.num {
				margin: 0 5px;
			}
		}
	}

	.feiyongmingxi {
		.fy-item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.fyi-label {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 32px;
			}

			.fyi-value {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 32px;
			}
		}
	}

	.orderCreate {
		.orderCreate-content {
			padding-bottom: 190px;
			// min-height: 100vh;

			.c-header {
				display: flex;
				align-items: center;
				height: 62px;

				.ch-left {
					display: flex;
					align-items: center;

					.chl-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/048.png');
						background-size: 100% 100%;
					}

					.chl-text {
						margin: 0 5px;
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}

					.chl-line {
						font-size: 12px;
						font-family: Inter, Inter;
						font-weight: 400;
						color: #8D9094;
					}
				}

				.ch-right {
					margin-left: auto;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;


				}
			}

			.c-body {
				.sin-checkbox {
					display: flex;
					align-items: center;
					margin-right: 30px;

					.checkbox {
						width: 16px;
						height: 16px;

						.no {
							width: 100%;
							height: 100%;
							background: #FFFFFF;
							border-radius: 50%;
							box-sizing: border-box;
							border: 1px solid #CED4DB;
						}

						.yes {
							width: 100%;
							height: 100%;
							background-image: url('../assets/040.png');
							background-size: 100% 100%;
						}
					}

					.label {
						margin-left: 10px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 16px;
					}
				}

				.card-header {
					display: flex;
					align-items: center;
					height: 56px;
					border-bottom: 1px solid #EDEDED;

					.ch-headingbox {
						position: relative;

						.chh-text {
							position: relative;
							z-index: 2;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							line-height: 16px;
						}

						.chh-line {
							position: absolute;
							left: 0;
							bottom: -3px;
							width: 120%;
							height: 7px;
							background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
							border-radius: 0px 0px 0px 0px;
						}
					}
					
					.ch-tip {
						height: 34px;
						// background: #FC474C;
						border-radius: 4px 4px 4px 4px;
						opacity: 1;
						// border: 1px solid #FC474C;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #FC474C;
						line-height: 32px;
						text-align: center;
						padding: 0 20px;
						margin-left: 20px;
					}

					.ch-right {
						display: flex;
						margin-left: auto;

						.cbr-add-btn {
							margin-left: 10px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 84px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							// border: 1px solid #3571DA;
							box-sizing: border-box;
							background-color: #3571DA;

							&.del {
								background-color: #EE0000;
							}

							.cbra-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/059.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}

							.cbra-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
						}

						.chr-number {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;

							.highlight {
								color: #3571DA;
							}
						}
					}
				}

				.card {
					box-sizing: border-box;
					padding: 0 20px;
					background-color: #FFFFFF;
					overflow: hidden;
					border-radius: 8px;
				}

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0;

					&.leixing {
						margin-bottom: 0;
						align-items: flex-start;
					}

					&.xiadan-moshi {
						margin-top: 0;
					}

					&.kuaisuXiadan {
						background-color: #F6F8FC;
						padding: 10px 0;
					}

					&.uploadPic {
						align-items: flex-start;
					}

					&.shangpinBeizhu {
						align-items: flex-start;
					}

					&.shibieDizhi {
						flex-direction: column;
						align-items: flex-start;

						.cbr-label {
							width: initial;
							margin-bottom: 10px;
						}

						.cbr-content {
							flex-direction: column;
							align-items: flex-start;
						}
					}

					.user-info {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #999999;

						.ui-edit {
							margin-left: 10px;
							color: #3571DA;
						}
					}

					.cbr-tip {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FF6939;
					}

					.cbr-label {
						width: 140px;
						display: flex;
						justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						align-items: center;

						.weixiu-remark {
							width: 976px;
							min-height: 32px;
							background: #FFF4CF;
							border-radius: 0px 0px 0px 0px;
							padding: 5px 10px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 400;
							font-size: 14px;
							color: #E75300;
							box-sizing: border-box;
							line-height: 22px;
						}

						.cbr-chaijiu {
							display: flex;
							align-items: center;
							margin-bottom: 20px;

							.cbrc-text {
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								font-size: 14px;
								color: #333333;
								line-height: 14px;
							}

							.input-number-box {
								margin: 0 10px;
							}
						}

						.cbrc-haopinTip {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #999999;
						}

						.cbr-shibie {
							width: 112px;
							height: 40px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 40px;
							text-align: center;
							margin-top: 10px;
						}

						/deep/.input-number-box {
							display: flex;
							align-items: center;

							.el-input-number {
								display: flex;
								align-items: center;
								width: auto;

								.el-input-number__decrease {
									position: relative;
									width: 32px;
									height: 32px;
									border: none;
									top: 0;

									&::after {
										content: "";
										width: 32px;
										height: 32px;
										display: block;
										background: url("../assets/060.png") no-repeat center;
										background-size: 32px 32px;
										position: absolute;
										top: 0;
										left: 0;
									}
								}

								.el-input {
									height: 32px;
									width: 72px;
									margin: 0 4px 0;

									.el-input__inner {
										padding: 0;
										position: absolute;
										border: 1px solid #CED4DB;
										box-sizing: border-box;
										height: 32px;
										width: 72px;
									}
								}

								.el-input-number__increase {
									position: relative;
									order: 1;
									width: 32px;
									height: 32px;
									border: none;
									top: 0;

									&::after {
										content: "";
										width: 32px;
										height: 32px;
										display: block;
										background: url("../assets/061.png") no-repeat center;
										background-size: 32px 32px;
										position: absolute;
										top: 0;
										left: 0;
									}
								}
							}

							.ib-unit {
								margin-left: 10px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}
						}

						.price-box {
							display: flex;
							align-items: baseline;
							margin-left: 30px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;

							.pb-label {}

							.pb-number {
								font-family: Microsoft YaHei-Bold;
								font-weight: bold;
								color: #FF2121;
							}
						}

						.select-btn {
							height: 32px;
							background: #FFFFFF;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #CED4DB;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #2F3133;
							line-height: 14px;
							display: flex;
							align-items: center;
							padding: 0 20px;
							box-sizing: border-box;
							margin-right: 10px;

							&.active {
								background-color: #3571DA;
								border-color: #3571DA;
								color: #FFFFFF;
							}
						}

						.cbr-add-btn {
							margin-left: 10px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 84px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							// border: 1px solid #3571DA;
							box-sizing: border-box;
							background-color: #3571DA;

							&.del {
								background-color: #EE0000;
							}

							.cbra-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/059.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}

							.cbra-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
						}

						.kuaisu-xiadan {
							display: flex;

							.kx-item {
								width: 80px;
								height: 80px;
								background: #FFFFFF;
								border-radius: 8px 8px 8px 8px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								position: relative;
								margin-right: 20px;

								.kxi-pic {
									width: 32px;
									height: 32px;
								}

								.kxi-title {
									margin-top: 10px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								.kxi-close {
									width: 16px;
									height: 16px;
									background-image: url('../assets/058.png');
									background-size: 100% 100%;
									position: absolute;
									top: 0;
									right: 0;
									transform: translate(50%, -50%);
								}
							}
						}

						.cbrc-submit {
							width: 88px;
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 32px;
							text-align: center;
						}

						.input-wrap {
							display: flex;
							align-items: center;

							.iw-label {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;
								margin-right: 10px;
							}

							.iw-unit {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;
								margin: 0 60px 0 10px;
							}
						}

						.leimu-list {
							display: flex;
							flex-wrap: wrap;

							.ll-item {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								width: 80px;
								height: 80px;
								background-color: #F6F8FC;
								border-radius: 8px;
								margin-right: 20px;
								margin-bottom: 20px;

								.lli-icon {
									width: 32px;
									height: 32px;
									margin-bottom: 10px;
									background-image: url('../assets/050.png');
									background-size: 100% 100%;
								}

								.lli-text {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								&.active {
									background-color: #3571DA;

									.lli-icon {
										background-image: url('../assets/049.png');
									}

									.lli-text {
										color: #FFFFFF;
									}
								}
							}
						}

						.form-item {}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
								margin-right: 10px;
							}

							&.type-2 {
								width: 136px;
								margin-right: 10px;
							}

							&.type-3 {
								width: 300px;
							}

							&.type-4 {
								width: 180px;
							}

							&.type-5 {
								width: 300px;
								margin-right: 10px;
							}

							&.type-6 {
								width: 300px;
								margin-right: 10px;
							}

							&.type-7 {
								width: 170px;
							}

							&.type-8 {
								margin-left: 10px;
								width: 120px;
							}

						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.type-1 {
								width: 216px;
							}

							&.type-2 {
								width: 300px;
							}

							&.type-3 {
								width: 180px;
							}

							&.type-4 {
								width: 300px;
								margin-right: 10px;
							}

							&.type-5 {
								width: 580px;
								margin-right: 10px;
							}
						}

						/deep/.datetime {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 25px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.type-1 {
								width: 300px;
							}
						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 980px;
							}

							&.type-2 {
								width: 580px;
							}
						}

						.cbr-search-btn {
							display: flex;
							align-items: center;
							width: 84px;
							height: 32px;
							background-color: #3571DA;
							border-radius: 4px;
							justify-content: center;

							.cbrs-icon {
								background-image: url('../assets/047.png');
								background-size: 100% 100%;
								width: 14px;
								height: 14px;
								margin-right: 10px;
							}

							.cbrs-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
						}

						.yikoujia-wrap {
							display: flex;

							/deep/.el-radio {
								.el-radio__input {
									display: none;
								}

								.el-radio__label {
									padding-left: 0;
								}
							}

							.yikoujia {
								width: 160px;
								height: 60px;
								border-radius: 8px;
								background-color: #F6F8FC;
								display: flex;
								justify-content: center;
								align-items: center;

								.yk-icon {
									width: 32px;
									height: 32px;
									background-image: url('../assets/057.png');
									background-size: 100% 100%;
									margin-right: 10px;
								}

								.yk-text {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
									line-height: 12px;
								}
							}
						}

						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #CED4DB;
							box-sizing: border-box;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							margin-right: 10px;

							&.pic {
								width: auto;
								display: flex;
								flex-direction: row;
								border: none;
								background: none;
							}

							/deep/.Media {
								margin-left: -10px;
								margin-top: -20px;
							}

							/deep/.image-box {
								width: 100px;
								height: 100px;
								box-sizing: border-box;
								padding: 4px;
								position: relative;
								border: 1px dashed #3571DA;
								margin-left: 10px;

								// margin-top: 10px;
								.image {
									width: 90px;
									height: 90px;
									border-radius: 4px;
									display: block;
									// margin-top: 4px;
								}

								.ui-tip {
									width: 100%;
									height: 20px;
									background: rgba(#000000, 0.4);
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									font-size: 12px;
									color: #FFFFFF;
									line-height: 20px;
									text-align: center;
									font-style: normal;
									transform: translateY(-100%);
								}

								.video-play {
									position: absolute;
									top: 4px;
									left: 4px;
									width: 90px;
									height: 90px;
									background-color: rgba(0, 0, 0, 0.4);
									display: flex;
									justify-content: center;
									align-items: center;

									.vp-icon {
										width: 30px;
										height: 30px;
										background-image: url(../assets/083.png);
										background-size: 100% 100%;
									}
								}
							}

							.ui-pic-wrap {
								width: 98px;
								height: 98px;
								box-sizing: border-box;
								padding: 4px;
								position: relative;

								.ui-pic {
									width: 100%;
									height: 100%;
									display: block;
								}

								.ui-tip {
									width: 100%;
									height: 20px;
									background: rgba(#000000, 0.4);
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									font-size: 12px;
									color: #FFFFFF;
									line-height: 20px;
									text-align: center;
									font-style: normal;
									transform: translateY(-100%);
								}
							}

							.ui-icon {
								width: 24px;
								height: 24px;
								background-image: url('../assets/052.png');
								background-size: 100% 100%;
								margin-bottom: 8px;
							}

							.ui-text {
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 12px;
							}

							&:nth-child(1) {
								.ui-icon {
									background-image: url('../assets/051.png');
								}
							}
						}

						.group-box {
							align-self: flex-start;
							margin-left: auto;
							display: flex;
							align-items: center;

							.gb-text {
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
							}

							.gb-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/062.png');
								background-size: 100% 100%;
								margin-left: 10px;
							}
						}
					}
				}

				.fuwu-leimu {
					background-color: #FFFFFF;

				}

				.shangpin-xinxi {
					margin-top: 20px;

					.empty-wrap {
						min-height: 200px;
					}
				}

				.kehu-xinxi {
					margin-top: 20px;

					.kx-content {
						display: flex;

						.kxc-left {}

						.kxc-right {
							flex: 1;
							margin-left: 50px;
						}
					}
				}

				.wuliu-xinxi {
					margin-top: 20px;

					.wuliuXinxi {
						display: flex;
						margin: 20px 0;

						.cb-row {
							margin: 0;
						}

						.cbr-add-btn {
							margin-left: 10px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 84px;
							height: 32px;
							border-radius: 4px 4px 4px 4px;
							// border: 1px solid #3571DA;
							box-sizing: border-box;
							background-color: #3571DA;

							&.del {
								background-color: #EE0000;
							}

							.cbra-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/059.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}

							.cbra-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
						}
					}
				}

				.dindan-lianxiren {
					margin-top: 20px;

					.dindanLianxiren {
						display: flex;
						align-items: center;
						margin: 20px 0;

						.cb-row {
							margin: 0;
						}
					}
				}

				.qita-fuwu {
					margin-top: 20px;
				}
			}

			.c-footer {}
		}

		.mul-checkbox {
			display: flex;
			align-items: center;

			.checkbox {
				width: 18px;
				height: 18px;

				.no {
					width: 100%;
					height: 100%;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #CDCDCD;
					box-sizing: border-box;
				}

				.yes {
					width: 100%;
					height: 100%;
					background-image: url('../assets/039.png');
					background-size: 100% 100%;
				}
			}

			.label {
				margin-left: 10px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 18px;
			}
		}

		.orderCreate-footer {
			background: #FFFFFF;
			z-index: 999;
			background-color: #FFFFFF;
			position: fixed;
			bottom: 0;

			.orderCreate-footer-content {
				display: flex;
				align-items: center;
				height: 90px;

				.product-add-btn {
					width: 207px;
					height: 48px;
					background: #FC474C;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #FC474C;
					box-sizing: border-box;
					font-size: 18px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 46px;
					text-align: center;
				}

				.of-right {
					margin-left: auto;
					display: flex;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 14px;

					.ofr-container {
						display: flex;
						align-items: center;
					}

					.ofr-text-1 {
						margin-left: 10px;
					}

					.ofr-text-2 {
						margin-left: 5px;
					}

					.ofr-text-3 {
						margin: 0 5px;
					}

					.ofr-text-4 {}

					.ofr-text-5 {
						margin-left: 20px;
					}

					.ofr-text-6 {
						color: #FC474C;
					}

					.ofr-text-7 {
						font-size: 18px;
						font-family: Microsoft YaHei-Bold;
						font-weight: bold;
						color: #FC474C;
					}

					.ofr-arrow {
						width: 16px;
						height: 16px;
						background-image: url('../assets/056.png');
						background-size: 100% 100%;
					}

					.submit-btn {
						margin-left: 20px;
						width: 207px;
						height: 48px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 18px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 48px;
						text-align: center;
					}
				}
			}
		}
	}
</style>