<template>
	<div class="foot" id="foot">
		<div class="foot-content-wrap L">
			<div class="foot-content M">
				<img :src="imgUrl(pc.dibulogo)" class="fc-logo" alt="" />
				<div class="fc-middle">
					<div class="fcm-phone">{{pc.lianxihaoma}}</div>
					<div class="fcm-date">工作日 {{pc.gongzuori}}</div>
					<div class="fcm-field">
						<span class="fcmf-label">邮箱：</span>
						<span class="fcmf-value">{{pc.email}}</span>
					</div>
					<div class="fcm-field">
						<span class="fcmf-label">地址：</span>
						<span class="fcmf-value">{{pc.address}}</span>
					</div>
				</div>
				<div class="fc-qrcode">
					<div class="fcq-item">
						<img :src="imgUrl(pc.xiaochengxuma)" class="fcqi-qrcode" alt="" />
						<div class="fcqi-label">客服端小程序</div>
					</div>
				</div>
			</div>
		</div>
		<div class="foot-bottom-wrap L">
			<div class="foot-bottom M">{{pc.copyright}} {{pc.companyname}}版权所有 {{pc.icp}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Foot',
		props: {
			msg: String
		},
		data() {
			return {
			}
		},
		methods: {

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.foot {
		width: 100%;
		margin-top: auto;
		.foot-content-wrap {
			background-color: #4D4D4D;
			.foot-content {
				box-sizing: border-box;
				padding: 70px 50px 0;
				display: flex;
				align-items: flex-start;
				min-height: 324px;
				.fc-logo {
					width: 140px;
					height: auto;
					object-fit: contain;
				}
				.fc-middle {
					flex: 1;
					padding: 0 0 0 55px;
					display: flex;
					flex-direction: column;
					.fcm-phone {
						font-size: 44px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 44px;
					}
					.fcm-date {
						margin-top: 20px;
						font-size: 24px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 24px;
						margin-bottom: 40px;
					}
					.fcm-field {
						font-size: 18px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 18px;
						margin-bottom: 20px;
						.fcmf-label {}
						.fcmf-value {}
					}
				}
				.fc-qrcode {
					display: flex;
					.fcq-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 0 30px;
						.fcqi-qrcode {
							width: 120px;
							height: 120px;
						}
						.fcqi-label {
							margin-top: 20px;
							font-size: 18px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 18px;
						}
					}
				}
			}
		}
		.foot-bottom-wrap {
			background-color: #434343;
			height: 48px;
			.foot-bottom {
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 48px;
				text-align: center;
			}
		}
	}
</style>