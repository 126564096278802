<template>
	<div>
		<div v-if="model == 'custom'" @click="openDialog(fileList[0],preview)">
			<slot></slot>
		</div>
		<div class="Media" :class="mediaClass" v-else>
			<template v-for="(item,index) in fileList">
				<div class="image-box" v-if="index < limit" :key="index" @click="openDialog(item,preview)">
					<slot>
						<template v-if="item.type == 'picture'">
							<el-image :src="item.url" alt="" :fit="fit" class="image"
								:preview-src-list="fileList.map(v => v.url)" :initial-index="index" @click.stop="" />
						</template>
						<template v-if="item.type == 'video'">
							<el-image :src="item.url" alt="" :fit="fit" class="image" />
							<div class="video-play pointer">
								<div class="vp-icon"></div>
							</div>
						</template>
					</slot>
					<slot name="ext" v-bind:data="{item:item, index,index}"></slot>
				</div>
			</template>
			<div v-if="!fileList.length" class="image-box">
				<el-image class="image">
					<!-- <i class="el-icon-picture-outline"></i> -->
					<div slot="error" class="no-pic">暂无图片</div>
				</el-image>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" :append-to-body="true" :lock-scroll="false">
			<div class="preview-box">
				<div class="preview">
					<div class="pv-title">预览{{previewFile[0].fileName ? `(${previewFile[0].fileName})`:''}}</div>
					<div class="pv-close" @click="closeDialog"></div>
				</div>
				<video class="video" controls="controls" :key="previewFile[0].value">
					<source :src="imgUrl(previewFile[0].value)" />
				</video>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="mediaListVisible" :lock-scroll="false">
			<div class="mediaList">
				<div class="pop-header">
					<div class="ph-title">预览</div>
					<div class="ph-close" @click="closeMediaListDialog"></div>
				</div>
				<div class="pop-body">
					<div class="Media" :class="mediaClass">
						<template v-for="(item,index) in fileList">
							<div class="image-box" :key="index">
								<el-image v-if="item.type == 'picture'" :src="item.url" alt="" :fit="fit" class="image"
									:preview-src-list="fileList.map(v => v.url)" :initial-index="index"
									@click.stop="" />
								<template v-if="item.type == 'video'">
									<el-image :src="item.url" alt="" :fit="fit" class="image" />
									<div class="video-play pointer" @click="openDialog(item,'default')">
										<div class="vp-icon"></div>
									</div>
								</template>
								<div class="image-tip txt-ellipsis-1" v-if="item[fileName]">{{item[fileName]}}</div>
							</div>
						</template>
					</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="closeMediaListDialog">关闭</div>
				</div>
			</div>
		</el-dialog>

		<image-viewer v-if="showViewer" :z-index="999999" :initial-index="previewIndex" :appendToBody="false"
			:on-close="closeViewer" :url-list="previewFile" />
	</div>
</template>

<script>
	import config from "../common/config.js";
	import ImageViewer from 'element-ui/packages/image/src/image-viewer';
	export default {
		name: 'Media',
		components: {
			ImageViewer
		},
		props: {
			value: [String, Object, Array],
			mediaClass: {
				type: String,
				default: 'w120h120g20b4'
			},
			fit: {
				type: String,
				default: 'cover'
			},
			keyName: {
				type: String,
				default: ''
			},
			fileName: {
				type: String,
				default: 'fileName'
			},
			limit: { //all 展示全部图 first 展示第一张图
				type: [String, Number],
				default: Infinity
			},
			preview: {
				type: [String],
				default: 'default'
			},
			model: {
				type: [String],
				default: ''
			}

		},
		data() {
			return {
				previewFile: [{}],
				dialogVisible: false,
				fileList: [],
				videoType: ['mp4', 'avi', 'wmv'],
				imageType: ["png", "jpg", "jpeg"],
				platform: '', // backstage 后台 aliOss 阿里云
				mediaListVisible: false,
				showViewer: false,
				previewIndex: 0,

			};
		},
		computed: {
			type() {
				if (Array.isArray(this.image)) {
					console.log('this.image: ', this.image);
					return 'Array'
				} else {
					return 'String'
				}
			},
			imgObject() {
				let obj = {
					width: this.width + 'px',
					height: this.height + 'px',
					marginLeft: this.gap + 'px',
					marginTop: this.gap + 'px',
					borderRadius: this.borderRadius + 'px'
				}
				return obj
			},
		},
		watch: {
			value: {
				handler(val) {
					if (config.imgRoot.endsWith('aliyuncs.com')) {
						this.platform = 'aliOss'
					} else {
						this.platform = 'backstage'
					}
					if (val) {
						// 首先将值转为数组
						const list = Array.isArray(val) ? val : this.value.split(',');
						// 然后将数组转为对象数组
						this.fileList = list.map(item => {
							//文件名操作
							// 1.//获取文件后缀
							// var suffix = "文件路径".substring("文件路径".lastIndexOf("."));
							// 2.//获取文件名
							// var fileName = "文件路径".substring("文件路径".lastIndexOf("/")+1);//或\

							if (typeof item === "string") {
								item = {
									name: item.substring(item.lastIndexOf("/") + 1),
									value: this.imgUrl(item),
									type: '',
									url: ''
								};
							} else { // 对象
								item = {
									name: item[this.keyName].substring(item[this.keyName].lastIndexOf("/") +
										1),
									value: this.imgUrl(item[this.keyName]),
									type: '',
									url: '',
									...item
								};
							}


							if (this.videoType.some(s => item.value.endsWith(s))) {
								console.log('this.platform: ', this.platform);
								if (this.platform == 'aliOss') {
									item.url = item.value +
										'?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
								} else {
									item.url = item.value
								}
								item.type = 'video'
							} else if (this.imageType.some(s => item.value.endsWith(s))) {
								item.url = item.value
								item.type = 'picture'
							} else {
								item.url = item.value
								item.type = 'file'
							}
							return item;
						});
						// console.log('this.fileList: ',this.fileList);
					} else {
						this.fileList = [];
						return [];
					}
				},
				deep: true,
				immediate: true
			}
		},
		created() {

		},
		methods: {
			// 预览
			openDialog(file, preview) {
				if (preview == 'default') {
					if (file.type == 'file') {
						window.open(file.url)
					} else if (file.type == 'picture') {
						this.previewFile = this.fileList.map(v => v.url)
						let index = this.fileList.findIndex(v => v.url == file.url)
						if (index > -1) {
							this.previewIndex = index
						} else {
							this.previewIndex = 0
						}
						console.log('index: ', index);
						this.showViewer = true
					} else {
						this.previewFile = [file]
						this.dialogVisible = true;
					}
				}
				if (preview == 'mediaList') {
					this.mediaListVisible = true
				}

			},
			closeDialog() {
				this.dialogVisible = false
			},
			closeMediaListDialog() {
				this.mediaListVisible = false
			},
			closeViewer() {
				this.showViewer = false;
			},


		}
	};
</script>
<style lang="scss" scoped>
	::v-deep .no-pic {
		width: 100%;
		height: 100%;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #C0C4CC;
		vertical-align: middle;
		background: #F5F7FA;
	}
	
	.Media {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-top: -20px;

		.image-tip {
			width: 100%;
			height: 20px;
			background: rgba(#000000, 0.4);
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 12px;
			color: #FFFFFF;
			line-height: 20px;
			text-align: center;
			font-style: normal;
			position: absolute;
			bottom: 0;
			// transform: translateY(-100%);
		}

		&.none {
			display: flex;
			flex-wrap: wrap;
			margin-left: 0;
			margin-top: 0;
		}

		&.w100h100g20b4 {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-top: -20px;

			.image-box {
				width: 100px;
				height: 100px;
				margin-left: 20px;
				margin-top: 20px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				.image {
					width: 100px;
					height: 100px;
					border-radius: 4px;
				}

				.video-play {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					display: flex;
					justify-content: center;
					align-items: center;

					.vp-icon {
						width: 30px;
						height: 30px;
						background-image: url(../assets/083.png);
						background-size: 100% 100%;
					}
				}
			}
		}

		&.w120h120g20b4 {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-top: -20px;

			.image-box {
				width: 120px;
				height: 120px;
				margin-left: 20px;
				margin-top: 20px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				.image {
					width: 120px;
					height: 120px;
					border-radius: 4px;
				}

				.video-play {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					display: flex;
					justify-content: center;
					align-items: center;

					.vp-icon {
						width: 30px;
						height: 30px;
						background-image: url(../assets/083.png);
						background-size: 100% 100%;
					}
				}
			}
		}

		&.w92h92g20b4 {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-top: -20px;

			.image-box {
				width: 92px;
				height: 92px;
				margin-left: 20px;
				margin-top: 20px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				.image {
					width: 92px;
					height: 92px;
					border-radius: 4px;
				}

				.video-play {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					display: flex;
					justify-content: center;
					align-items: center;

					.vp-icon {
						width: 30px;
						height: 30px;
						background-image: url(../assets/083.png);
						background-size: 100% 100%;
					}
				}
			}
		}

		&.w120h90g20b4 {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-top: -20px;

			.image-box {
				width: 120px;
				height: 90px;
				margin-left: 20px;
				margin-top: 20px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				.image {
					width: 120px;
					height: 90px;
					border-radius: 4px;
				}

				.video-play {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					display: flex;
					justify-content: center;
					align-items: center;

					.vp-icon {
						width: 30px;
						height: 30px;
						background-image: url(../assets/083.png);
						background-size: 100% 100%;
					}
				}
			}
		}

		&.w160h120g12b4 {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-top: -20px;

			.image-box {
				width: 160px;
				height: 120px;
				margin-left: 12px;
				margin-top: 12px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;

				.image {
					width: 160px;
					height: 120px;
					border-radius: 4px;
				}

				.video-play {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					display: flex;
					justify-content: center;
					align-items: center;

					.vp-icon {
						width: 30px;
						height: 30px;
						background-image: url(../assets/083.png);
						background-size: 100% 100%;
					}
				}
			}
		}
	}

	.preview-box {
		.preview {
			background-color: #FFFFFF;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px 10px 0 0;

			.pv-title {
				color: #333333;
				font-size: 16px;
			}

			.pv-close {
				width: 17px;
				height: 17px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
				margin-right: 10px
			}

		}

		.video {
			background-color: #000000;
			display: block;
			width: 700px;
			height: 500px;
			margin: 0 auto;
		}
	}

	.mediaList {
		width: 600px;
		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 20px 20px 20px 0;
			display: flex;
			flex-direction: column;
			max-height: 60vh;
			overflow: auto;
			margin-right: -20px;


		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>