<template>
	<div class="zhangHaoInfo">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">账号安全</div>
					<div class="chh-line"></div>
				</div>
			</div>
			<div v-loading="isLoading">
				<div v-show="!isEdit">
					<div class="c-body-info">
						<div class="cb-row">
							<div class="cbr-label">用户类型：</div>
							<div class="cbr-content">
								<div class="cbrc-text">厂商</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">用户昵称：</div>
							<div class="cbr-content">
								<div class="form-item">
									{{detail.name}}
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">公司名称：</div>
							<div class="cbr-content">
								<div class="form-item">
									{{detail.company}}
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label" style="align-self: flex-start;">营业执照：</div>
							<div class="cbr-content">
								<Media mediaClass="w100h100g20b4" :value="detail.license"></Media>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">联系人：</div>
							<div class="cbr-content">
								<div class="form-item">
									{{detail.contacts}}
								</div>
							</div>
						</div>
						<div class="cb-row" style="align-items: flex-start;">
							<div class="cbr-label" >技术指导员：</div>
							<div class="cbr-content " style="display: flex;flex-direction: column;">
								<div class="jishuzhidaoyuan"
									v-for="(technicalUserVoListitem,technicalUserVoListindex) in detail.technicalUserVoList"
									:key="technicalUserVoListindex"
									>
									<div class="form-item type-1-wrap">
										{{technicalUserVoListitem.name}}
									</div>
									<div class="form-item type-1-wrap">
										{{technicalUserVoListitem.phone}}
									</div>
								</div>
							</div>
						</div>
						<div class="address-list"
							v-for="(umsUserAddressVoListitem,umsUserAddressVoListindex) in detail.umsUserAddressList"
							:key="`address-list-${umsUserAddressVoListindex}`">
							<div class="address-item">
								<div class="cb-row">
									<div class="cbr-label">收货地址{{umsUserAddressVoListindex+1}}：</div>
									<div class="cbr-content">
										<div v-if="umsUserAddressVoListitem.paymentType == 0">垫付</div>
										<div v-if="umsUserAddressVoListitem.paymentType == 1">到付</div>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<div class="form-item type-1-wrap">
											{{umsUserAddressVoListitem.realName}}
										</div>
										<div class="form-item type-1-wrap">
											{{umsUserAddressVoListitem.phone}}
										</div>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<div class="form-item type-1-wrap">
											{{`${umsUserAddressVoListitem.provinceName}/${umsUserAddressVoListitem.cityName}/${umsUserAddressVoListitem.districtName}`}}
										</div>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<div class="form-item type-1-wrap">
											{{umsUserAddressVoListitem.detailAddress}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"></div>
							<div class="cbr-content">
								<div class="btn btn-fill" @click="isEdit = true">
									<div class="ab-text" >编辑</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-show="isEdit">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="c-body">
						<div class="cb-row">
							<div class="cbr-label">用户类型：</div>
							<div class="cbr-content">
								<div class="cbrc-text">厂商</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">用户昵称：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.name" class="input type-1" placeholder="请输入"
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">公司名称：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.company" class="input type-1" placeholder="请输入"
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label" style="align-self: flex-start;">营业执照：</div>
							<div class="cbr-content">
								<FileUpload class="upload-demo" className="w100h100" v-model="ruleForm.license" :limit="1" dir="licence">
									<div class="upload-item">
										<div class="zu-icon"></div>
										<div class="zu-label">上传图片</div>
									</div>
								</FileUpload>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label">联系人：</div>
							<div class="cbr-content">
								<el-form-item prop="" class="form-item">
									<el-input v-model="ruleForm.contacts" class="input type-1" placeholder="请输入"
										clearable></el-input>
								</el-form-item>
							</div>
						</div>
						<div class="cb-row" style="align-items: flex-start;">
							<div class="cbr-label" style="line-height: 32px;">技术指导员：</div>
							<div class="cbr-content " style="display: flex;flex-direction: column;">
								<div class="jishuzhidaoyuan"
									v-for="(technicalUserVoListitem,technicalUserVoListindex) in ruleForm.technicalUserVoList"
									:key="technicalUserVoListindex"
									>
									<el-form-item prop="" class="form-item type-1-wrap">
										<el-input v-model="technicalUserVoListitem.name" class="input type-1"
											placeholder="请输入姓名" clearable></el-input>
									</el-form-item>
									<el-form-item prop="" class="form-item type-1-wrap">
										<el-input v-model="technicalUserVoListitem.phone" class="input type-1"
											placeholder="请输入手机号" clearable></el-input>
									</el-form-item>
									<div class="btn type-1 pointer"
										@click="technicalUserVoListitemAdd(technicalUserVoListitem,technicalUserVoListindex)">
										<div class="ab-icon"></div>
										<div class="ab-text">添加</div>
									</div>
									<div class="btn type-2 pointer" v-if="ruleForm.technicalUserVoList.length > 1"
										@click="technicalUserVoListitemDel(technicalUserVoListitem,technicalUserVoListindex)">
										<!-- <div class="ab-icon"></div> -->
										<div class="ab-text">删除</div>
									</div>
								</div>
							</div>
						</div>
						<div class="address-list"
							v-for="(umsUserAddressVoListitem,umsUserAddressVoListindex) in ruleForm.umsUserAddressVoList"
							:key="`address-list-${umsUserAddressVoListindex}`">
							<div class="address-item">
								<div class="cb-row">
									<div class="cbr-label">收货地址{{umsUserAddressVoListindex+1}}：</div>
									<div class="cbr-content">
										<el-radio v-model="umsUserAddressVoListitem.paymentType" label="0">垫付</el-radio>
										<el-radio v-model="umsUserAddressVoListitem.paymentType" label="1">到付</el-radio>
										<div class="btn type-1 pointer"
											@click="umsUserAddressVoListitemAdd(umsUserAddressVoListitem,umsUserAddressVoListindex)">
											<div class="ab-icon"></div>
											<div class="ab-text">添加</div>
										</div>
										<div class="btn type-2 pointer" v-if="ruleForm.technicalUserVoList.length > 1"
											@click="umsUserAddressVoListitemDel(umsUserAddressVoListitem,umsUserAddressVoListindex)">
											<!-- <div class="ab-icon"></div> -->
											<div class="ab-text">删除</div>
										</div>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<el-form-item prop="" class="form-item type-1-wrap">
											<el-input v-model="umsUserAddressVoListitem.realName" class="input type-1"
												placeholder="请输入联系人姓名" clearable></el-input>
										</el-form-item>
										<el-form-item prop="" class="form-item type-1-wrap">
											<el-input v-model="umsUserAddressVoListitem.phone" class="input type-1"
												placeholder="请输入联系人电话" clearable></el-input>
										</el-form-item>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<el-form-item prop="" class="form-item type-1-wrap">
											<el-cascader class="select type-1"
												:ref="`refSubCat${umsUserAddressVoListindex}`"
												v-model="umsUserAddressVoListitem.listProvinceCityValue"
												:options="listProvinceCity" clearable :props="{ expandTrigger: 'hover' }"
												@change="listProvinceCityChange($event,umsUserAddressVoListitem,umsUserAddressVoListindex)">
											</el-cascader>
										</el-form-item>
									</div>
								</div>
								<div class="cb-row">
									<div class="cbr-label"></div>
									<div class="cbr-content">
										<el-form-item prop="" class="form-item type-1-wrap">
											<el-input v-model="umsUserAddressVoListitem.detailAddress" class="textarea textarea-1" type="textarea" :show-word-limit="true"
												:maxlength="400" :rows="2" placeholder="请输入详细地址" clearable></el-input>
										</el-form-item>
									</div>
								</div>
							</div>
						</div>
						<div class="cb-row">
							<div class="cbr-label"></div>
							<div class="cbr-content">
								<div class="btn btn-fill" @click="submit">
									<div class="ab-text" >保存</div>
								</div>
								<div class="btn type-3 btn-fill" @click="isEdit = false">
									<div class="ab-text" >关闭</div>
								</div>
							</div>
						</div>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		UserGetManufacturerInfo,
		UserEditManufacturerInfo,
		ListProvinceCity
	} from '../common/api.js'
	var technicalUserVoList = {
		name: '', // 姓名
		phone: '', // 手机号
	}
	var umsUserAddressVoList = {
			cityId: '', // 城市ID
			cityName: '', // 城市名称
			detailAddress: '', // 详细地址
			districtId: '', // 区ID
			districtName: '', // 区名称
			phone: '', // 收货人手机
			provinceId: '', // 省ID
			provinceName: '', // 省名称
			realName: '', // 收货人名称
			defaultFlag: '', // 是否默认;0=非默认 1=默认
			id: '', // id
			paymentType: '', // 付款类型;0=垫付 1=到付
			listProvinceCityValue: [], // 
		}
		export default {
			data() {
				return {
					licence: '',
					options: [{
						value: '选项1',
						label: '黄金糕'
					}, {
						value: '选项2',
						label: '双皮奶'
					}, {
						value: '选项3',
						label: '蚵仔煎'
					}, {
						value: '选项4',
						label: '龙须面'
					}, {
						value: '选项5',
						label: '北京烤鸭'
					}],
					value: '',

					ruleForm: {
						company: '', // 公司名称
						contacts: '', // 联系人
						license: '', // 营业执照
						name: '', // 姓名
						avatar: '', // 头像
						technicalUserVoList: [{...technicalUserVoList}], // 技术手机号
						umsUserAddressVoList: [{...umsUserAddressVoList}], // 收货地址
					},
					rules: {},

					listProvinceCity: [],
					isEdit: false,
					detail: {},
					
					isLoading: true,

				}
			},
			created() {
				this.getUserGetManufacturerInfo()
				this.getListProvinceCity()
			},
			mounted() {

			},
			methods: {
				submit() {
					let data = {
						company: this.ruleForm.company, // 公司名称
						contacts: this.ruleForm.contacts, // 联系人
						license: this.ruleForm.license, // 营业执照
						name: this.ruleForm.name, // 姓名
						avatar: this.ruleForm.avatar, // 头像
						technicalUserVoList: [], // 技术手机号
						umsUserAddressVoList: [], // 收货地址
					}

					this.ruleForm.technicalUserVoList.forEach(v => {
						for (let x in v) {
							if (v[x]) {
								data.technicalUserVoList.push(v)
								break
							}
						}
					})

					this.ruleForm.umsUserAddressVoList.forEach(v => {
						for (let x in v) {
							if (v[x] && v[x].length) {
								data.umsUserAddressVoList.push(v)
								break
							}
						}
					})

					console.log('data: ', data);

					UserEditManufacturerInfo(data).then(res => {
						this.getUserGetManufacturerInfo()
						this.toast({
							message: '保存成功',
							type: 'success'
						})
						// this.$rootApp.$refs.PopTip.open({
						// 	content: '提交成功，是否继续编辑？',
						// 	type: 'success',
						// 	confirmText: '继续编辑',
						// 	confirm: () => {
						// 		this.isEdit = true
						// 	},
						// 	cancel: () => {
						// 		this.isEdit = false
						// 	}
						// })
					})
				},
				// 获取厂商账号信息
				getUserGetManufacturerInfo() {
					UserGetManufacturerInfo().then(res => {
						this.detail = res.data
						this.ruleForm.company = res.data.company || '' // 公司名称
						this.ruleForm.contacts = res.data.contacts || '' // 联系人
						this.ruleForm.license = res.data.license || '' // 营业执照
						this.ruleForm.name = res.data.name || '' // 姓名
						this.ruleForm.avatar = res.data.avatar || '' // 头像


						if (res.data.technicalUserVoList && res.data.technicalUserVoList.length) {
							this.ruleForm.technicalUserVoList = res.data.technicalUserVoList
						} else {
							this.ruleForm.technicalUserVoList = [{...technicalUserVoList}]
						}

						if (res.data.umsUserAddressList && res.data.umsUserAddressList.length) {
							this.ruleForm.umsUserAddressVoList = res.data.umsUserAddressList.map(v => {
								v.paymentType = v.paymentType.toString()
								v.listProvinceCityValue = [v.provinceId,v.cityId,v.districtId]
								return v
							})
						} else {
							this.ruleForm.umsUserAddressVoList = [{...umsUserAddressVoList}]
						}

						console.log('this.ruleForm: ', this.ruleForm);


					}).finally(() => {
						this.isLoading = false
					})
				},
				getListProvinceCity() {
					ListProvinceCity().then(res => {
						this.listProvinceCity = res.data
					})
				},
				// 添加技术指导员
				technicalUserVoListitemAdd(technicalUserVoListitem, technicalUserVoListindex) {
					this.ruleForm.technicalUserVoList.splice(technicalUserVoListindex+1,0,{...technicalUserVoList})
				},
				// 删除技术指导员
				technicalUserVoListitemDel(technicalUserVoListitem, technicalUserVoListindex) {
					this.ruleForm.technicalUserVoList.splice(technicalUserVoListindex,1)
				},
				// 添加收货地址
				umsUserAddressVoListitemAdd(umsUserAddressVoListitem, umsUserAddressVoListindex) {
					this.ruleForm.umsUserAddressVoList.splice(umsUserAddressVoListindex+1,0,{...umsUserAddressVoList})
				},
				// 删除收货地址
				umsUserAddressVoListitemDel(umsUserAddressVoListitem, umsUserAddressVoListindex) {
					this.ruleForm.umsUserAddressVoList.splice(umsUserAddressVoListindex,1)
				},
				// 选择省市区
				listProvinceCityChange(value,umsUserAddressVoListitem,umsUserAddressVoListindex) {
					console.log('value: ',value);
					let node = this.$refs[`refSubCat${umsUserAddressVoListindex}`][0].getCheckedNodes(true)[0]
					console.log('node: ',node);
					if (value.length) {
						umsUserAddressVoListitem.provinceId = node.path[0]
						umsUserAddressVoListitem.cityId = node.path[1]
						umsUserAddressVoListitem.districtId = node.path[2]
						umsUserAddressVoListitem.provinceName = node.pathLabels[0]
						umsUserAddressVoListitem.cityName = node.pathLabels[1]
						umsUserAddressVoListitem.districtName = node.pathLabels[2]
					} else {
						umsUserAddressVoListitem.provinceId = ''
						umsUserAddressVoListitem.cityId = ''
						umsUserAddressVoListitem.districtId = ''
						umsUserAddressVoListitem.provinceName = ''
						umsUserAddressVoListitem.cityName = ''
						umsUserAddressVoListitem.districtName = ''
					}
				}

			}
		}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.zhangHaoInfo {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 56px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-headingbox {
					position: relative;

					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
			}

			.c-body-info {
				box-sizing: border-box;
				padding: 20px 20px 100px;

				.cb-row {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					.cbr-label {
						width: 92px;
						box-sizing: border-box;
						padding-right: 20px;
						text-align: right;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					.cbr-content {
						display: flex;
						align-items: center;
						position: relative;

						&.type-1 {
							flex-direction: column;
						}

						.jishuzhidaoyuan {
							display: flex;
							align-items: center;
							margin-bottom: 20px;
							// flex-wrap: wrap;
							// flex-direction: column;
						}

						.cbrc-text {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						.form-item {
							&.type-1-wrap {
								margin-right: 20px;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 300px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 300px;
							}
						}

						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.textarea-1 {
								width: 620px;
							}
						}


						// .upload-demo {
						// 	/deep/ {
						// 		.el-upload-list--picture-card .el-upload-list__item {
						// 			width: 100px;
						// 			height: 100px;
						// 		}
						// 	}
						// }

						.upload-demo {
							background: #FFFFFF;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border-radius: 4px;
							box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;

							&:hover {

								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}

							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}

						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px 4px 4px 4px;
							box-sizing: border-box;
							border: 1px dashed #CED4DB;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.zu-icon {
								width: 28px;
								height: 28px;
								background-image: url('../assets/043.png');
								background-size: 100% 100%;
							}

							.zu-label {
								margin-top: 8px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 16px;
							}
						}

						.btn {
							width: 84px;
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							display: flex;
							justify-content: center;
							align-items: center;

							.ab-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/044.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}

							.ab-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}

							&.type-1 {
								position: absolute;
								left: 640px;
							}
							&.type-2 {
								background: #ff0000;
								position: absolute;
								left: 744px;
							}
						}
					}
				}
			}
			
			.c-body {
				box-sizing: border-box;
				padding: 20px 20px 100px;
			
				.cb-row {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
			
					.cbr-label {
						width: 92px;
						box-sizing: border-box;
						padding-right: 20px;
						text-align: right;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}
			
					.cbr-content {
						display: flex;
						align-items: center;
						position: relative;
			
						&.type-1 {
							flex-direction: column;
						}
			
						.jishuzhidaoyuan {
							display: flex;
							align-items: center;
							margin-bottom: 20px;
							// flex-wrap: wrap;
							// flex-direction: column;
						}
			
						.cbrc-text {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
			
						.form-item {
							&.type-1-wrap {
								margin-right: 20px;
							}
						}
			
						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
			
							&.type-1 {
								width: 300px;
							}
						}
			
						/deep/.select {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
			
							&.type-1 {
								width: 300px;
							}
						}
			
						/deep/.textarea {
							textarea {
								height: 104px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
			
							&.textarea-1 {
								width: 620px;
							}
						}
			
			
						// .upload-demo {
						// 	/deep/ {
						// 		.el-upload-list--picture-card .el-upload-list__item {
						// 			width: 100px;
						// 			height: 100px;
						// 		}
						// 	}
						// }
			
						.upload-demo {
							background: #FFFFFF;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border-radius: 4px;
							box-shadow: 0px 2px 8px 0px rgba(203, 203, 203, 0.5);
							margin-right: 10px;
			
							&:hover {
			
								// box-shadow: 0px 2px 8px 0px #3571DA;
								.upload-item {
									border-color: #3571DA;
								}
							}
			
							::v-deep {
								.el-upload-list--picture-card .el-upload-list__item {
									width: 100px;
									height: 100px;
								}
							}
						}
			
						.upload-item {
							width: 100px;
							height: 100px;
							background: #F6F8FC;
							border-radius: 4px 4px 4px 4px;
							box-sizing: border-box;
							border: 1px dashed #CED4DB;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
			
							.zu-icon {
								width: 28px;
								height: 28px;
								background-image: url('../assets/043.png');
								background-size: 100% 100%;
							}
			
							.zu-label {
								margin-top: 8px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #8D9094;
								line-height: 16px;
							}
						}
			
						.btn {
							width: 84px;
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							display: flex;
							justify-content: center;
							align-items: center;
							box-sizing: border-box;
			
							.ab-icon {
								width: 16px;
								height: 16px;
								background-image: url('../assets/044.png');
								background-size: 100% 100%;
								margin-right: 5px;
							}
			
							.ab-text {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #FFFFFF;
								line-height: 14px;
							}
			
							&.type-1 {
								position: absolute;
								left: 640px;
							}
							&.type-2 {
								background: #ff0000;
								position: absolute;
								left: 744px;
							}
							&.type-3 {
								background: transparent;
								margin-left: 20px;
								.ab-text {
									color: #3571DA;
								}
								border: 1px solid #3571DA;
							}
						}
					}
				}
			}
		}
	}
</style>