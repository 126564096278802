<template>
	<div class="popUpdatePass">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="xiugaidenglumima">
				<div class="pop-header">
					<div class="ph-title">修改登录密码</div>
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="pop-body">
					<div class="cb-row">
						<div class="cbr-label">手机号：</div>
						<div class="cbr-content">
							<div class="cbrc-value">{{userInfo.phone | starFilter(4,4,'*')}}</div>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">验证码：</div>
						<div class="cbr-content">
							<el-form-item prop="captcha" class="form-item yanzhengma">
								<el-input v-model="ruleForm.captcha" class="input type-1" placeholder="请输入" clearable autocomplete="new-password"></el-input>
								<Countdown ref="Countdown" :seconds="60" class="yanzhengma-btn" @click="getCode"></Countdown>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">新密码：</div>
						<div class="cbr-content">
							<el-form-item prop="password" class="form-item">
								<el-input v-model="ruleForm.password" class="input type-1" placeholder="请输入" clearable show-password autocomplete="new-password"></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="cb-row">
						<div class="cbr-label">确认密码：</div>
						<div class="cbr-content">
							<el-form-item prop="passwordConfirm" class="form-item">
								<el-input v-model="ruleForm.passwordConfirm" class="input type-1" placeholder="请输入" clearable show-password autocomplete="new-password"></el-input>
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close">取消</div>
					<div class="pf-confirm btn-fill" @click="submit">确定</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		UpdatePass,
		SendCode
	} from '../common/api.js'
	var that = {}
	var numberChecked = (rule, value, callback) => {
		if (!isNaN(parseFloat(value)) && isFinite(value)) {
			callback();
		} else {
			return callback(new Error('请勿输入数字以外的字符'));
		}
	};
	var passwordConfirmChecked = (rule, value, callback) => {
		if (value) {
			if (that.ruleForm.password === that.ruleForm.passwordConfirm) {
				callback();
			} else {
				return callback(new Error('2次输入的密码不一致'));
			}
		} else {
			return callback(new Error('请输入确认密码'));
		}
		
	};
	var passwordChecked = (rule, value, callback) => {
		if (value) {
			if (that.ruleForm.passwordConfirm) {
				that.$refs.ruleForm.validateField(['passwordConfirm'], (valid) => {
					
				});
			}
			callback();
		} else {
			return callback(new Error('请输入密码'));
		}
		
	};
	export default {
		name: 'PopUpdatePass',//修改密码
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					captcha: '', // 验证码
					password: '', // 新密码
					passwordConfirm: '', // 确认密码
				},
				rules: {
					captcha: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						required: true,
						trigger: 'change',
						validator: numberChecked
					}, {
						min: 6,
						max: 6,
						message: '验证码位数不正确',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						trigger: 'blur',
						validator: passwordChecked
					}],
					passwordConfirm: [{
						required: true,
						trigger: ['blur','change'],
						validator: passwordConfirmChecked
					}],
				},
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					if (!val) {
						this.$refs.ruleForm.resetFields()
					}
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			submit() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = {
							captcha: this.ruleForm.captcha, // 验证码
							password: this.ruleForm.password, // 密码
						}
						UpdatePass(data).then(res => {
							this.toast({
								message: '修改成功',
								type: 'success'
							})
							this.close()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getCode() {
				console.log('this.$refs.Countdown.canGetCode: ',this.$refs.Countdown.canGetCode);
				if (this.$refs.Countdown.canGetCode) {
					let data = {
						phone: this.userInfo.phone,
						type: 'forget', // 类型register注册login验证码登录forget忘记密码
					}
					SendCode(data).then(res => {
						this.$refs.Countdown.start()
					})
				}
				
				
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.popUpdatePass {
		.xiugaidenglumima {
			min-width: 600px;
			// min-height: 396px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;

			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;

				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}

				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}

			.pop-body {
				box-sizing: border-box;
				padding: 0 0 20px;
				display: flex;
				flex-direction: column;

				.cb-row {
					display: flex;
					align-items: center;
					margin: 20px 0 0;

					.cbr-label {
						width: 80px;
						display: flex;
						// justify-content: flex-end;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						box-sizing: border-box;
						padding-right: 10px;

						.star {
							color: #FF0000;
						}
					}

					.cbr-content {
						flex: 1;
						display: flex;
						// align-items: center;

						.cbrc-value {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						.yanzhengma {
							position: relative;

							.yanzhengma-btn {
								position: absolute;
								right: 0;
								top: 0;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #3571DA;
								line-height: 32px;
								width: 80px;
								text-align: center;
							}
						}

						/deep/.input {
							input {
								height: 32px;
								font-size: 12px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							&.type-1 {
								width: 320px;
							}
						}
					}
				}

			}

			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;

				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}

				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #3571DA;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>