<template>
	<div class="PopLogoff">
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="zhuxiaozhanghu">
				<div class="pop-header">
					<div class="ph-title">注销账户</div>
					<div class="ph-close pointer" @click="close()"></div>
				</div>
				<div class="pop-body">
					<div class="pb-icon"></div>
					<div class="pb-content">是否确认注销账户？</div>
					<div class="pb-waring">一旦注销成功，所有数据将被删除且无法恢复、无法申请售后。</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="close()">取消</div>
					<div class="pf-confirm btn-fill" @click="confirm">确定注销</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		UserLogoff
	} from '../common/api.js'
	var that = {}
	export default {
		name: 'PopLogoff',//注销账号
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				dialogVisible: false,
				
			}
		},
		watch: {
			show: {
				handler: function(val) {
					this.dialogVisible = val
				},
				immediate: true
			},
			dialogVisible: {
				handler: function(val) {
					this.$emit('update:show', val)
				}
			},
			
		},
		created() {
			that = this
		},
		methods: {
			close() {
				this.dialogVisible = false
			},
			confirm() {
				UserLogoff().then(res => {
					this.toast({
						message: '注销成功',
						type: 'success'
					})
					this.close()
					this.$store.dispatch('updateToken')
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.PopLogoff {
		.zhuxiaozhanghu {
			width: 600px;
			min-height: 272px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			box-sizing: border-box;
			padding: 0 20px;
		
			.pop-header {
				height: 56px;
				display: flex;
				align-items: center;
				position: relative;
				border-bottom: 1px solid #EDEDED;
		
				.ph-title {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
				}
		
				.ph-close {
					width: 16px;
					height: 16px;
					background-image: url('../assets/058.png');
					background-size: 100% 100%;
					position: absolute;
					right: 0;
				}
			}
		
			.pop-body {
				box-sizing: border-box;
				padding: 50px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
		
				.pb-icon {
					width: 42px;
					height: 42px;
					background-image: url('../assets/066.png');
					background-size: 100% 100%;
				}
		
				.pb-content {
					margin-top: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.pb-waring {
					margin-top: 20px;
					font-size: 12px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FC474C;
				}
			}
		
			.pop-footer {
				border-top: 1px solid #EDEDED;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
		
				.pf-cancel {
					width: 88px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					border: 1px solid #3571DA;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571DA;
					line-height: 14px;
					box-sizing: border-box;
					margin: 0 10px;
				}
		
				.pf-confirm {
					width: 88px;
					height: 32px;
					background: #FC474C;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 32px;
					text-align: center;
					margin: 0 10px;
				}
			}
		}
	}
</style>