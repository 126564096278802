<template>
	<div class="manage L">
		<div class="manage-content" :class="$route.name == 'order' ? 'L':'M'">
			<div class="menu">
				<div class="card dingdan-guanli">
					<div class="c-header">
						<div class="ch-headingbox">
							<div class="chh-text">订单管理</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:order:add')" :class="{active: $route.name == 'orderCreate'}"
						@click="toOrderCreate">
						<div class="ci-icon icon-1"></div>
						<div class="ci-title">一键下单</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:order:list')"
						:class="{active: $route.name == 'order' || $route.name == 'orderDetail'}" @click="toOrder">
						<div class="ci-icon icon-2"></div>
						<div class="ci-title">我的订单</div>
					</div>
					<div class="c-item" :class="{active: $route.name == 'product' || $route.name == 'productAdd'}"
						@click="toProduct">
						<div class="ci-icon icon-3"></div>
						<div class="ci-title">产品管理</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:order:finish')"
						:class="{active: $route.name == 'wanGongAlbum'}" @click="toWanGongAlbum">
						<div class="ci-icon icon-4"></div>
						<div class="ci-title">完工图库</div>
					</div>
					<div class="c-item"
						:class="{active: $route.name == 'afterSale' || $route.name == 'afterSaleDetail' || $route.name == 'afterSaleOrderDetail'}"
						@click="toAfterSale">
						<div class="ci-icon icon-5"></div>
						<div class="ci-title">售后管理</div>
					</div>
					<div class="c-item" :class="{active: $route.name == 'zhiShiKu'}" @click="toZhiShiKu">
						<div class="ci-icon icon-6"></div>
						<div class="ci-title">知识库管理</div>
					</div>
				</div>
				<div class="card wode-qianbao">
					<div class="c-header">
						<div class="ch-headingbox">
							<div class="chh-text">我的钱包</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:account:list')" :class="{active: $route.name == 'account'}"
						@click="toAccount">
						<div class="ci-icon icon-7"></div>
						<div class="ci-title">账户/收支明细</div>
					</div>
					<div class="c-item" :class="{active: $route.name == 'accountRecharge'}" @click="toAccountRecharge">
						<div class="ci-icon icon-8"></div>
						<div class="ci-title">账户充值</div>
					</div>
				</div>
				<div class="card zhanghu-xinxi"
					v-if="hasPermi('app:user:info') || hasPermi('app:user:safe') || !isSubAccount">
					<div class="c-header">
						<div class="ch-headingbox">
							<div class="chh-text">账户信息</div>
							<div class="chh-line"></div>
						</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:user:info')"
						:class="{active: $route.name == 'zhangHaoInfo'}" @click="toZhangHaoInfo">
						<div class="ci-icon icon-9"></div>
						<div class="ci-title">基本信息</div>
					</div>
					<div class="c-item" v-if="hasPermi('app:user:safe')"
						:class="{active: $route.name == 'zhangHaoSafety'}" @click="toZhangHaoSafety">
						<div class="ci-icon icon-10"></div>
						<div class="ci-title">账号安全</div>
					</div>
					<div class="c-item" v-if="!isSubAccount" :class="{active: $route.name == 'zhangHao'}"
						@click="toZhangHao">
						<div class="ci-icon icon-11"></div>
						<div class="ci-title">账号管理</div>
					</div>
				</div>
			</div>
			<div class="menu-content">
				<!-- 动态判断是否使用 keep-alive -->
				<keep-alive :include="['order']">
					<router-view />
				</keep-alive>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'manage',
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
			// console.log('this.$route: ', this.$route);
		},
		methods: {
			toOrderCreate() {
				this.$router.push({
					name: 'orderCreate'
				})
			},
			toOrder() {
				this.$router.push({
					name: 'order'
				})
			},
			toProduct() {
				this.$router.push({
					name: 'product'
				})
			},
			toWanGongAlbum() {
				this.$router.push({
					name: 'wanGongAlbum'
				})
			},
			toAfterSale() {
				this.$router.push({
					name: 'afterSale'
				})
			},
			toZhiShiKu() {
				this.$router.push({
					name: 'zhiShiKu'
				})
			},
			toAccount() {
				this.$router.push({
					name: 'account'
				})
			},
			toAccountRecharge() {
				this.$router.push({
					name: 'accountRecharge'
				})
			},
			toZhangHaoInfo() {
				this.$router.push({
					name: 'zhangHaoInfo'
				})
			},
			toZhangHaoSafety() {
				this.$router.push({
					name: 'zhangHaoSafety'
				})
			},
			toZhangHao() {
				this.$router.push({
					name: 'zhangHao'
				})
			},

		}
	}
</script>

<style lang="scss" scoped>
	.manage {
		.manage-content {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 20px 0 80px;
			
			&.L {
				padding: 20px 20px 80px;
				.menu {
					margin-right: 20px;
				}
				.menu-content {
					flex: 1;
				}
			}

			.menu {
				width: 190px;
				
				align-self: flex-start;
				max-height: calc(100vh - 95px - 20px);
				position: sticky;
				top: calc(95px + 20px);
				overflow-y: auto;
				&:hover {
					&::-webkit-scrollbar {
					  width: 10px; /* 设置滚动条的宽度 */
					}
				}
				&::-webkit-scrollbar {
				  width: 0; /* 设置滚动条的宽度 */
				}
				&::-webkit-scrollbar-thumb {
				  background-color: #c1c1c1; /* 设置滚动条滑块的颜色 */
				  border-radius: 5px; /* 设置滚动条滑块的圆角 */
				}
				&::-webkit-scrollbar-thumb:hover {
				  background-color: #a8a8a8; /* 设置滚动条滑块在悬停时的颜色 */
				}
				&::-webkit-scrollbar-track {
				  background-color: #f1f1f1; /* 设置滚动条背景的颜色 */
				}

				.card {
					width: 180px;
					// height: 395px;
					background-color: #FFFFFF;
					border-radius: 8px;
					margin-bottom: 20px;
					
					&:last-child {
						margin-bottom: 5px;
					}

					.c-header {
						display: flex;
						align-items: center;
						// height: 56px;
						padding-top: 20px;
						padding-left: 20px;
						padding-bottom: 15px;

						.ch-headingbox {
							position: relative;

							.chh-text {
								position: relative;
								z-index: 2;
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #333333;
								line-height: 16px;
							}

							.chh-line {
								position: absolute;
								left: 0;
								bottom: -3px;
								width: 120%;
								height: 7px;
								background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
								border-radius: 0px 0px 0px 0px;
							}
						}
					}

					.c-item {
						height: 56px;
						box-sizing: border-box;
						padding-left: 20px;
						display: flex;
						align-items: center;
						cursor: pointer;

						.ci-icon {
							width: 26px;
							height: 26px;

							&.icon-1 {
								background-image: url('../assets/016.png');
								background-size: 100% 100%;
							}

							&.icon-2 {
								background-image: url('../assets/017.png');
								background-size: 100% 100%;
							}

							&.icon-3 {
								background-image: url('../assets/019.png');
								background-size: 100% 100%;
							}

							&.icon-4 {
								background-image: url('../assets/021.png');
								background-size: 100% 100%;
							}

							&.icon-5 {
								background-image: url('../assets/023.png');
								background-size: 100% 100%;
							}

							&.icon-6 {
								background-image: url('../assets/037.png');
								background-size: 100% 100%;
							}

							&.icon-7 {
								background-image: url('../assets/027.png');
								background-size: 100% 100%;
							}

							&.icon-8 {
								background-image: url('../assets/029.png');
								background-size: 100% 100%;
							}

							&.icon-9 {
								background-image: url('../assets/031.png');
								background-size: 100% 100%;
							}

							&.icon-10 {
								background-image: url('../assets/033.png');
								background-size: 100% 100%;
							}

							&.icon-11 {
								background-image: url('../assets/035.png');
								background-size: 100% 100%;
							}
						}

						.ci-title {
							padding: 0 10px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						&.active {
							.ci-icon {
								width: 26px;
								height: 26px;

								&.icon-1 {
									background-image: url('../assets/016.png');
									background-size: 100% 100%;
								}

								&.icon-2 {
									background-image: url('../assets/018.png');
									background-size: 100% 100%;
								}

								&.icon-3 {
									background-image: url('../assets/020.png');
									background-size: 100% 100%;
								}

								&.icon-4 {
									background-image: url('../assets/022.png');
									background-size: 100% 100%;
								}

								&.icon-5 {
									background-image: url('../assets/024.png');
									background-size: 100% 100%;
								}

								&.icon-6 {
									background-image: url('../assets/026.png');
									background-size: 100% 100%;
								}

								&.icon-7 {
									background-image: url('../assets/028.png');
									background-size: 100% 100%;
								}

								&.icon-8 {
									background-image: url('../assets/030.png');
									background-size: 100% 100%;
								}

								&.icon-9 {
									background-image: url('../assets/032.png');
									background-size: 100% 100%;
								}

								&.icon-10 {
									background-image: url('../assets/034.png');
									background-size: 100% 100%;
								}

								&.icon-11 {
									background-image: url('../assets/036.png');
									background-size: 100% 100%;
								}
							}

							.ci-title {
								padding: 0 10px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #3571DA;
							}
						}
					}
				}
			}

			.menu-content {
				width: 1010px;
				border-radius: 8px;
				background-color: #FFFFFF;
				align-self: flex-start;
			}
		}
	}
</style>