<template>
	<div class="wanGongAlbum">
		<div class="card">
			<div class="c-header">
				<div class="ch-headingbox">
					<div class="chh-text">完工图库</div>
					<div class="chh-line"></div>
				</div>
			</div>
			<div class="c-body">
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">选择产品：</div>
						<el-cascader class="select type-1" v-model="ruleForm.categoryValue" :options="erjiCategoryList"
							clearable
							:props="{ expandTrigger: 'hover', label: 'name', value: 'id', children: 'child' }">
						</el-cascader>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">客户信息：</div>
						<el-input class="input input-1" v-model="ruleForm.keyword" placeholder="请输入客户姓名/手机号"
							clearable></el-input>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">完工时间：</div>
						<el-date-picker v-model="ruleForm.time" class="date type-1" type="daterange"
							value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
							:default-time="['00:00:00', '23:59:59']">
						</el-date-picker>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">服务项目：</div>
						<el-select class="select type-2" v-model="ruleForm.serviceType" placeholder="请选择">
							<el-option v-for="item in serviceTypeOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="cb-line"></div>
				<div class="cb-list">
					<div class="cbl-header">
						<div class="mul-checkbox btn-fill" @click="checkedAllClick">
							<div class="checkbox">
								<div class="no" v-if="!checkedAll"></div>
								<div class="yes" v-else></div>
							</div>
							<div class="label">全选 | 已选<span class="num">{{checkedNumber}}</span>张</div>
						</div>
						<div class="cblh-download btn-fill" @click="downloadFiles">下载原图</div>
					</div>
					<div class="empty-wrap" v-loading="isLoading" v-if="!wangongList.length">
						<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"></el-empty>
					</div>
					<div v-loading="isLoading" v-show="wangongList.length">
						<div class="list-item" v-for="(item,index) in wangongList" :key="index">
							<div class="li-header">
								<div class="mul-checkbox btn-fill" @click.stop="wangongitemSelect(item,index)">
									<div class="checkbox">
										<div class="no" v-if="!item.checked"></div>
										<div class="yes" v-else></div>
									</div>
								</div>
								<div class="lih-field" style="flex: 2;min-width: 20%;flex-shrink: 0;">
									<span class="lihf-text">订单号：</span>
									<span class="highlight">{{item.omsOrder.orderNo}}</span>
								</div>
								<div class="lih-line"></div>
								<div class="lih-field" style="flex: auto;min-width: 30%;">
									<span class="lihf-text">服务产品：{{item.modelName}}</span>
								</div>
								<div class="lih-line"></div>
								<div class="lih-field" style="flex: 6;min-width: 30%;">
									<span class="lihf-text">客户信息：{{item.omsOrder.realName}}
										{{item.omsOrder.phone}}{{item.omsOrder.phoneExtension | phoneExtensionFilter}}</span>
								</div>
							</div>
							<div class="li-album">
								<Media mediaClass="w160h120g12b4" :value="item.omsProductCompletionPicList"
									keyName="fileUrl">
									<div class="mul-checkbox-wrap" slot="ext" slot-scope="{ data }"
										@click.stop="omsProductCompletionPicitemSelect(item,data)">
										<div class="mul-checkbox">
											<div class="checkbox">
												<div class="no"
													v-if="!item.omsProductCompletionPicList[data.index].checked"></div>
												<div class="yes" v-else></div>
											</div>
										</div>
									</div>
								</Media>
							</div>
						</div>
					</div v-loading="isLoading" v-show="productsList.length">
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination background :current-page.sync="pageNum" :page-size.sync="pageSize"
							:page-sizes="pageSizesOptions" layout="sizes, prev, pager, next" :total="total"
							@size-change="loadData" @current-change="loadData">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WangongList,
		CategoryList
	} from '../common/api.js'
	import {
		filesDownloadZip
	} from '../common/downLoadFile.js'
	var ruleForm = {
		categoryId: '', // 产品
		keyword: '', // 
		maxTime: '', // 完工时间结束
		minTime: '', // 完工时间开始
		serviceType: '', // 服务项目 1=安装2=维修3=清洗4=调试
		categoryValue: [],
		time: ''
	}
	export default {
		data() {
			return {
				serviceTypeOptions: [{ // 服务项目 1=安装2=维修3=清洗4=调试
					value: 1,
					label: '安装'
				}, {
					value: 2,
					label: '维修'
				}, {
					value: 3,
					label: '清洗'
				}, {
					value: 4,
					label: '调制/检测/定位/教学'
				}, ],

				ruleForm: {
					...ruleForm
				},
				rules: {},

				wangongList: [],

				total: 0,
				pageNum: 1,
				pageSize: 10,
				isLoading: true,

				categoryList: [],
				erjiCategoryList: [],

			}
		},
		computed: {
			checkedAll() {
				if (this.wangongList && this.wangongList.length) {
					if (this.wangongList.some(v => v.checked == false)) {
						return false
					} else {
						return true
					}
				} else {
					return false
				}
			},
			checkedNumber() {
				let number = 0
				this.wangongList.forEach(v => {
					v.omsProductCompletionPicList.forEach(m => {
						if (m.checked) {
							number++
						}
					})
				})
				return number
			}
		},
		created() {
			this.loadData()
			this.getCategoryList()

		},
		mounted() {

		},
		methods: {
			reload() {
				this.pageNum = 1
				this.loadData()
			},
			downloadFiles() {
				let fileList = []
				this.wangongList.forEach(v => {
					v.omsProductCompletionPicList.forEach((m, i) => {
						if (m.checked) {
							fileList.push(m)
						}
					})
				})
				filesDownloadZip(fileList)
			},
			// 完工图片
			loadData() {
				console.log('this.ruleForm.categoryValue.length: ', this.ruleForm.categoryValue.length);
				let data = {
					categoryId: '', // 产品
					keyword: this.ruleForm.keyword, // 
					maxTime: '', // 完工时间结束
					minTime: '', // 完工时间开始
					serviceType: this.ruleForm.serviceType, // 服务项目 1=安装2=维修3=清洗4=调试
					pageSize: this.pageSize,
					pageNum: this.pageNum
				}
				if (this.ruleForm.categoryValue && this.ruleForm.categoryValue.length) {
					data.categoryId = this.ruleForm.categoryValue[this.ruleForm.categoryValue.length - 1]
				}

				data.minTime = this.ruleForm.time[0] || ''
				data.maxTime = this.ruleForm.time[1] || ''

				console.log('data: ', data);
				this.isLoading = true
				WangongList(data).then(res => {
					this.wangongList = res.rows.map(v => {
						v.checked = false
						v.omsProductCompletionPicList.map(m => {
							m.checked = false
							return m
						})
						return v
					})
					this.total = res.total
					this.$bus.$emit('backTop')
				}).finally(() => {
					this.isLoading = false
				})
			},
			getCategoryList() {
				let data = {
					name: '',
					parentId: '',
					type: 1
				}
				CategoryList(data).then(res => {
					this.categoryList = res.data
					if (res.data.length > 1) {
						this.erjiCategoryList = res.data
					} else if (res.data.length == 1) {
						this.erjiCategoryList = res.data[0].child
					}
				})

			},
			// 筛选
			filter() {
				this.reload()
			},
			// 重置
			reset() {
				this.ruleForm = {
					...ruleForm
				}
				this.reload()
			},
			wangongitemSelect(wangongitem, wangongindex) {
				wangongitem.checked = !wangongitem.checked
				if (wangongitem.checked) {
					wangongitem.omsProductCompletionPicList.forEach(v => v.checked = true)
				} else {
					wangongitem.omsProductCompletionPicList.forEach(v => v.checked = false)
				}
			},
			omsProductCompletionPicitemSelect(wangongitem, data) {
				console.log('data: ', data);
				wangongitem.omsProductCompletionPicList[data.index].checked = !wangongitem.omsProductCompletionPicList[data
					.index].checked
				if (wangongitem.omsProductCompletionPicList.some(v => v.checked == false)) {
					wangongitem.checked = false
				} else {
					wangongitem.checked = true
				}
			},
			checkedAllClick() {
				if (!this.checkedAll) {
					this.wangongList.forEach(v => {
						v.checked = true
						v.omsProductCompletionPicList.map(m => {
							m.checked = true
							return m
						})
					})
				} else {
					this.wangongList.forEach(v => {
						v.checked = false
						v.omsProductCompletionPicList.map(m => {
							m.checked = false
							return m
						})
					})
				}
			},


		}
	}
</script>

<style>
	::placeholder {
		color: #B4B9BF !important;
	}
</style>
<style lang="scss" scoped>
	.wanGongAlbum {
		.card {
			.c-header {
				display: flex;
				align-items: center;
				height: 56px;
				margin: 0 20px;
				border-bottom: 1px solid #EDEDED;

				.ch-headingbox {
					position: relative;

					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5FB14D 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}
			}

			.c-body {
				padding-top: 20px;

				.cb-row {
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding-left: 20px;
					margin-bottom: 20px;

					.cbr-input-box {
						display: flex;
						align-items: center;
						margin-right: 20px;

						.cbri-label {
							width: 80px;
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}

						/deep/.input {
							width: 216px;

							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}
						}

						/deep/.select {
							input {
								height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								padding-left: 8px;
							}

							.el-input__suffix {
								display: flex;
								align-items: center;
							}

							&.type-1 {
								width: 280px;
							}

							&.type-2 {
								width: 216px;
							}
						}

						/deep/.date {
							height: 32px;
							padding-left: 8px;
							padding-right: 4px;

							input {
								// height: 32px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								text-align: left;
							}

							.el-input__icon {
								height: initial;
							}

							.el-icon-date {
								order: 1;
							}

							&.type-1 {
								width: 280px;
							}
						}
					}

					.cbr-shaixuan-btn {
						width: 60px;
						height: 32px;
						background: #3571DA;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 32px;
						text-align: center;
						margin-right: 20px;
					}

					.cbr-reset-btn {
						width: 60px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
						line-height: 14px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.cbr-add-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571DA;
						box-sizing: border-box;

						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/045.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}

						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 14px;
						}
					}
				}

				.cb-line {
					margin: 0 20px;
					border-bottom: 1px solid #EDEDED;
				}

				.mul-checkbox-wrap {
					position: absolute;
					top: 0;
					right: 0;
					width: 38px;
					height: 38px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					&:hover {
						.checkbox {
							.no {
								border: 1px solid #3571da;
							}
						}
					}
				}

				.mul-checkbox {
					display: flex;
					align-items: center;

					&:hover {
						.checkbox {
							.no {
								border: 1px solid #3571da;
							}
						}
					}

					.checkbox {
						width: 18px;
						height: 18px;

						.no {
							width: 100%;
							height: 100%;
							background: #FFFFFF;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #CDCDCD;
							box-sizing: border-box;
						}

						.yes {
							width: 100%;
							height: 100%;
							background-image: url('../assets/039.png');
							background-size: 100% 100%;
						}
					}

					.label {
						margin-left: 10px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 18px;

						.num {
							margin: 0 5px;
						}
					}
				}

				.cb-list {
					.cbl-header {
						padding: 20px;
						display: flex;
						align-items: center;

						.mul-checkbox {
							.label {
								font-size: 12px;
							}
						}

						.cblh-download {
							height: 32px;
							background: #3571DA;
							border-radius: 4px 4px 4px 4px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 32px;
							padding: 0 16px;
							margin-left: 10px;
						}
					}

					.list-item {
						width: 970px;
						border: 1px solid #DFDFDF;
						margin: 0 auto 20px;
						border-radius: 8px 8px 0 0;

						&:last-child {
							margin-bottom: 0;
						}

						.li-header {
							min-height: 42px;
							display: flex;
							align-items: center;
							box-sizing: border-box;
							padding: 10px 20px;
							border-bottom: 1px solid #DFDFDF;
							background-color: #F7F7F7;

							.mul-checkbox {
								margin-right: 10px;
							}

							.lih-field {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;

								.lihf-text {}

								.highlight {
									color: #3571DA;
								}
							}

							.lih-line {
								width: 1px;
								height: 14px;
								background-color: #333333;
								margin: 0 20px;
							}
						}

						.li-album {
							box-sizing: border-box;
							padding: 20px;
							display: flex;

							.lia-item {
								width: 160px;
								height: 120px;
								margin-right: 12px;
								position: relative;
								border-radius: 4px;
								overflow: hidden;

								.liai-pic {
									width: 160px;
									height: 120px;
								}

								.mul-checkbox {
									position: absolute;
									top: 6px;
									right: 6px;
								}
							}
						}
					}
				}

				.pagination-wrap {
					padding: 20px 0;

					/deep/.pagination {
						display: flex;
						justify-content: center;
					}
				}

			}
		}
	}
</style>